import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, CircularProgress, Divider, Grid, Typography, useTheme } from "@mui/material";
import { Layout } from "../../components/Layout/Layout";
import { useEffect, useState } from "react";
import { WorkPreparationBreadCrumb } from "../work-preparation/WorkpreparationBreadCrumb";
import { useTranslation } from "react-i18next";
import { DefaultCopyField } from "@eisberg-labs/mui-copy-field";
import WorkPreparationContracts from "../../../infrastructure/api/pages/work-preparation-contracts-api";
import ContractApi from "../../../infrastructure/api/pages/contract-api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RoofAccessMapping } from "../../../application/common/utils/roof-access";
import { Download } from "@phosphor-icons/react";
import "./WorkPreparationDetail.scss";
export const WorkPreparationDetail = () => {
    const theme = useTheme();
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("id");
    const orderNumber = urlParams.get("orderNumber");
    const { t: translate } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [selectedContract, setSelectedContract] = useState();
    const [copied, setCopied] = useState(false);
    const [loader, setLoader] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState({
        leadId: "",
        contractName: "",
        chamberOfCommerce: "",
        companyName: "",
        companyStreet: "",
        companyHouseNumber: "",
        companyCity: "",
        companyCountry: "",
        contactName: "",
        contactPhoneNumber: "",
        contactEmail: "",
        totalAmountInContractedObjects: 0,
        reference: '',
        costCentre: '',
        ledgerAccount: '',
        objects: [],
        contactObjects: [],
        files: []
    });
    useEffect(() => {
        setLoading(true);
        if (myParam) {
            ContractApi.getById(myParam).then((response) => {
                setSelectedContract(response.data);
                setCopied(response.data.isCopied);
            });
            WorkPreparationContracts.getWorkPreparationDetails(myParam)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    setData(response.data);
                    setLoading(false);
                }
            })
                .finally(() => { });
        }
    }, [myParam]);
    useEffect(() => {
        if (completed && selectedContract) {
            setLoader(true);
            // ContractApi.getById(myParam).then((response) => {
            selectedContract.isCopied = true;
            ContractApi.update(selectedContract).then((res) => {
                if (res.status == 200) {
                    setLoader(false);
                    navigate("/workpreparation");
                    toast.success(translate("UpdatedSuccessfully"));
                }
            });
            // });  
        }
    }, [completed]);
    const downloadDocument = (documentId, fileName) => {
        WorkPreparationContracts.downloadDocument(documentId)
            .then((response) => {
            if (response.status === 200 && response.data != null) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        })
            .catch((error) => {
            console.error("There was an error downloading the document!", error);
        })
            .finally(() => { });
    };
    return (_jsx(Layout, { children: _jsxs(Grid, Object.assign({ container: true, sx: {
                height: "80vh",
                borderRadius: "24px",
                gap: 2,
                padding: { xs: 1, sm: 1, md: 1.5 },
            } }, { children: [_jsx(WorkPreparationBreadCrumb, { orderNumber: orderNumber, setCompleted: setCompleted, loader: loader, copied: copied }), loading ? (_jsx(Box, Object.assign({ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh", width: "100%" }, { children: _jsx(CircularProgress, { size: 32, color: "inherit" }) }))) : (_jsxs(_Fragment, { children: [_jsxs(Grid, Object.assign({ container: true, xs: 12, sx: {
                                bgcolor: theme.palette.common.white,
                                borderRadius: "24px",
                                padding: { xs: 1, sm: 1, md: 1.5 },
                                marginBottom: 2,
                                flexDirection: "column",
                                gap: 1,
                            } }, { children: [_jsx(Typography, Object.assign({ variant: "h2" }, { children: translate("Relation") })), _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("ChamberOfCommerce"), value: data.chamberOfCommerce || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Name"), value: data.companyName || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Address"), value: `${data.companyStreet} ${data.companyHouseNumber}${data.companyAddition} ${data.companyPostalCode} ${data.companyCity}`, fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Typography, Object.assign({ variant: "h3" }, { children: translate("Contact Gegevens") })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Name"), value: data.contactName || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Email"), value: data.contactEmail || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Phone Number"), value: data.contactPhoneNumber || " ", fullWidth: true }) }))] }))] })), _jsxs(Grid, Object.assign({ container: true, xs: 12, sx: {
                                bgcolor: theme.palette.common.white,
                                borderRadius: "24px",
                                padding: { xs: 1, sm: 1, md: 1.5 },
                                marginBottom: 2,
                                flexDirection: "column",
                                gap: 1,
                            } }, { children: [_jsx(Typography, Object.assign({ variant: "h2" }, { children: translate("Quote") })), _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("ContractName"), value: data.contractName || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Price"), value: data.totalAmountInContractedObjects, fullWidth: true }) }))] }))] })), _jsxs(Grid, Object.assign({ container: true, xs: 12, sx: {
                                bgcolor: theme.palette.common.white,
                                borderRadius: "24px",
                                padding: { xs: 1, sm: 1, md: 1.5 },
                                marginBottom: 2,
                                flexDirection: "column",
                                gap: 1,
                            } }, { children: [_jsx(Typography, Object.assign({ variant: "h2" }, { children: translate("Invoice") })), _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("CompanyName"), value: data.companyName || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("ChamberOfCommerce"), value: data.chamberOfCommerce || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Address"), value: `${data.companyStreet} ${data.companyHouseNumber}${data.companyAddition} ${data.companyPostalCode} ${data.companyCity}`, fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Cost Centre"), value: data.costCentre || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Reference Number"), value: data.reference || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("LedgerAccount"), value: data.ledgerAccount || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Invoice Email Address"), value: data.contactEmail || " ", fullWidth: true }) }))] }))] })), _jsxs(Grid, Object.assign({ container: true, xs: 12, sx: {
                                bgcolor: theme.palette.common.white,
                                borderRadius: "24px",
                                padding: { xs: 1, sm: 1, md: 1.5 },
                                marginBottom: 2,
                                flexDirection: "column",
                                gap: 3,
                            } }, { children: [_jsx(Typography, Object.assign({ variant: "h2" }, { children: translate("Object") })), data.objects.length === 0 ? (_jsx(Typography, { children: translate("No objects present") })) : (data.objects.map((object, index) => {
                                    var _a;
                                    return (_jsxs(Grid, Object.assign({ container: true, flexDirection: { sm: "row", md: "column" }, sx: { gap: 2 } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Object Name"), value: object.name || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Address"), value: `${object.street || ""} ${object.houseNumber || ""}${object.addition || ""} ${object.postalCode || ""} ${object.city || ""}`, fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Number of roof surfaces"), value: object.totalRoofSurfaces, fullWidth: true }) }))] })), _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Roof Surface Area"), value: object.area, fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Roof access"), value: RoofAccessMapping[(_a = object.roofAccess) !== null && _a !== void 0 ? _a : 0], fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("ProjectNumber"), value: object.projectNumber || "", fullWidth: true }) }))] })), _jsx(Divider, { sx: {
                                                    width: "100%",
                                                    height: "1px",
                                                    border: "solid 1px #ced4da",
                                                    marginTop: "1%",
                                                    marginBottom: "1%",
                                                } })] }), index));
                                }))] })), _jsxs(Grid, Object.assign({ container: true, xs: 12, sx: {
                                bgcolor: theme.palette.common.white,
                                borderRadius: "24px",
                                padding: { xs: 1, sm: 1, md: 1.5 },
                                marginBottom: 2,
                                flexDirection: "column",
                                gap: 1,
                            } }, { children: [_jsx(Typography, Object.assign({ variant: "h3" }, { children: "Daktoegang contact" })), data.contactObjects.length === 0 ? (_jsx(Typography, { children: "Geen daktoegang contacten toegevoegd" })) : (data.contactObjects.map((contactObject, index) => (_jsxs(Grid, Object.assign({ container: true, flexDirection: { sm: "row", md: "column" }, sx: { marginBottom: 2 } }, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Name"), value: contactObject.name || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("Email"), value: contactObject.email || " ", fullWidth: true }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsx(DefaultCopyField, { label: translate("PhoneNumber"), value: contactObject.phoneNumber || " ", fullWidth: true }) }))] })), _jsx(Divider, { sx: {
                                                width: "100%",
                                                height: "1px",
                                                border: "solid 1px #ced4da",
                                                marginTop: "1%",
                                                marginBottom: "1%",
                                            } })] }), index))))] })), _jsxs(Grid, Object.assign({ container: true, xs: 12, sx: {
                                bgcolor: theme.palette.common.white,
                                borderRadius: "24px",
                                padding: { xs: 1, sm: 1, md: 1.5 },
                                marginBottom: 2,
                                flexDirection: "column",
                                gap: 1,
                            } }, { children: [_jsx(Typography, Object.assign({ variant: "h3" }, { children: translate("Purchase order documents") })), data.files.length === 0 ? (_jsx(Typography, { children: "Geen documenten toegevoegd" })) : (_jsx(Grid, Object.assign({ container: true, item: true, display: "flex", 
                                    //lg={9}
                                    gap: 1, marginTop: 1 }, { children: data.files.map((document, index) => (_jsxs(Grid, Object.assign({ item: true, className: "selected-file", onClick: () => {
                                            downloadDocument(document.id, document.fileName);
                                        } }, { children: [_jsx(Typography, Object.assign({ variant: "body2" }, { children: document.fileName })), _jsx(Download, { size: 16 })] }))
                                    //           <Grid item xs={12} sm={6} md={4} key={index}>
                                    //               <Button
                                    //                   variant="contained"
                                    //                   // color="info"
                                    //                   endIcon={<Download size={26} />}
                                    //                   onClick={() => downloadDocument(document.id, document.fileName)}
                                    //                   fullWidth
                                    //               >
                                    // <Typography
                                    // >
                                    //  {document.fileName}
                                    // </Typography>
                                    //               </Button>
                                    //           </Grid>
                                    )) })))] }))] }))] })) }));
};
