import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { CaretDoubleUp, DotsThree } from "@phosphor-icons/react";
import { useState } from "react";
const LeftMessage = ({ message, name, date, maxCharacters, }) => {
    const [showFullText, setShowFullText] = useState(false);
    const toggleTextVisibility = () => {
        setShowFullText(!showFullText);
    };
    const truncatedText = showFullText
        ? message
        : message.slice(0, maxCharacters);
    return (_jsxs(Grid, Object.assign({ container: true, item: true, className: "leftmessage" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 2 }, { children: _jsx(Avatar, {}) })), _jsxs(Grid, Object.assign({ container: true, item: true, className: "content" }, { children: [_jsxs(Grid, Object.assign({ item: true, className: "namecontainer" }, { children: [_jsx(Typography, Object.assign({ className: "nametext" }, { children: name })), _jsx(Typography, Object.assign({ className: "datetext" }, { children: date }))] })), _jsxs(Grid, Object.assign({ item: true }, { children: [_jsx(Typography, Object.assign({ className: "message" }, { children: truncatedText })), message.length > maxCharacters &&
                                (showFullText ? (_jsx(CaretDoubleUp, Object.assign({ onClick: toggleTextVisibility }, { children: " " }))) : (_jsx(DotsThree, { onClick: toggleTextVisibility })))] }))] }))] })));
};
export default LeftMessage;
