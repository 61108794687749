export const dummyDashboard = {
    recentOffers: [],
    topHeader: {
        newLeads: 0,
        newLeadsPercentage: 0,
        totalOfferSent: 0,
        totalOfferSentByUser: 0,
        leadsClosed: 0,
        leadsClosedByUser: 0,
        revenueGenerated: 0,
        revenueGeneratedByUser: 0,
    },
    contractChartData: [{
            contractCount: 0,
            yearMonth: ""
        }]
};
export const dummyDashboardTasks = {
    takenLeadTask: [],
    generalTask: [],
};
export const defaultFilter = {
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 12)).toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0], // Assign current date
};
