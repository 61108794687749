import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState, } from "react";
import { TextField, Grid, FormControl, FormHelperText, } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import GeneralTaskApi from "../../../infrastructure/api/pages/general-task-api";
import { useTranslation } from "react-i18next";
import CustomModal from "../modal/CustomModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Calendar } from "@phosphor-icons/react";
import dayjs from "dayjs";
const GeneralTaskModal = ({ open, onClose, initialFormData, data, setRefreshModalData, }) => {
    const [saving, setSaving] = useState(false);
    const { t: translate } = useTranslation();
    const hiddenButtonRef = useRef(null);
    const handleSaveButtonClick = () => {
        // Trigger click event on the hidden button
        if (hiddenButtonRef.current) {
            hiddenButtonRef.current.click();
        }
    };
    // State for form fields
    const { reset, handleSubmit, control, register, setValue, formState: { errors }, } = useForm();
    const onCloseModal = () => {
        reset(initialFormData);
        onClose();
    };
    const onSubmit = (data) => {
        if (data.id && data.id != "") {
            setSaving(true);
            GeneralTaskApi.update(data)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    toast.success(translate("UpdatedSuccessfully"));
                    onCloseModal();
                    setRefreshModalData((prv) => !prv);
                    reset(initialFormData);
                }
            })
                .finally(() => {
                setSaving(false);
            });
        }
        else {
            setSaving(true);
            data.id = undefined;
            GeneralTaskApi.add(data)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    toast.success(translate("SavedSuccessfully"));
                    onCloseModal();
                    setRefreshModalData((prv) => !prv);
                    reset(initialFormData);
                }
            })
                .finally(() => {
                setSaving(false);
            });
        }
    };
    useEffect(() => {
        reset(data);
    }, [data]);
    return (_jsx(CustomModal, Object.assign({ open: open, onClose: onCloseModal, size: "sm", saving: saving, handleSaveButtonClick: handleSaveButtonClick, heading: translate(data.id && data.id != "" ? "Edit General Task" : "Add General Task"), saveButtonText: translate(data.id && data.id != "" ? "Edit" : "Save"), disabled: false }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 0 }, { children: _jsx(Grid, Object.assign({ item: true, lg: 8, xs: 12, mx: "auto" }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("input", Object.assign({ type: "hidden" }, register("id"))), _jsx(Controller, { name: "description", control: control, defaultValue: data.description, rules: { required: translate("Description is required") }, render: ({ field }) => {
                                var _a;
                                return (_jsx(TextField, Object.assign({ label: translate("Description"), variant: "outlined", fullWidth: true, margin: "normal" }, field, { error: !!errors.description, helperText: (_a = errors.description) === null || _a === void 0 ? void 0 : _a.message })));
                            } }), _jsx(Controller, { name: "deadline", control: control, rules: {
                                required: {
                                    value: true,
                                    message: translate("Deadline is required"),
                                },
                                validate: {
                                    dateRange: (value) => {
                                        if (!value)
                                            return translate("Deadline is required");
                                        const selectedDate = new Date(value);
                                        const today = new Date();
                                        const futureLimit = new Date("2099-12-31");
                                        if (isNaN(selectedDate.getTime())) {
                                            return translate("Invalid date format");
                                        }
                                        if (selectedDate.getDate() < today.getDate()) {
                                            return translate("Deadline must be a future date");
                                        }
                                        else if (selectedDate.getDate() > futureLimit.getDate()) {
                                            return translate("Deadline must be before 2099");
                                        }
                                        return true;
                                    },
                                },
                            }, render: ({ field }) => (_jsxs(FormControl, Object.assign({ fullWidth: true, variant: "outlined", sx: { marginTop: "16px", marginBottom: "8px" } }, { children: [_jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: data.deadline && dayjs(data.deadline), views: ["day", "month", "year"], label: translate("Deadline"), slots: { openPickerIcon: Calendar }, onChange: (date) => {
                                                const formattedDate = date && dayjs(date).format("YYYY-MM-DD");
                                                field.onChange(formattedDate);
                                            }, 
                                            // onBlur={field.onBlur} // Ensure that onBlur event is propagated to trigger validation
                                            sx: { width: "100%" }, format: "DD-MM-YYYY" }) })), _jsx(FormHelperText, Object.assign({ error: true }, { children: errors.deadline && errors.deadline.message }))] }))) }), _jsx("button", Object.assign({ ref: hiddenButtonRef, type: "submit", hidden: true }, { children: "Submit" }))] })) })) })) })));
};
export default GeneralTaskModal;
