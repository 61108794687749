import "react-toastify/dist/ReactToastify.css";
import { http_api } from "../../leadg-api";
const getAllAdminUsers = (isActive) => {
    if (isActive === null) {
        return http_api.get(`users/get-admin-users`);
    }
    return http_api.get(`users/get-admin-users?isActive=${isActive}`);
};
const getRolesOptions = () => {
    return http_api.get(`role/get-all-role-for-user`);
};
const createAdminUser = (input) => {
    return http_api.post("users/register-admin", input);
};
const getAdminUserById = (id) => {
    return http_api.get(`users/get-user-by_id/${id}`);
};
const updateAdminUser = (input) => {
    return http_api.put("users/upadate-admin-user", input);
};
const deleteUserById = (input) => {
    return http_api.post(`users/delete-admin-user`, input);
};
const ActivateUserById = (input) => {
    return http_api.put(`users/active-admin-user`, input);
};
const updateUserLocale = (request) => {
    return http_api.post("users/update-user-locale", request);
};
const UsersApi = {
    getAllAdminUsers,
    createAdminUser,
    getAdminUserById,
    updateAdminUser,
    deleteUserById,
    getRolesOptions,
    ActivateUserById,
    updateUserLocale,
};
export default UsersApi;
