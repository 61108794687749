import { http_api } from "../../leadg-api";
const getCompanyInfo = (contractId) => {
    return http_api.get(`company/get-comapny-by-contractId/${contractId}`);
};
const updateCompanyInvoiceInfo = (contract) => {
    return http_api.put(`contract-invoice-address/update`, contract);
};
const getCompanyInvoiceAddressInfo = (contractId) => {
    return http_api.get(`contract-invoice-address/get-by-contractId/${contractId}`);
};
const getPurchaseOrderData = (contractId) => {
    return http_api.get(`contract/get-documents?contractId=${contractId}`);
};
const config = {
    headers: {
        "content-type": "multipart/form-data",
    },
};
const uploadDocumentData = (input) => {
    return http_api.post(`contract/update-purchase-order-info`, input, config);
};
const deleteDocument = (id) => {
    return http_api.delete(`contract/delete-document?contractDocumentId=${id}`);
};
const ClientPortalApi = {
    getCompanyInfo,
    getPurchaseOrderData,
    uploadDocumentData,
    deleteDocument,
    getCompanyInvoiceAddressInfo,
    updateCompanyInvoiceInfo
};
export default ClientPortalApi;
