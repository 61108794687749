import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, CircularProgress, Divider, FormControl, Grid, TextField, Typography, Autocomplete, FormHelperText, } from "@mui/material";
import { FloppyDisk } from "@phosphor-icons/react";
import { useEffect, useState, useContext, Fragment, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import LeadDetailApi from "../../../infrastructure/api/pages/lead-detail-api";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { UserRolesPermissionsContext } from "../../../context/UserRolesPermissionsContext";
import { checkCreateEdit, } from "../../../application/common/utils/roles-helper";
import { Modules } from "../../../application/common/enums/screen-name";
import LeadsObjectApi from "../../../infrastructure/api/pages/lead-object-api";
import LeadsApi from "../../../infrastructure/api/pages/lead-api";
const initialFormData = {
    id: "",
    name: "",
    chamberOfCommerce: "",
    street: "",
    houseNumber: "",
    addition: "",
    postalCode: "",
    city: "",
    country: "",
    phoneNumber: "",
    email: "",
    btwNumber: "",
    locationId: "",
    location: null,
};
const CompanyInfoTab = () => {
    const { t: translate } = useTranslation();
    const validationSchema = yup.object({
        name: yup.string().required(translate("NameRequired")),
        email: yup.string().email(translate("InvalidEmail")),
    });
    const urlParams = new URLSearchParams(window.location.search);
    const { reset, register, handleSubmit, setValue, control, formState: { errors }, } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const [data, setData] = useState(initialFormData);
    const [saving, setSaving] = useState(false);
    const { RolesPermissions } = useContext(UserRolesPermissionsContext);
    const [locations, setLocations] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const personSelectRef = useRef(null);
    const [error, setError] = useState(undefined);
    const onSubmit = (data) => {
        var _a;
        if (data.location) {
            setSaving(true);
            LeadDetailApi.updateCompanyInfo(data)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    toast.success(translate("SavedSuccessfully"));
                }
            })
                .finally(() => {
                setSaving(false);
            });
        }
        else {
            setError("Verplicht");
            (_a = personSelectRef === null || personSelectRef === void 0 ? void 0 : personSelectRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    };
    useEffect(() => {
        const leadId = urlParams.get("id");
        if (leadId) {
            LeadsApi.makeNewImportLeadOld(leadId);
        }
    });
    useEffect(() => {
        const companyId = urlParams.get("companyId");
        if (companyId) {
            LeadDetailApi.getCompanyInfo(companyId)
                .then((response) => {
                var _a, _b, _c, _d;
                if (response.status === 200 && response.data != null) {
                    if (response.data.locationId) {
                        const location = {
                            id: response.data.locationId,
                            weergavenaam: `${response.data.street} ${(_a = response.data.houseNumber) !== null && _a !== void 0 ? _a : ""}${(_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.addition) !== null && _c !== void 0 ? _c : ""}, ${(_d = response.data.postalCode) !== null && _d !== void 0 ? _d : ""} ${response.data.city}`,
                        };
                        response.data.location = location;
                    }
                    setData(response.data);
                    reset(response.data);
                    //setValue("name", response.data.name);
                }
            })
                .finally(() => { });
        }
    }, []);
    const resetLocation = () => {
        setValue("street", "");
        setValue("houseNumber", "");
        setValue("addition", "");
        setValue("locationId", "");
        setValue("postalCode", "");
        setValue("city", "");
        setValue("locationId", "");
        setValue("location", null);
        setError(undefined);
    };
    const searchLocation = (query) => {
        if (query) {
            LeadsObjectApi.searchLocation(query).then((response) => {
                if (response.status === 200) {
                    const mappedLocations = response.data.response.docs.map((location) => ({
                        id: location === null || location === void 0 ? void 0 : location.id,
                        weergavenaam: location === null || location === void 0 ? void 0 : location.weergavenaam,
                        score: location === null || location === void 0 ? void 0 : location.score,
                        isFakeId: location === null || location === void 0 ? void 0 : location.isFakeId,
                    }));
                    setLocations(mappedLocations);
                    setIsFetching(false);
                }
            });
        }
        setIsFetching(false);
    };
    const getLocationById = (id) => {
        LeadsObjectApi.getLocationById(id).then((response) => {
            var _a, _b, _c, _d, _e, _f;
            if (response.status === 200) {
                const location = response.data.response.docs[0];
                setValue("street", (_a = location === null || location === void 0 ? void 0 : location.straatnaam) !== null && _a !== void 0 ? _a : "");
                setValue("houseNumber", (_c = (_b = location === null || location === void 0 ? void 0 : location.huisnummer) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : "");
                setValue("addition", `${(_d = location === null || location === void 0 ? void 0 : location.huisletter) !== null && _d !== void 0 ? _d : ""}${(location === null || location === void 0 ? void 0 : location.huisnummertoevoeging)
                    ? "-" + location.huisnummertoevoeging
                    : ""}`);
                setValue("locationId", location === null || location === void 0 ? void 0 : location.id);
                setValue("postalCode", (_e = location === null || location === void 0 ? void 0 : location.postcode) !== null && _e !== void 0 ? _e : "");
                setValue("city", (_f = location.woonplaatsnaam) !== null && _f !== void 0 ? _f : "");
                setValue("country", "Netherlands");
            }
        });
    };
    const fetchLocations = (query) => {
        setIsFetching(true);
        searchLocation(query);
    };
    const onLocationChange = (value) => {
        if (value) {
            getLocationById(value.id);
        }
    };
    return (_jsx("form", Object.assign({ onSubmit: checkCreateEdit(RolesPermissions, Modules.Leads)
            ? handleSubmit(onSubmit)
            : undefined }, { children: _jsx(FormControl, Object.assign({ fullWidth: true, variant: "outlined", margin: "normal" }, { children: _jsxs(Box, Object.assign({ padding: "var(--2, 16px) var(--4, 32px)" }, { children: [_jsxs(Grid, Object.assign({ container: true, justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ variant: "h2", sx: { width: { xs: "100%", sm: "49%", md: "49%" } } }, { children: translate("CompanyInformation") })), checkCreateEdit(RolesPermissions, Modules.Leads) && (_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 6, sx: { display: "flex", justifyContent: "flex-end" } }, { children: _jsxs(Button, Object.assign({ type: "submit", variant: "outlined", sx: {
                                        borderRadius: "12px",
                                        border: "var(--none, 1px) solid var(--n-primary, #130922)",
                                        background: "var(--n-primary-light, #F9F6FF)",
                                        display: "flex",
                                        padding: "12px !important",
                                        alignItems: "flex-start",
                                        gap: "10px",
                                        minWidth: "auto !important",
                                    } }, { children: ["Opslaan", saving ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : (_jsx(FloppyDisk, { size: 24 }))] })) })))] })), _jsxs(Grid, Object.assign({ container: true, gap: { xs: 0.5, sm: 0.5, md: 1 }, flexDirection: { xs: "column", sm: "row", md: "row" } }, { children: [_jsx(Controller, { name: "name", control: control, defaultValue: data.name, render: ({ field }) => {
                                    var _a;
                                    return (_jsx(TextField, Object.assign({ label: translate("Name"), variant: "outlined", margin: "normal", sx: { width: { xs: "100%", sm: "100%", md: "100%" } } }, field, { error: !!errors.name, helperText: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message })));
                                } }), _jsx(Controller, { name: "safeId", control: control, defaultValue: data.chamberOfCommerce, render: ({ field }) => (_jsx(TextField, Object.assign({ label: translate("SafeId"), variant: "outlined", margin: "normal", sx: { width: { xs: "100%", sm: "49%", md: "49%" } } }, field))) }), _jsx(Controller, { name: "chamberOfCommerce", control: control, defaultValue: data.chamberOfCommerce, render: ({ field }) => (_jsx(TextField, Object.assign({ label: translate("ChamberOfCommerce"), variant: "outlined", margin: "normal", sx: { width: { xs: "100%", sm: "49%", md: "49%" } } }, field))) })] })), _jsx(Divider, {}), _jsx(Grid, Object.assign({ container: true, gap: 1, flexDirection: { xs: "column", sm: "row", md: "row" } }, { children: _jsx(Controller, { name: "location", control: control, defaultValue: data.location, rules: {
                                required: {
                                    value: true,
                                    message: "Locatie is vereist.",
                                },
                            }, render: ({ field: { onChange, value } }) => {
                                var _a;
                                return (_jsxs(FormControl, Object.assign({ fullWidth: true, variant: "outlined", margin: "normal" }, { children: [_jsx(Autocomplete, { isOptionEqualToValue: (option, value) => option.id === value.id, disablePortal: true, fullWidth: true, getOptionLabel: (option) => option.weergavenaam, options: locations, disabled: isLoading, loading: isFetching, onChange: (_, value) => {
                                                onLocationChange(value);
                                                onChange(value);
                                            }, value: value, renderInput: (params) => (_jsx(TextField, Object.assign({ placeholder: "Zoek locatie", error: !!error, helperText: error, inputRef: personSelectRef }, params, { label: "Locatie", onChange: (e) => {
                                                    fetchLocations(e.target.value);
                                                    resetLocation();
                                                }, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(Fragment, { children: [isFetching ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }), _jsx(FormHelperText, Object.assign({ error: true }, { children: (_a = errors.location) === null || _a === void 0 ? void 0 : _a.message }))] })));
                            } }) })), _jsx(Divider, {}), _jsxs(Grid, Object.assign({ container: true, gap: 1, flexDirection: { xs: "column", sm: "row", md: "row" } }, { children: [_jsx(Controller, { name: "phoneNumber", control: control, defaultValue: data.phoneNumber, render: ({ field }) => (_jsx(TextField, Object.assign({ label: translate("PhoneNumber"), variant: "outlined" }, register("phoneNumber"), { margin: "normal", sx: { width: { xs: "100%", sm: "49%", md: "49%" } } }, field))) }), _jsx(Controller, { name: "email", control: control, defaultValue: data.email, render: ({ field }) => {
                                    var _a;
                                    return (_jsx(TextField, Object.assign({ label: translate("Email"), variant: "outlined" }, register("email"), { margin: "normal", sx: { width: { xs: "100%", sm: "49%", md: "49%" } }, error: !!errors.email, helperText: (_a = errors.email) === null || _a === void 0 ? void 0 : _a.message }, field)));
                                } })] }))] })) })) })));
};
export default CompanyInfoTab;
