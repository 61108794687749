import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, } from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { convertToNLEuroFormat } from "../../../application/common/utils/helper";
const ContractObjectPriceAccordion = ({ amount }) => {
    const { t: translate } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };
    return (_jsx(Box, Object.assign({ sx: {
            display: "flex",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            padding: "16px 18px 0px 18px !important",
        } }, { children: _jsx(Box, Object.assign({ sx: {
                width: "100%",
                maxWidth: "95%",
            } }, { children: _jsxs(Accordion, Object.assign({ expanded: expanded, onChange: handleAccordionChange, sx: {
                    bottom: "-1px",
                    borderBottomLeftRadius: "0 !important",
                    borderBottomRightRadius: "0 !important",
                } }, { children: [_jsx(AccordionSummary, Object.assign({ expandIcon: _jsx(CaretDown, { size: 24 }), "aria-controls": "panel1-content", id: "panel1-header" }, { children: _jsxs(Grid, Object.assign({ container: true, justifyContent: "space-between", alignItems: "center", padding: { sm: "0px 12px 8px 12px", md: "0px 24px 16px 24px" } }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ className: "accordianheading" }, { children: translate("Amount") })) })), _jsx(Grid, Object.assign({ item: true }, { children: !expanded && (_jsx(Box, Object.assign({ className: "pricecontainer" }, { children: _jsx(Typography, Object.assign({ className: "pricetext" }, { children: convertToNLEuroFormat(parseFloat((Math.round(amount * 100) / 100).toFixed(2))) })) }))) }))] })) })), _jsx(AccordionDetails, { children: _jsx(Grid, Object.assign({ container: true, spacing: { xs: 1, sm: "auto" } }, { children: _jsxs(Grid, Object.assign({ container: true, item: true, justifyContent: "space-between", alignItems: "center", padding: { sx: "0px 12px 8px 12px", sm: "0px 12px 8px 12px", md: "0px 24px 16px 24px" } }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, { children: translate("Total") }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Box, Object.assign({ className: "pricecontainer" }, { children: _jsx(Typography, Object.assign({ className: "pricetext" }, { children: convertToNLEuroFormat(parseFloat((Math.round(amount * 100) / 100).toFixed(2))) })) })) }))] })) })) })] })) })) })));
};
export default ContractObjectPriceAccordion;
