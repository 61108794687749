import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Grid, Paper, Typography, useTheme, CircularProgress, Skeleton, } from "@mui/material";
import { DataGrid, } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import "./LeadsTakenTable.scss";
import { CaretRight, Plus, CheckSquare } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import GeneralTaskApi from "../../../../infrastructure/api/pages/general-task-api";
import LeadDetailApi from "../../../../infrastructure/api/pages/lead-detail-api";
import { convertUTCDateToLocalDateString } from "../../../../application/common/utils/helper";
import GeneralTaskModal from "../../../pages/dashboard/GeneralTaskModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const initialFormData = {
    id: "",
    description: "",
    deadline: new Date(),
};
export const LeadsTakenTable = ({ takenTask, generalTask, loading, setRefreshModalData, }) => {
    const theme = useTheme();
    const [buttonClicked, setButtonClicked] = useState(false);
    const [columns, setColumns] = useState([]);
    const { t: translate } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [task, setTask] = useState(initialFormData);
    const [taskTakenSelection, setTaskTakenSelection] = useState([]);
    const [generalTaskSelection, setGeneralTaskSelection] = useState([]);
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    const handleOpenModal = () => {
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const handleCellClick = (params, event) => {
        // Check if the click is from the second column (index 1)
        if (params.field === "__check__") {
            // Prevent further propagation
            event.stopPropagation();
        }
        else {
            handleEditTask(params.id);
        }
    };
    const handleLeadTaskCellClick = (params, event) => {
        // Check if the click is from the second column (index 1)
        if (params.field === "__check__") {
            // Prevent further propagation
            event.stopPropagation();
        }
        else {
            const id = params.row.leadId;
            const companyId = params.row.companyId;
            const orderNumber = params.row.leadNumber;
            const newUrl = `/lead-detail?${new URLSearchParams({
                id,
                companyId,
                orderNumber,
            }).toString()}`;
            navigate(newUrl);
        }
    };
    const handleEditTask = (id) => {
        if (id) {
            GeneralTaskApi.getById(id)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    setTask(response.data);
                    handleOpenModal();
                }
            })
                .finally(() => { });
        }
    };
    useEffect(() => {
        setButtonClicked(buttonClicked);
        if (buttonClicked === true)
            setColumns(columnsForGeneral);
        else
            setColumns(columnsForLead);
    }, [buttonClicked]);
    const columnsForLead = [
        {
            field: "description",
            headerName: "Omschrijving",
            sortable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: false,
            width: 10,
        },
        {
            field: "deadline",
            headerName: translate("Datum gereed"),
            sortable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: false,
            minWidth: 50,
            valueGetter: (value, row) => convertUTCDateToLocalDateString(row.deadline),
        },
        {
            field: "companyName",
            headerName: translate("CompanyName"),
            sortable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: false,
            minWidth: 150,
        },
    ];
    const columnsForGeneral = [
        {
            field: "description",
            headerName: "Omschrijving",
            sortable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: false,
            width: 10,
        },
        {
            field: "deadline",
            headerName: translate("Datum gereed"),
            sortable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: false,
            minWidth: 100,
            valueGetter: (value, row) => convertUTCDateToLocalDateString(row.deadline),
        },
    ];
    const onRowsSelectionTaskTakenHandler = (ids) => {
        setTaskTakenSelection(ids);
    };
    const onRowsSelectionGeneralTaskHandler = (ids) => {
        setGeneralTaskSelection(ids);
    };
    const renderSkeletonRows = (numRows) => {
        const rows = [];
        for (let i = 0; i < numRows; i++) {
            rows.push(_jsxs(Box, Object.assign({ display: "flex", mb: 2, alignItems: "center" }, { children: [_jsx(Skeleton, { animation: "wave", variant: "text", width: 40, height: 25, sx: { marginRight: 1, borderRadius: 1 } }), columns.map((col, index) => (_jsx(Skeleton, { animation: "wave", variant: "text", width: 50 * (index + 2), height: 25, sx: { marginRight: 1, borderRadius: 1 } }, index)))] }), i));
        }
        return rows;
    };
    const handleStatusCompletedGeneralTaskButton = () => {
        const selectedRowsData = generalTaskSelection.map((id) => id);
        if (selectedRowsData && selectedRowsData.length > 0) {
            setSaving(true);
            GeneralTaskApi.isCompleted(selectedRowsData)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    toast.success(translate("Task Completed Successfully"));
                }
            })
                .finally(() => {
                setSaving(false);
                setRefreshModalData((prev) => !prev);
            });
        }
        else {
            toast.warning(translate("Please select atleast one task"));
        }
    };
    const handleStatusCompletedLeadTaskButton = () => {
        const selectedRowsData = taskTakenSelection.map((id) => id);
        if (selectedRowsData && selectedRowsData.length > 0) {
            setSaving(true);
            LeadDetailApi.isCompleted(selectedRowsData)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    toast.success(translate("Task Completed Successfully"));
                }
            })
                .finally(() => {
                setSaving(false);
                setRefreshModalData((prev) => !prev);
            });
        }
        else {
            toast.warning(translate("Please select atleast one task"));
        }
    };
    return (_jsxs(Grid, Object.assign({ item: true, lg: 6, md: 6, xs: 12 }, { children: [_jsxs(Box, Object.assign({ className: "leads-taken-table-container" }, { children: [_jsxs(Box, Object.assign({ className: "header" }, { children: [_jsx(Typography, Object.assign({ color: theme.palette.text.disabled, variant: "body1" }, { children: "Taken" })), _jsxs(Box, Object.assign({ display: "flex", flexDirection: "column", gap: 1 }, { children: [_jsx(Box, Object.assign({ display: "flex", justifyContent: "flex-end" }, { children: _jsx(Button, Object.assign({ variant: "outlined", size: "small", endIcon: _jsx(CaretRight, { size: 20 }), sx: {
                                                marginRight: "5px",
                                                background: "#F9F6FF",
                                                boxShadow: `
                0px 1px 1px 0px #0000001A,
                0px 2px 2px 0px #00000017,
                0px 6px 3px 0px #0000000D,
                0px 10px 4px 0px #00000003,
                0px 15px 4px 0px #00000000
              `,
                                            }, onClick: () => setButtonClicked((prv) => !prv) }, { children: translate(buttonClicked ? "General" : "Leads") })) })), buttonClicked ? (_jsxs(Box, Object.assign({ display: "flex" }, { children: [_jsxs(Button, Object.assign({ variant: "contained", size: "small", endIcon: _jsx(CheckSquare, { size: 20 }), sx: { marginRight: "5px" }, disabled: saving, onClick: handleStatusCompletedGeneralTaskButton }, { children: [translate("Mark as Completed"), saving ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : (_jsx(_Fragment, {}))] })), _jsx(Button, Object.assign({ variant: "contained", size: "small", endIcon: _jsx(Plus, { size: 20 }), sx: { marginRight: "5px" }, onClick: handleOpenModal }, { children: "Taak toevoegen" }))] }))) : (_jsx(Box, Object.assign({ display: "flex", gap: 2 }, { children: _jsxs(Button, Object.assign({ variant: "contained", size: "small", endIcon: _jsx(CheckSquare, { size: 20 }), sx: { marginRight: "5px" }, disabled: saving, onClick: handleStatusCompletedLeadTaskButton }, { children: [translate("Mark as Completed"), saving ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : (_jsx(_Fragment, {}))] })) })))] }))] })), _jsx(Paper, Object.assign({ sx: {
                            width: "100%",
                            height: "250px",
                            overflow: "visible",
                            boxShadow: "none",
                            marginTop: "1%",
                        } }, { children: buttonClicked == false ? (loading ? (_jsx(Box, { children: renderSkeletonRows(6) })) : (_jsx(DataGrid, Object.assign({ rows: takenTask !== null && takenTask !== void 0 ? takenTask : [], onRowSelectionModelChange: (ids) => onRowsSelectionTaskTakenHandler(ids) }, takenTask, { columns: columnsForLead, onCellClick: handleLeadTaskCellClick, checkboxSelection: true, disableRowSelectionOnClick: true, disableColumnFilter: true, disableColumnMenu: true, disableColumnSorting: true, hideFooter: true, localeText: { noRowsLabel: translate("Geen rijen") }, loading: loading, sx: { border: "none" } }), "leads-grid"))) : loading ? (_jsx(Box, { children: renderSkeletonRows(5) })) : (_jsx(DataGrid, Object.assign({ rows: generalTask !== null && generalTask !== void 0 ? generalTask : [], onRowSelectionModelChange: (ids) => onRowsSelectionGeneralTaskHandler(ids) }, generalTask, { columns: columnsForGeneral, onCellClick: handleCellClick, checkboxSelection: true, disableRowSelectionOnClick: true, disableColumnFilter: true, disableColumnMenu: true, disableColumnSorting: true, hideFooter: true, localeText: { noRowsLabel: translate("Geen rijen") }, loading: loading, sx: { border: "none" } }), "leads-grid")) }))] })), _jsx(GeneralTaskModal, { open: modalOpen, onClose: handleCloseModal, initialFormData: initialFormData, data: task, setRefreshModalData: setRefreshModalData })] })));
};
