import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// Add languages here to make available in language selector
export const languages = [
    { key: "en-US", label: "English" },
    { key: "nl-NL", label: "Dutch" },
];
const backendOptions = {
    loadPath: "/locales/{{lng}}/translations.json", //{{lng}}
};
const detectionOptions = {
    order: ["navigator", "localStorage"],
    lookupLocalStorage: "i18nextLng",
    caches: ["localStorage"],
};
export const initializeI18N = () => {
    i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
        backend: backendOptions,
        detection: detectionOptions,
        lng: "nl-NL",
        debug: false,
        load: "currentOnly",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false,
        },
    });
};
export default i18n;
