import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Box, } from "@mui/material";
import { useTranslation } from "react-i18next";
const CustomModal = ({ open, onClose, saving, heading, handleSaveButtonClick, children, size, saveButtonText, disabled, warningMessage, showSaveButton, }) => {
    const { t: translate } = useTranslation();
    return (_jsxs(Dialog, Object.assign({ maxWidth: size, fullWidth: true, open: open, onClose: onClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", sx: { p: { xs: "4px  4px", md: "auto" } } }, { children: [_jsx(DialogTitle, Object.assign({ id: "alert-dialog-title" }, { children: heading })), _jsx(DialogContent, Object.assign({ sx: { paddingBottom: 0 } }, { children: children })), _jsx(DialogActions, { children: _jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: 2,
                    } }, { children: [warningMessage ? (_jsx(Typography, Object.assign({ variant: "body1", color: "#F0A900" }, { children: warningMessage }))) : (_jsx(_Fragment, {})), _jsxs(Box, Object.assign({ sx: { display: "flex", flexDirection: "row", gap: 2 } }, { children: [_jsx(Button, Object.assign({ variant: "outlined", color: "primary", onClick: onClose, sx: {
                                        display: "flex",
                                        padding: "var(--1, 8px) var(--2, 16px)",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "var(--1, 8px)",
                                        borderRadius: "var(--1, 8px)",
                                        border: "var(--none, 1px) solid var(--n-primary, #130922)",
                                    } }, { children: _jsx(Typography, Object.assign({ sx: {
                                            color: "var(--n-primary, #130922)",
                                            fontFeatureSettings: "'clig' off, 'liga' off",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "150%" /* 24px */,
                                            letterSpacing: "1px",
                                        } }, { children: translate("Cancel") })) })), showSaveButton !== false ? (_jsxs(Button, Object.assign({ variant: "contained", color: "primary", onClick: handleSaveButtonClick, autoFocus: true, disabled: disabled, sx: {
                                        display: "flex",
                                        padding: "var(--1, 8px) var(--2, 16px)",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "var(--1, 8px)",
                                        borderRadius: "var(--1, 8px)",
                                        border: "var(--n-primary, #130922)",
                                    } }, { children: [saving ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : (_jsx(_Fragment, {})), _jsx(Typography, Object.assign({ sx: {
                                                color: "var(--Neutral-White, #FFF)",
                                                fontFeatureSettings: "'clig' off, 'liga' off",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                                lineHeight: "150%" /* 24px */,
                                                letterSpacing: "1px",
                                            } }, { children: saveButtonText }))] }))) : (_jsx(_Fragment, {}))] }))] })) })] })));
};
export default CustomModal;
