import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../../context/AuthenticationContext";
import { useTranslation } from "react-i18next";
import { CaretDoubleLeft, CaretDoubleRight, } from "@phosphor-icons/react";
import "./TopBar.scss";
import { Box, Grid, Typography, useTheme, } from "@mui/material";
export const TopBar = ({ setIsSideBarOpen, isSideBarOpen, isSideBarHidden, }) => {
    const { getProfile, setLanguage, selectedLanguage } = useContext(AuthenticationContext);
    const theme = useTheme();
    const [user, setUser] = useState();
    const { t: translate } = useTranslation();
    useEffect(() => {
        setUser(getProfile());
    }, []);
    const handleButtonClick = () => {
        setIsSideBarOpen(!isSideBarOpen);
    };
    const handleLanguageButtonClick = (language) => {
        setLanguage(language);
    };
    return (_jsx("header", { children: _jsxs(Grid, Object.assign({ container: true, justifyContent: "space-between", alignItems: "center", flexWrap: "nowrap", sx: { maxHeight: "80px" }, bgcolor: "#F4EFFB", paddingBottom: 3, paddingTop: 3 }, { children: [_jsx(Grid, Object.assign({ item: true, sm: "auto" }, { children: _jsx(Box, Object.assign({ onClick: handleButtonClick, sx: { cursor: "pointer", display: "flex" }, gap: 2 }, { children: isSideBarOpen || isSideBarHidden ? (_jsx(CaretDoubleRight, { size: 32 })) : (_jsx(CaretDoubleLeft, { size: 32 })) })) })), _jsx(Grid, Object.assign({ item: true, sm: "auto" }, { children: _jsx(Box, Object.assign({ sx: { display: "flex", gap: "8px", alignItems: "center" } }, { children: _jsx(Typography, Object.assign({ variant: "body1" }, { children: user === null || user === void 0 ? void 0 : user.name })) })) }))] })) }));
};
