export const statusMapping = {
    1: "New",
    2: "Quotation",
    3: "Signed",
    4: "Completed",
    5: "Remark",
    6: "Special",
    7: "InReview",
    8: "Toewijzen",
    9: "Verlopen – concurrentie",
    10: "Verlopen – foutief ingeschreven",
    11: "Verlopen – niet bekend",
    12: "Verlopen – prijs",
    13: "Verlopen – opdracht via andere offerte"
};
export const statusBgColor = {
    1: "#E9ECEF",
    2: "#EBF7FD",
    3: "#DFF1E3",
    4: "#F4DFF4",
    5: "#FFFAEB",
    6: "#FBE9DA",
    7: "#dfd8e6",
    8: "#ebf7e9",
    9: "#edd9d8",
    10: "#edd9d8",
    11: "#edd9d8",
    12: "#edd9d8",
    13: "#edd9d8"
};
export const statusTextColor = {
    1: "#495057",
    2: "#005892",
    3: "#2AA241",
    4: "#B425B7",
    5: "#FFBF00",
    6: "#E26D12",
    7: "E52521"
};
export const resultTextColor = {
    1: "#2AA241",
    2: "#E52521"
};
export const resultBackgroundColor = {
    1: "#DFF1E3",
    2: "#FBDFDE"
};
