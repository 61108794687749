import { http_api } from "../../leadg-api";
const getCompanyInfo = (companyId) => {
    return http_api.get(`company/get-comapny-by-id/${companyId}`);
};
const updateCompanyInfo = (info) => {
    return http_api.put(`company/update-company`, info);
};
const getGeneralNotes = (leadId) => {
    return http_api.get(`lead/get-general-notes/${leadId}`);
};
const updateGeneralNotes = (lead) => {
    return http_api.put(`lead/update-genral-notes`, lead);
};
const getLeadContacts = (leadId) => {
    return http_api.get(`contact/get-by-lead-id/${leadId}`);
};
const addContact = (contact) => {
    return http_api.post(`contact/add`, contact);
};
const getContactById = (id) => {
    return http_api.get(`contact/get-by-id/${id}`);
};
const updateContact = (contact) => {
    return http_api.put(`contact/update`, contact);
};
const deleteContact = (id) => {
    return http_api.delete(`contact/delete/${id}`);
};
const getLeadLogs = (leadId) => {
    return http_api.get(`lead/get-logs/${leadId}`);
};
const addLeadTask = (leadTask) => {
    return http_api.post(`lead-task/add`, leadTask);
};
const getLeadTaskByLeadId = (leadId) => {
    return http_api.get(`lead-task/get-by-lead-id/${leadId}`);
};
const deleteLeadTask = (id) => {
    return http_api.delete(`lead-task/delete/${id}`);
};
const updateLeadTask = (lead) => {
    return http_api.put(`lead-task/update`, lead);
};
const getLeadTaskById = (id) => {
    return http_api.get(`lead-task/get-by-id/${id}`);
};
const isCompleted = (Id) => {
    return http_api.patch(`lead-task/updated-status`, Id);
};
const isSafeIdExist = (safeid) => {
    return http_api.get(`company/is-safeid-exist/${safeid}`);
};
const LeadDetailApi = {
    getCompanyInfo,
    updateCompanyInfo,
    getGeneralNotes,
    updateGeneralNotes,
    getLeadContacts,
    addContact,
    getContactById,
    updateContact,
    deleteContact,
    addLeadTask,
    getLeadTaskByLeadId,
    deleteLeadTask,
    updateLeadTask,
    getLeadTaskById,
    getLeadLogs,
    isCompleted,
    isSafeIdExist,
};
export default LeadDetailApi;
