import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { UserRolesPermissionsContext } from "../../../context/UserRolesPermissionsContext";
import { checkCreateEdit } from "../../../application/common/utils/roles-helper";
import { Modules } from "../../../application/common/enums/screen-name";
import { Box, Button, Container, Grid, Typography, useTheme, } from "@mui/material";
import { Plus } from "@phosphor-icons/react/dist/ssr";
const TopHeader = ({ openPopup, translate }) => {
    const { RolesPermissions } = useContext(UserRolesPermissionsContext);
    const theme = useTheme();
    return (_jsx(Container, Object.assign({ disableGutters: true, sx: { maxWidth: "100% !important" } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2, alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 6, sm: 6, md: 6 }, { children: _jsx(Box, Object.assign({ sx: { padding: "24px 0px" } }, { children: _jsx(Typography, Object.assign({ variant: "h1", color: theme.palette.text.primary }, { children: translate("Role") })) })) })), _jsx(Grid, Object.assign({ item: true, xs: 6, sm: 6, md: 6, sx: { textAlign: "right" } }, { children: checkCreateEdit(RolesPermissions, Modules.RoleManagement) && (_jsx(Button, Object.assign({ variant: "contained", size: "medium", startIcon: _jsx(Plus, {}), onClick: openPopup }, { children: translate("NewRole") }))) }))] })) })));
};
export default TopHeader;
