const AUTH_TOKEN = "AUTH_TOKEN";
const REFRESH_TOKEN = "REFRESH_TOKEN";
const setAccessToken = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
};
const setRefreshToken = (token) => {
    localStorage.setItem(REFRESH_TOKEN, token);
};
const getAccessToken = () => {
    return localStorage.getItem(AUTH_TOKEN);
};
const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN);
};
const removeAccessToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
};
const removeRefreshToken = () => {
    localStorage.removeItem(REFRESH_TOKEN);
};
const LocalStorageService = {
    setAccessToken,
    setRefreshToken,
    getAccessToken,
    getRefreshToken,
    removeAccessToken,
    removeRefreshToken,
};
export default LocalStorageService;
