var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import CustomModal from "../../../pages/modal/CustomModal";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete, Grid, TextField } from "@mui/material";
import ContactObjectApi from "../../../../infrastructure/api/pages/contact-object-api";
import { toast } from "react-toastify";
const ContactObjectModal = ({ open, onClose, data, option, initialFormData, refresh, contractId, }) => {
    const [saving, setSaving] = useState(false);
    const { t: translate } = useTranslation();
    const hiddenButtonRef = useRef(null);
    const handleSaveButtonClick = () => {
        // Trigger click event on the hidden button
        if (hiddenButtonRef.current) {
            hiddenButtonRef.current.click();
        }
    };
    const intialData = {
        id: initialFormData.id,
        name: initialFormData.name,
        contractId: contractId,
        contractObjectIds: initialFormData.contractObjectIds,
        contractObjectIdArray: option.filter((x) => initialFormData.contractObjectIdArray.includes(x.id)),
        email: initialFormData.email,
        phoneNumber: initialFormData.phoneNumber,
        sameAsBillingAddress: initialFormData.sameAsBillingAddress,
        instructions: initialFormData.instructions,
        isDeleted: initialFormData.isDeleted,
    };
    const onCloseModal = () => {
        onClose();
        reset(intialData);
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setSaving(true);
        if (data.id) {
            ContactObjectApi.UpdateObjectContact(data)
                .then((res) => {
                if (res.data && res.status) {
                    toast.success(translate("UpdatedSuccessfully"));
                    refresh(true);
                    onCloseModal();
                }
                else {
                    toast.error(translate("ErrorContact"));
                }
            })
                .finally(() => {
                setSaving(false);
            });
        }
        else {
            ContactObjectApi.AddObjectContact(data)
                .then((res) => {
                if (res.data && res.status === 200) {
                    toast.success(translate("SavedSuccessfully"));
                    refresh(true);
                    onCloseModal();
                }
                else {
                    toast.error(translate("ErrorContact"));
                }
            })
                .finally(() => {
                setSaving(false);
            });
        }
    });
    const { reset, handleSubmit, control, register, formState: { errors }, } = useForm();
    useEffect(() => {
        const updateData = {
            id: data.id,
            name: data.name,
            contractId: contractId,
            contractObjectIds: data.contractObjectIds,
            contractObjectIdArray: option.filter((x) => data.contractObjectIdArray.includes(x.id)),
            email: data.email,
            phoneNumber: data.phoneNumber,
            sameAsBillingAddress: data.sameAsBillingAddress,
            instructions: data.instructions,
            isDeleted: data.isDeleted,
        };
        reset(updateData);
    }, [data]);
    return (_jsx(_Fragment, { children: _jsx(CustomModal, Object.assign({ open: open, onClose: onCloseModal, size: "sm", saving: saving, handleSaveButtonClick: handleSaveButtonClick, heading: translate("AddContact"), saveButtonText: translate("Save"), disabled: false }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 0 }, { children: _jsx(Grid, Object.assign({ item: true, lg: 8, xs: 12, mx: "auto" }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("input", Object.assign({ type: "hidden" }, register("id"))), _jsx(Controller, { name: "name", control: control, defaultValue: data.name, render: ({ field }) => {
                                    var _a;
                                    return (_jsx(TextField, Object.assign({ label: translate("Name"), variant: "outlined", fullWidth: true, margin: "normal" }, field, { error: !!errors.name, helperText: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message })));
                                }, rules: {
                                    required: {
                                        value: true,
                                        message: translate("Name") + " " + translate("Required"),
                                    },
                                } }), _jsx(Controller, { name: "phoneNumber", control: control, defaultValue: data.phoneNumber, render: ({ field }) => {
                                    var _a;
                                    return (_jsx(TextField, Object.assign({ label: translate("PhoneNumber"), variant: "outlined", fullWidth: true, margin: "normal" }, field, { error: !!errors.phoneNumber, helperText: (_a = errors.phoneNumber) === null || _a === void 0 ? void 0 : _a.message })));
                                }, rules: {
                                    required: {
                                        value: true,
                                        message: translate("PhoneNumber") + " " + translate("Required"),
                                    },
                                } }), _jsx(Controller, { name: "email", control: control, defaultValue: data.email, render: ({ field }) => {
                                    var _a;
                                    return (_jsx(TextField, Object.assign({ label: translate("MailAddress"), variant: "outlined", fullWidth: true, margin: "normal" }, field, { error: !!errors.email, helperText: (_a = errors.email) === null || _a === void 0 ? void 0 : _a.message })));
                                }, rules: {
                                    required: {
                                        value: true,
                                        message: translate("MailAddress") + " " + translate("Required"),
                                    },
                                    pattern: {
                                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                        message: translate("InvalidEmail")
                                    }
                                } }), _jsx(Controller, { name: "contractObjectIdArray", control: control, defaultValue: option.filter((x) => data.contractObjectIdArray.includes(x.id)), render: ({ field }) => (_jsx(Autocomplete, { id: "tags-outlined", multiple: true, options: option, defaultValue: option.filter((x) => data.contractObjectIdArray.includes(x.id)), getOptionLabel: (option) => option.name, filterSelectedOptions: true, onChange: (event, newValue) => field.onChange(newValue), renderInput: (params) => {
                                        var _a;
                                        return (_jsx(TextField, Object.assign({}, params, { label: "Selecteer Objecten", error: !!errors.contractObjectIdArray, helperText: (_a = errors.contractObjectIdArray) === null || _a === void 0 ? void 0 : _a.message })));
                                    } })), rules: {
                                    required: {
                                        value: true,
                                        message: translate("Object") + " " + translate("Required"),
                                    },
                                } }), _jsx(Controller, { name: "instructions", control: control, defaultValue: data.instructions, render: ({ field }) => {
                                    var _a;
                                    return (_jsx(TextField, Object.assign({ label: translate("Instruction"), variant: "outlined", multiline: true, fullWidth: true, minRows: 3, margin: "normal" }, field, { error: !!errors.instructions, helperText: (_a = errors.instructions) === null || _a === void 0 ? void 0 : _a.message })));
                                }, rules: {
                                    required: {
                                        value: true,
                                        message: translate("Instruction") + " " + translate("Required"),
                                    },
                                    maxLength: {
                                        value: 500,
                                        message: translate("Maximum500CharachterValidation")
                                    }
                                } }), _jsx("button", Object.assign({ ref: hiddenButtonRef, type: "submit", hidden: true }, { children: "Submit" }))] })) })) })) })) }));
};
export default ContactObjectModal;
