var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState } from "react";
import jwt_decode from "jwt-decode";
import LocalStorageService from "../infrastructure/local-storage-service";
import useAsyncEffect from "use-async-effect";
import UsersApi from "../infrastructure/api/pages/users-api";
import i18n from "../i18n";
const context = {
    setAccessToken: (token) => { },
    getProfile: () => {
        return {};
    },
    setQRCodeIsConfigured: (_isConfigured) => { },
    isConfigured: false,
    setLanguage: (language) => { },
    selectedLanguage: "",
};
export const AuthenticationContext = createContext(context);
export const AuthenticationContextProvider = ({ children }) => {
    const [token, setToken] = useState("");
    const [isConfigured, setIsConfigured] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const setAccessToken = (token) => {
        LocalStorageService.setAccessToken(token);
        setToken(token);
    };
    const setQRCodeIsConfigured = (_isConfigured) => {
        setIsConfigured(_isConfigured);
    };
    useAsyncEffect(() => __awaiter(void 0, void 0, void 0, function* () {
        const userId = getProfile().userId;
        if (userId) {
            yield UsersApi.getAdminUserById(userId).then((res) => {
                if (res.data.locale) {
                    setSelectedLanguage(res.data.locale);
                    i18n.changeLanguage(res.data.locale);
                }
            });
        }
    }), []);
    const getProfile = () => {
        const _token = token || LocalStorageService.getAccessToken();
        if (_token) {
            const tempProfile = jwt_decode(_token);
            const keys = Object.keys(tempProfile);
            let profileKeys = [];
            keys.forEach((k) => {
                const key = k.split("/")[k.split("/").length - 1];
                const value = tempProfile[k];
                profileKeys.push({ key, value });
            });
            const _profile = {
                emailaddress: profileKeys.find((f) => f.key === "emailaddress")
                    .value,
                name: profileKeys.find((f) => f.key === "name").value,
                role: profileKeys.find((f) => f.key === "role").value,
                userId: profileKeys.find((f) => f.key === "nameidentifier").value,
                locale: profileKeys.find((f) => f.key === "locality").value,
            };
            return _profile;
        }
        else {
            const profile = {
                emailaddress: "",
                name: "",
                role: "",
                locale: "nl-NL",
                userId: "",
            };
            return profile;
        }
    };
    const setLanguage = (language) => {
        i18n.changeLanguage(language);
        const request = {
            id: getProfile().userId,
            roles: [],
            locale: language,
        };
        if (selectedLanguage !== request.locale) {
            setSelectedLanguage(language);
            UsersApi.updateUserLocale(request);
        }
    };
    const contextValue = {
        setAccessToken,
        getProfile,
        setQRCodeIsConfigured,
        isConfigured,
        setLanguage,
        selectedLanguage,
    };
    return (_jsx(AuthenticationContext.Provider, Object.assign({ value: contextValue }, { children: children })));
};
