import { http_api } from "../../leadg-api";
const getAllRoleRolePermission = () => {
    return http_api.get(`rolepermission/get-all-role-permissions`);
};
const getRolePermissionByRoleId = (roleId) => {
    return http_api.get(`rolepermission/get-role-permission-by-roleid/${roleId}`);
};
const postRolePermission = (rolePermission) => {
    return http_api.post(`rolepermission/add-role-permission`, rolePermission);
};
const getRolePermissionByUserId = () => {
    return http_api.get(`rolepermission/get-role-permission-by-userid`);
};
const updateRolePermission = (updateRolePermission) => {
    return http_api.put(`rolepermission/update-role-permission`, updateRolePermission);
};
const RolePermissionApi = {
    getAllRoleRolePermission,
    getRolePermissionByRoleId,
    postRolePermission,
    updateRolePermission,
    getRolePermissionByUserId,
};
export default RolePermissionApi;
