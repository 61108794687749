import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
const ZoomableImage = ({ src }) => {
    const [zoomLevel, setZoomLevel] = useState(1);
    const handleZoomIn = () => {
        setZoomLevel(zoomLevel + 1);
    };
    const handleZoomOut = () => {
        if (zoomLevel > 0.2) {
            setZoomLevel(zoomLevel - 0.1);
        }
    };
    return (_jsx("div", Object.assign({ style: { overflow: 'hidden', width: '100%' } }, { children: _jsx("img", { src: src, style: {
                width: "100%",
                minHeight: "230px",
                maxHeight: "230px",
                objectFit: "contain",
                objectPosition: "center center",
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.3s ease-in-out',
                cursor: 'zoom-in',
            }, onClick: (e) => { e.stopPropagation(); handleZoomIn(); }, onContextMenu: (e) => {
                e.preventDefault(); // Prevent context menu when right-clicking
                handleZoomOut();
            }, onMouseOver: () => setZoomLevel(1.2), onMouseOut: () => setZoomLevel(1) }) })));
};
export default ZoomableImage;
