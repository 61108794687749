import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Box, Grid, Typography, Button, CircularProgress } from "@mui/material";
import people from "../../../images/CongratsPageSideImage.jpg";
import { Copy, Link } from "@phosphor-icons/react";
import { ClientTopBar } from "../../components/Layout/ClientLayout/ClientTopBar";
import "./Congrats.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ContractApi from "../../../infrastructure/api/pages/contract-api";
const Congrats = () => {
    const navigate = useNavigate();
    const { t: translate } = useTranslation();
    const [noOfMonths, setNoOfMonths] = useState(0);
    const [loading, setLoading] = useState(false);
    const appUrl = process.env.REACT_APP_APPLICATION_BASE_URL;
    const urlParams = new URLSearchParams(window.location.search);
    const contractId = urlParams.get("contractId");
    const redirectUrl = appUrl + "roof-access?contractId=" + contractId;
    useEffect(() => {
        if (contractId) {
            setLoading(true);
            ContractApi.getNumberOfMonthsForPreferredMonth(contractId).then((response) => {
                setNoOfMonths(response.data);
                setLoading(false);
            });
        }
    }, [contractId]);
    return (_jsx(Box, Object.assign({ className: "congratscontainer" }, { children: _jsxs(Grid, Object.assign({ container: true, className: "gridconatiner" }, { children: [_jsx(Grid, Object.assign({ className: "topbar", item: true }, { children: _jsx(ClientTopBar, {}) })), _jsxs(Grid, Object.assign({ container: true, sx: {
                        background: "#F9F6FF",
                        paddingLeft: { xs: "12px", sm: "24px", md: "32px", lg: "64px" },
                        paddingRight: { xs: "12px", sm: "24px", md: "32px", lg: "64px" },
                        paddingBottom: "2%",
                    }, lg: 12 }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, lg: 6, md: 6, sm: 12, sx: {
                                background: "#FFFFFF",
                                boxShadow: "-8px 0px 35px 0px rgba(0, 0, 0, 0.16)",
                                borderRadius: {
                                    xs: "24px 24px 0px 0px",
                                    sm: "24px 24px 0px 0px",
                                    md: "24px 0px 0px 24px",
                                },
                                padding: "3%",
                            }, alignItems: "flex-start", display: "flex" }, { children: [_jsx(Grid, Object.assign({ item: true, lg: 12, sm: 12, display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }, { children: _jsx(Typography, Object.assign({ variant: "h2" }, { children: "Bedankt voor jouw vertrouwen in Eurosafe!" })) })), _jsxs(Grid, Object.assign({ container: true, item: true, lg: 12, gap: 8 }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, lg: 12, gap: 3, alignItems: "center" }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, lg: 12, display: "flex", justifyContent: "center", alignItems: "center", gap: 6 }, { children: [_jsx(Grid, Object.assign({ item: true, lg: 12, display: "flex", justifyContent: "center", alignItems: "center" }, { children: _jsxs(Typography, Object.assign({ variant: "h6", textAlign: "center" }, { children: ["De inspectie wordt binnen nu en", " ", loading ? (_jsx(CircularProgress, { size: 12, color: "inherit" })) : (_jsx(_Fragment, { children: noOfMonths > 0 ? noOfMonths : 12 })), " ", "maanden ingepland. Wij nemen contact met je op zodra we de eerste inspectie inplannen."] })) })), _jsxs(Grid, Object.assign({ container: true, item: true, lg: 12, gap: 3, display: "flex", justifyContent: "center", alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ variant: "h6", textAlign: "center" }, { children: "Om jou zo goed mogelijk van dienst te kunnen zijn hebben wij extra informatie van je nodig. Vul je gegevens eenvoudig aan via onderstaande button:" })), _jsx(Button, Object.assign({ variant: "contained", color: "primary", autoFocus: true, onClick: () => {
                                                                        navigate("/roof-access?contractId=" + contractId);
                                                                    }, sx: {
                                                                        display: "flex",
                                                                        padding: "var(--1, 8px) var(--2, 16px)",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        gap: "var(--1, 8px)",
                                                                        borderRadius: "var(--1, 8px)",
                                                                        border: "var(--n-primary, #130922)",
                                                                    } }, { children: _jsx(Typography, Object.assign({ variant: "body1" }, { children: "Gegevens aanvullen" })) }))] }))] })), _jsx(Grid, Object.assign({ container: true, item: true, lg: 12, direction: "column", justifyContent: "center", alignItems: "center" }, { children: _jsx(Grid, Object.assign({ item: true, lg: 12, width: { xs: "100%", sm: "100%", md: "100%", lg: "75%" } }, { children: _jsxs(Box, Object.assign({ padding: "12px", sx: {
                                                                borderRadius: "var(--1, 8px)",
                                                                background: "var(--Neutral-100, #F8F9FA)",
                                                                boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset",
                                                            }, display: "flex", justifyContent: "space-between", alignItems: "center", gap: { xs: 1, md: 2 } }, { children: [_jsx(Box, { children: _jsx(Link, { size: 24 }) }), _jsx(Typography, Object.assign({ variant: "body1" }, { children: redirectUrl })), _jsx(Box, { children: _jsx(Copy, { size: 24, cursor: "pointer", onClick: () => {
                                                                            navigator.clipboard
                                                                                .writeText(redirectUrl)
                                                                                .then(() => {
                                                                                toast.success(translate("URL copied successfully"));
                                                                            })
                                                                                .catch((error) => {
                                                                                console.error("Unable to copy text to clipboard:", error);
                                                                                // Handle any errors here
                                                                            });
                                                                        } }) })] })) })) }))] })), _jsxs(Grid, Object.assign({ item: true, lg: 12, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }, { children: [_jsx(Typography, Object.assign({ variant: "h6", textAlign: "center" }, { children: "De link naar jouw gegevens wordt tevens verzonden per e-mail." })), _jsx(Typography, Object.assign({ variant: "h6", textAlign: "center" }, { children: "Met vriendelijke groeten," })), _jsx(Typography, Object.assign({ variant: "h6", textAlign: "center" }, { children: "Team Eurosafe" }))] }))] }))] })), _jsx(Grid, Object.assign({ container: true, item: true, lg: 6, md: 6, sm: 12, sx: {
                                background: "#A9D1D9",
                                boxShadow: "-8px 0px 35px 0px rgba(0, 0, 0, 0.16)",
                                borderRadius: {
                                    xs: "0px 0px 24px 24px",
                                    sm: "0px 0px 24px 24px",
                                    md: "0px 24px 24px 0px",
                                },
                            } }, { children: _jsx(Grid, Object.assign({ item: true, lg: 12, sm: 12 }, { children: _jsx(Box, { component: "img", width: "100%", height: "100%", src: people }) })) }))] }))] })) })));
};
export default Congrats;
