import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Link, Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import WorkPreparationContracts from "../../../infrastructure/api/pages/work-preparation-contracts-api";
const BillingInformation = ({ payAndCheckInfo, loading, }) => {
    var _a;
    const { t: translate } = useTranslation();
    const handlePhoneNumberClick = (phoneNumber) => {
        window.open(`tel:${phoneNumber}`);
    };
    const downloadDocument = (documentId, fileName) => {
        WorkPreparationContracts.downloadDocument(documentId)
            .then((response) => {
            if (response.status === 200 && response.data != null) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        })
            .catch((error) => {
            console.error("There was an error downloading the document!", error);
        })
            .finally(() => { });
    };
    return (_jsx(Box, Object.assign({ className: "billing-information", width: { xs: "100%", sm: "90%" } }, { children: _jsxs(Box, Object.assign({ display: "flex", flexDirection: "column", px: 4, py: 2, gap: 3 }, { children: [_jsx(Typography, Object.assign({ className: "billing-information-heading" }, { children: translate("BillingInformation") })), _jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        flexDirection: { xs: "column", sm: "row" },
                        width: "100%",
                    } }, { children: [_jsxs(Typography, Object.assign({ className: "name" }, { children: [translate("CompanyName"), ":"] })), loading ? (_jsx(Skeleton, { animation: "wave", width: "20%" })) : (_jsx(Typography, Object.assign({ className: "data" }, { children: payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.companyName })))] })), _jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        flexDirection: { xs: "column", sm: "row" },
                        width: "100%",
                    } }, { children: [_jsxs(Typography, Object.assign({ className: "name" }, { children: [translate("ContactPerson"), ":"] })), loading ? (_jsx(Skeleton, { animation: "wave", width: "20%" })) : (_jsx(Typography, Object.assign({ className: "data" }, { children: payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.contactPerson })))] })), _jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        flexDirection: { xs: "column", sm: "row" },
                        width: "100%",
                    } }, { children: [_jsxs(Typography, Object.assign({ className: "name" }, { children: [translate("ChamberOfCommerce"), ":"] })), loading ? (_jsx(Skeleton, { animation: "wave", width: "20%" })) : (_jsx(Typography, Object.assign({ className: "data" }, { children: payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.chamberOfCommerce })))] })), _jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        flexDirection: { xs: "column", sm: "row" },
                        width: "100%",
                    } }, { children: [_jsxs(Typography, Object.assign({ className: "name" }, { children: [translate("Address"), ":"] })), loading ? (_jsx(Skeleton, { animation: "wave", width: "40%" })) : (_jsxs(Box, Object.assign({ sx: { textAlign: "right" } }, { children: [_jsx(Typography, Object.assign({ className: "data" }, { children: (payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.street) + ", " + (payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.houseNumber) })), _jsx(Typography, Object.assign({ className: "data" }, { children: payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.postalCode })), _jsx(Typography, Object.assign({ className: "data" }, { children: (payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.city) + " (" + (payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.country) + ")" }))] })))] })), _jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        flexDirection: { xs: "column", sm: "row" },
                        width: "100%",
                    } }, { children: [_jsxs(Typography, Object.assign({ className: "name" }, { children: [translate("MailAddress"), ":"] })), loading ? (_jsx(Skeleton, { animation: "wave", width: "17%" })) : (_jsx(Link, Object.assign({ className: "data", href: `mailto:${payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.email}`, target: "_blank", rel: "noreferrer", sx: {
                                wordWrap: "break-word",
                                whiteSpace: "pre-wrap",
                                overflowWrap: "anywhere",
                                display: "inline-block",
                            } }, { children: payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.email })))] })), _jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        flexDirection: { xs: "column", sm: "row" },
                        width: "100%",
                    } }, { children: [_jsxs(Typography, Object.assign({ className: "name" }, { children: [translate("PhoneNumber"), ":"] })), loading ? (_jsx(Skeleton, { animation: "wave", width: "15%" })) : (_jsx(Typography, Object.assign({ className: "data", onClick: () => handlePhoneNumberClick(payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.phoneNumber), style: { cursor: "pointer" } }, { children: payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.phoneNumber })))] })), _jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        flexDirection: { xs: "column", sm: "row" },
                        width: "100%",
                    } }, { children: [_jsxs(Typography, Object.assign({ className: "name" }, { children: [translate("Reference"), ":"] })), loading ? (_jsx(Skeleton, { animation: "wave", width: "20%" })) : (_jsx(Typography, Object.assign({ className: "data" }, { children: payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.reference })))] })), _jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        flexDirection: { xs: "column", sm: "row" },
                        width: "100%",
                    } }, { children: [_jsxs(Typography, Object.assign({ className: "name" }, { children: [translate("CostCenter"), ":"] })), loading ? (_jsx(Skeleton, { animation: "wave", width: "20%" })) : (_jsx(Typography, Object.assign({ className: "data" }, { children: payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.department })))] })), _jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        flexDirection: { xs: "column", sm: "row" },
                        width: "100%",
                    } }, { children: [_jsxs(Typography, Object.assign({ className: "name" }, { children: [translate("LedgerAccount"), ":"] })), loading ? (_jsx(Skeleton, { animation: "wave", width: "20%" })) : (_jsx(Typography, Object.assign({ className: "data" }, { children: payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.ledgerAccount })))] })), _jsxs(Box, Object.assign({ sx: {
                        display: "flex",
                        justifyContent: { xs: "center", sm: "space-between" },
                        flexDirection: { xs: "column", sm: "row" },
                        width: "100%",
                        gap: 1,
                    } }, { children: [_jsxs(Typography, Object.assign({ className: "name" }, { children: [translate("Files"), ":"] })), _jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                flexWrap: "wrap",
                                gap: { xs: 1, sm: 2 },
                                width: { xs: "100%", sm: "50%" },
                                justifyContent: { xs: "flex-start", sm: "flex-end" },
                                alignItems: { xs: "flex-start", sm: "flex-end" },
                                textAlign: "center",
                            } }, { children: loading ? (_jsx(Skeleton, { animation: "wave", width: "100%" })) : ((_a = payAndCheckInfo === null || payAndCheckInfo === void 0 ? void 0 : payAndCheckInfo.fileDetails) === null || _a === void 0 ? void 0 : _a.map((file, index) => (_jsx(Box, Object.assign({ className: "selected-file-container", onClick: () => {
                                    if (file.id && file.fileName)
                                        downloadDocument(file.id, file.fileName);
                                }, sx: {
                                    display: "inline-block",
                                    maxWidth: "100%",
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-word",
                                    textAlign: "center",
                                    marginBottom: 1, // Optional: Adds some space between rows
                                } }, { children: _jsx(Typography, Object.assign({ variant: "body2" }, { children: file.fileName })) }), index)))) }))] }))] })) })));
};
export default BillingInformation;
