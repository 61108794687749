var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import LeadDetailApi from "../../../../infrastructure/api/pages/lead-detail-api";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Notepad, Pencil, Plus, Trash, } from "@phosphor-icons/react";
import "../ChatSection/ChatSection.scss";
import useAsyncEffect from "use-async-effect";
import moment from "moment";
import LeadTaskModal from "../../../pages/lead-details/LeadTaskModal";
import { toast } from "react-toastify";
import "./TaskList.scss";
import UsersApi from "../../../../infrastructure/api/pages/users-api";
const TaskList = ({ refresh }) => {
    const defaultData = {
        id: undefined,
        leadId: "",
        description: "",
        responsiblePersonId: "",
        responsiblePersonName: "",
        responsiblePersonPhoto: "",
        deadline: new Date(),
        createdOn: new Date(),
    };
    const urlParams = new URLSearchParams(window.location.search);
    const leadId = urlParams.get("id");
    const theme = useTheme();
    const { t: translate } = useTranslation();
    const [tasks, setTasks] = useState([]);
    const [data, setData] = useState(defaultData);
    const [refereshGrid, setRefereshGrid] = useState(false);
    const [leadTaskId, setLeadTaskId] = useState("");
    const [users, setUsers] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const handleOpenModal = () => {
        setShowPopup(true);
    };
    const showPopupOnNewUser = () => {
        setShowPopup(true);
        setLeadTaskId("");
    };
    const getData = (filter) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            var result = yield UsersApi.getAllAdminUsers(filter);
            if (result.data) {
                setUsers(result.data);
            }
        }
        catch (error) {
            console.error("Error while fetching adminusers:", error);
        }
    });
    useEffect(() => {
        getData(false);
    }, []);
    const handleEditTask = (id) => __awaiter(void 0, void 0, void 0, function* () {
        if (id) {
            LeadDetailApi.getLeadTaskById(id)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    setLeadTaskId(id);
                    setData(response.data);
                    // return user ? `${user.firstName} ${user.lastName}` : "Unknown User";
                    handleOpenModal();
                }
            })
                .finally(() => { });
        }
    });
    useEffect(() => {
        const user = users.find((user) => user.id === data.responsiblePersonId);
        data.responsiblePersonName = (user === null || user === void 0 ? void 0 : user.firstName) + " " + (user === null || user === void 0 ? void 0 : user.lastName);
    }, [data]);
    const handleDeleteTask = (id) => __awaiter(void 0, void 0, void 0, function* () {
        if (id) {
            try {
                LeadDetailApi.deleteLeadTask(id).then((response) => {
                    if (response.status === 200 && response.data != null) {
                        toast.success(translate("LeadTaskDeleted"));
                        setRefereshGrid(!refereshGrid);
                    }
                });
            }
            catch (error) {
                console.error("Error deleting user:", error);
            }
        }
    });
    useAsyncEffect(() => __awaiter(void 0, void 0, void 0, function* () {
        if (leadId) {
            LeadDetailApi.getLeadTaskByLeadId(leadId).then((response) => {
                setTasks(response.data);
            });
        }
    }), [leadId, refresh, refereshGrid]);
    // useEffect(() => {
    //   setRefreshModalData((prev) => !prev);
    // }, [tasks]);
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, lg: 12, xs: 12, className: "tasklist", sx: {
                    justifyContent: "space-between",
                    bgcolor: theme.palette.common.white,
                    borderRadius: "24px",
                    padding: "var(--2, 16px) var(--4, 32px)",
                    gap: "var(--2, 16px)",
                } }, { children: [_jsx(Typography, Object.assign({ className: "heading" }, { children: translate("Lead Tasks") })), _jsx(Button, Object.assign({ type: "submit", variant: "contained", size: "small", onClick: showPopupOnNewUser }, { children: _jsx(Plus, { size: 20 }) })), tasks.length === 0 ? (_jsx(Grid, Object.assign({ item: true, lg: 12, xs: 12, display: "flex", justifyContent: "space-between" }, { children: _jsx(Typography, Object.assign({ sx: { width: "85%" } }, { children: "Geen taken voor deze lead" })) }))) : (tasks.map((d) => {
                        return (_jsx(_Fragment, { children: _jsxs(Box, Object.assign({ className: "rightmessage", sx: { display: "block", width: "100%" } }, { children: [_jsxs(Box, Object.assign({ mb: 1, sx: { display: "flex", gap: 0.5, alignItems: "center" } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 1,
                                                    flex: "1 1 auto",
                                                } }, { children: [_jsx(Notepad, { size: 20 }), _jsx(Typography, Object.assign({ className: "nametext" }, { children: d.responsiblePersonName }))] })), _jsxs(Typography, Object.assign({ className: "datetext" }, { children: ["Deadline:", " ", moment.utc(d.deadline).local().format("DD-MM-YYYY")] }))] })), _jsxs(Box, Object.assign({ sx: { display: "flex", gap: 1, alignItems: "center" } }, { children: [_jsx(Typography, Object.assign({ className: "nametext", sx: { flex: "1 1 auto" } }, { children: d.description })), _jsxs(Box, Object.assign({ sx: { display: "flex", gap: 0.5, alignItems: "center" } }, { children: [_jsx(Pencil, { size: 20, style: { cursor: "pointer" }, onClick: () => handleEditTask(d.id) }), _jsx(Trash, { size: 20, style: { cursor: "pointer" }, onClick: () => handleDeleteTask(d.id) })] }))] }))] })) }));
                    }))] })), _jsx(LeadTaskModal, { leadTaskId: leadTaskId, data: data, title: leadTaskId ? translate("UpdateLeadTask") : translate("AddLeadTask"), buttonText: leadTaskId ? translate("UpdateLeadTask") : translate("AddLeadTask"), showPopup: showPopup, translate: translate, onClosePopup: () => {
                    setShowPopup(false);
                    setLeadTaskId("");
                    setData(defaultData);
                }, onSave: () => setRefereshGrid(!refereshGrid) })] }));
};
export default TaskList;
