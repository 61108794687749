var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState, useContext } from "react";
import { toast } from "react-toastify";
import UsersApi from "../../../infrastructure/api/pages/users-api";
import { TextField, Grid, FormControl, InputLabel, FormHelperText, Select, MenuItem } from "@mui/material";
import i18n from "../../../i18n";
import CustomModal from "../modal/CustomModal";
import { Controller, useForm } from "react-hook-form";
import { UserRolesPermissionsContext } from "../../../context/UserRolesPermissionsContext";
const defaultUserData = {
    roles: [],
};
const UserFormSubmit = ({ data, title, closeOnClickOutside = false, userId = "", buttonText, showPopup = false, onClosePopup, onSave, translate, isDisabled }) => {
    const [options, setOptions] = useState([]);
    const [modalTitle, setModalTitle] = useState("");
    const [userData, setUserData] = React.useState(defaultUserData);
    const [buttonTextChange, setButtonTextChange] = useState("");
    const [saving, setSaving] = useState(false);
    const { UserRolesPermissionsSet } = useContext(UserRolesPermissionsContext);
    const { reset, handleSubmit, control, register, formState: { errors }, } = useForm();
    useEffect(() => {
        setUserData(data);
    });
    const hiddenButtonRef = useRef(null);
    const handleSaveButtonClick = () => {
        // Trigger click event on the hidden button
        if (hiddenButtonRef.current) {
            hiddenButtonRef.current.click();
        }
    };
    useEffect(() => {
        getRoleOptios();
        setModalTitle(title);
        setButtonTextChange(buttonText);
        if (userId != "") {
            getAdminUserById(userId);
        }
    }, [userId, i18n.language]);
    const getRoleOptios = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            var result = yield UsersApi.getRolesOptions();
            if (result.data) {
                setOptions(result.data);
            }
        }
        catch (error) {
            console.error("Error while fetching roles:", error);
        }
    });
    const resetFormData = () => {
        setUserData(defaultUserData);
        userData.roles = [];
    };
    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        userData.roles.length = 0;
        let data = Object.assign({}, userData);
        if (value != "") {
            data.roles.push(value);
        }
        setUserData(data);
    };
    const getAdminUserById = (userId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            var result = yield UsersApi.getAdminUserById(userId);
            if (result.data) {
                setUserData(result.data);
            }
        }
        catch (error) {
            console.error("Error while fetching adminusers:", error);
        }
    });
    useEffect(() => {
        reset(data);
    }, [data]);
    const onSubmit = (userData) => {
        setSaving(true);
        if (userData.id) {
            UpdateUserAsync(userData);
        }
        else {
            createUserAsync(userData);
        }
    };
    const UpdateUserAsync = (userData) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            userData.roles = Array.isArray(userData.roles) ? userData.roles : [userData.roles];
            const result = yield UsersApi.updateAdminUser(userData);
            if (result.data) {
                toast.success(translate("UpdatedSuccessfully"));
                closePopup();
                UserRolesPermissionsSet();
                onSave();
                setSaving(false);
            }
        }
        catch (error) {
            console.error("Error while updating User.", error);
            setSaving(false);
        }
    });
    const createUserAsync = (userData) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            userData.roles = Array.isArray(userData.roles) ? userData.roles : [userData.roles];
            userData.password = "Admin@4321";
            const result = yield UsersApi.createAdminUser(userData);
            if (result.data) {
                toast.success(translate("SavedSuccessfully"));
                closePopup();
                onSave();
                setSaving(false);
            }
        }
        catch (error) {
            toast.error(translate("InvalidUser"));
            setSaving(false);
        }
    });
    const closePopup = () => {
        resetFormData();
        onClosePopup();
        reset();
    };
    return (_jsx(CustomModal, Object.assign({ open: showPopup, onClose: closePopup, size: "sm", saving: saving, handleSaveButtonClick: handleSaveButtonClick, heading: translate(modalTitle), saveButtonText: translate("Save"), disabled: false }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 0 }, { children: _jsx(Grid, Object.assign({ item: true, lg: 8, xs: 12, mx: "auto" }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("input", Object.assign({ type: "hidden" }, register("id"))), _jsx(Controller, { name: "firstName", control: control, defaultValue: data.firstName, rules: {
                                required: {
                                    value: true,
                                    message: translate("FirstName is required"),
                                },
                                minLength: {
                                    value: 2,
                                    message: translate("ShouldHaveMin2Character"),
                                },
                                pattern: {
                                    value: RegExp("^[a-z A-Z]+$"),
                                    message: translate("InvalidFirstName"),
                                }
                            }, render: ({ field }) => {
                                var _a;
                                return (_jsx(TextField, Object.assign({ label: translate("FirstName"), variant: "outlined", fullWidth: true, margin: "normal" }, field, { error: !!errors.firstName, helperText: (_a = errors.firstName) === null || _a === void 0 ? void 0 : _a.message })));
                            } }), _jsx(Controller, { name: "lastName", control: control, defaultValue: data.lastName, rules: {
                                required: {
                                    value: true,
                                    message: translate("LastName is required"),
                                },
                                pattern: {
                                    value: RegExp("^[a-z A-Z]+$"),
                                    message: translate("InvalidLastName"),
                                }
                            }, render: ({ field }) => {
                                var _a;
                                return (_jsx(TextField, Object.assign({ label: translate("LastName"), variant: "outlined", fullWidth: true, margin: "normal" }, field, { error: !!errors.lastName, helperText: (_a = errors.lastName) === null || _a === void 0 ? void 0 : _a.message })));
                            } }), _jsx(Controller, { name: "email", control: control, defaultValue: data.email, rules: {
                                required: {
                                    value: true,
                                    message: translate("Email is required"),
                                },
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: translate("InvalidEmail"),
                                }
                            }, render: ({ field }) => {
                                var _a;
                                return (_jsx(TextField, Object.assign({ label: translate("Email"), variant: "outlined", fullWidth: true, margin: "normal", disabled: data.email != "" }, field, { error: !!errors.email, helperText: (_a = errors.email) === null || _a === void 0 ? void 0 : _a.message })));
                            } }), _jsx(Controller, { name: "phoneNumber", control: control, defaultValue: data.phoneNumber, 
                            // rules={{
                            //   required: {
                            //     value: true,
                            //     message: translate("PhoneNumber is required"),
                            //   },
                            //   // pattern: {
                            //   //   value: /^\d{13}$/,
                            //   //   message: translate("InvalidPhoneNo"),
                            //   // },
                            // }}
                            render: ({ field }) => {
                                var _a;
                                return (_jsx(TextField, Object.assign({ label: translate("PhoneNumber"), variant: "outlined", fullWidth: true, margin: "normal" }, field, { error: !!errors.phoneNumber, helperText: (_a = errors.phoneNumber) === null || _a === void 0 ? void 0 : _a.message })));
                            } }), _jsx(Controller, { name: "roles", control: control, disabled: isDisabled, defaultValue: data.roles, rules: {
                                required: {
                                    value: true,
                                    message: translate("Role is required"),
                                },
                            }, render: ({ field }) => {
                                var _a;
                                return (_jsxs(FormControl, Object.assign({ fullWidth: true, error: !!errors.roles }, { children: [_jsx(InputLabel, Object.assign({ htmlFor: "simple-select-role-option", style: { color: errors.roles ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)' } }, { children: translate("Role") })), _jsx(Select, Object.assign({ labelId: "simple-select-role-option", id: "simple-select-role-option", label: translate("Role") }, field, { children: options.map((option) => (_jsx(MenuItem, Object.assign({ value: option.name }, { children: option.name }), option.id))) })), _jsx(FormHelperText, Object.assign({ error: true }, { children: (_a = errors.roles) === null || _a === void 0 ? void 0 : _a.message }))] })));
                            } }), _jsx("button", Object.assign({ ref: hiddenButtonRef, type: "submit", hidden: true }, { children: "Submit" }))] })) })) })) })));
};
export default UserFormSubmit;
