import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, Typography, useTheme, FormHelperText, Button, } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Calendar } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
const DashboardTopHeader = ({ filters, setFilters, }) => {
    const [filter, setFilter] = useState(filters);
    const theme = useTheme();
    const hiddenButtonRef = useRef(null);
    const { t: translate } = useTranslation();
    const { reset, control, handleSubmit, setError, clearErrors, formState: { errors }, } = useForm({
        defaultValues: filters,
    });
    const validateStartDate = (date) => {
        if (!date) {
            return "Start Date is required";
        }
        // Add any other validation logic here if necessary
        return null;
    };
    const onSubmit = (data) => {
        if (!data.startDate) {
            setError("startDate", {
                type: "manual",
                message: translate("Start Date is required"),
            });
            return;
        }
        if (!data.endDate) {
            setError("endDate", {
                type: "manual",
                message: translate("End Date is required"),
            });
            return;
        }
        if (data.startDate >= data.endDate) {
            setError("startDate", {
                type: "manual",
                message: translate("Start date must be Lesser than end date"),
            });
            setError("endDate", {
                type: "manual",
                message: translate("End date must be greater than start date"),
            });
            return;
        }
        clearErrors("startDate");
        setFilters(data);
    };
    const buttonClicked = () => {
        // Trigger click event on the hidden button
        if (hiddenButtonRef.current) {
            hiddenButtonRef.current.click();
        }
    };
    useEffect(() => {
        reset(filter);
    }, [filter, reset]);
    return (_jsxs(Box, Object.assign({ sx: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
        } }, { children: [_jsx(Box, Object.assign({ sx: { padding: { xs: "12px 0px", md: "24px 0px" } } }, { children: _jsx(Typography, Object.assign({ variant: "h1", color: theme.palette.text.primary }, { children: "Dashboard" })) })), _jsxs(Box, Object.assign({ sx: {
                    display: "flex",
                    padding: { xs: "8px 0px", md: "24px 0px" },
                    gap: { xs: 1, md: 2 },
                    flexDirection: { sm: "row", xs: "column", md: "row" },
                } }, { children: [_jsx(Box, Object.assign({ sx: { display: "flex", alignItems: "center" } }, { children: _jsx(Typography, { children: "Select Range:" }) })), _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    gap: { xs: 1, md: 2 },
                                } }, { children: [_jsx(Controller, { name: "startDate", control: control, render: ({ field }) => (_jsxs(FormControl, Object.assign({ variant: "outlined", sx: {
                                                padding: "2px",
                                            } }, { children: [_jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: filters.startDate && dayjs(filters.startDate), views: ["day", "month", "year"], label: "Startdatum", slots: { openPickerIcon: Calendar }, onChange: (date) => {
                                                            const formattedDate = date && dayjs(date).format("YYYY-MM-DD");
                                                            field.onChange(formattedDate);
                                                        }, sx: { width: "100%" }, format: "DD-MM-YYYY" }) })), _jsx(FormHelperText, Object.assign({ error: true }, { children: errors.startDate && errors.startDate.message }))] }))) }), _jsx(Controller, { name: "endDate", control: control, rules: {
                                            required: translate("End date is required"),
                                            // validate: (value) => {
                                            //   if (!value) {
                                            //     return true; // Skip validation if start date is not set
                                            //   }
                                            //   return (
                                            //     value >= filters.startDate ||
                                            //     translate("End date must be greater than start date")
                                            //   );
                                            // },
                                        }, render: ({ field }) => (_jsxs(FormControl, Object.assign({ variant: "outlined", sx: {
                                                padding: "2px",
                                            } }, { children: [_jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: filters.endDate && dayjs(filters.endDate), views: ["day", "month", "year"], label: "Einddatum", slots: { openPickerIcon: Calendar }, onChange: (date) => {
                                                            const formattedDate = date && dayjs(date).format("YYYY-MM-DD");
                                                            field.onChange(formattedDate);
                                                        }, 
                                                        // onBlur={field.onBlur} // Ensure that onBlur event is propagated to trigger validation
                                                        sx: { width: "100%" }, format: "DD-MM-YYYY" }) })), _jsx(FormHelperText, Object.assign({ error: true }, { children: errors.endDate && errors.endDate.message }))] }))) }), _jsx(Button, Object.assign({ type: "submit", variant: "contained", size: "medium", 
                                        // endIcon={<MagnifyingGlass size={20} />}
                                        //disabled={saving}
                                        sx: { fontSize: { xs: "12px", sm: "12px", md: "14px" } }, onClick: buttonClicked }, { children: "Toepassen" }))] })), _jsx("button", Object.assign({ ref: hiddenButtonRef, type: "submit", hidden: true }, { children: "Submit" }))] }))] }))] })));
};
export default DashboardTopHeader;
