import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
const OfferPDFViewer = ({ base64Pdf }) => {
    const [pdfSrc, setPdfSrc] = useState("");
    useEffect(() => {
        if (base64Pdf) {
            setPdfSrc(`data:application/pdf;base64,${base64Pdf}`);
        }
    }, [base64Pdf]);
    return (_jsx("div", { children: _jsx("iframe", { title: "PDF Viewer", src: pdfSrc + "#zoom=page-width", width: "100%", height: `${window.innerHeight - 200}`, allowFullScreen: true, style: { borderRadius: "12px" } }) }));
};
export default OfferPDFViewer;
