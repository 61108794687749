import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import CustomModal from "../../../pages/modal/CustomModal";
import { Box, Button, FormHelperText, Grid, TextField, Typography, } from "@mui/material";
import SignaturePad from "react-signature-canvas";
import { useEffect, useRef, useState } from "react";
import ContractedObjectApi from "../../../../infrastructure/api/pages/contracted-object";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const ContractAcceptModal = ({ setRefresh, open, onClose, contract, }) => {
    var _a;
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const [saving, setSaving] = useState(false);
    const hiddenButtonRef = useRef(null);
    const { formState: { errors }, control, handleSubmit, reset, setError, clearErrors, } = useForm();
    const sigCanvas = useRef(null);
    const clear = () => { var _a; return (_a = sigCanvas.current) === null || _a === void 0 ? void 0 : _a.clear(); };
    const onSubmit = (data) => {
        var _a, _b;
        setSaving(true);
        if ((_a = sigCanvas.current) === null || _a === void 0 ? void 0 : _a.isEmpty()) {
            setError("signature", {
                type: "required",
                message: translate("Signature is required"),
            });
            setSaving(false);
        }
        if (sigCanvas.current && !((_b = sigCanvas.current) === null || _b === void 0 ? void 0 : _b.isEmpty())) {
            data.signature = sigCanvas.current
                .getTrimmedCanvas()
                .toDataURL("image/png");
            if (data.signature)
                data.isSigned = true;
            ContractedObjectApi.UpdateContract(data)
                .then(() => {
                toast.info(translate("Signed saved successfully"));
                navigate("/congrats?contractId=" + data.id);
            })
                .finally(() => {
                onClose();
                setSaving(false);
                setRefresh((prev) => !prev);
            });
        }
    };
    const save = () => {
        // Trigger click event on the hidden button
        if (hiddenButtonRef.current) {
            hiddenButtonRef.current.click();
        }
    };
    const closeModal = () => {
        setSaving(false);
        reset();
        onClose();
    };
    useEffect(() => {
        reset(contract);
    }, [contract]);
    return (_jsx(CustomModal, Object.assign({ open: open, onClose: closeModal, size: "md", saving: saving, handleSaveButtonClick: save, heading: translate("Sign offer"), saveButtonText: translate("Save"), disabled: false }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs(Grid, Object.assign({ container: true, gap: 1, padding: { xs: 0, sm: 1 } }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, flexDirection: "row", gap: 2 }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Controller, { name: "functie", control: control, render: ({ field }) => {
                                            var _a;
                                            return (_jsx(TextField, Object.assign({ label: translate("Function") }, field, { error: !!errors.functie, helperText: (_a = errors.functie) === null || _a === void 0 ? void 0 : _a.message })));
                                        }, rules: {
                                            required: {
                                                value: true,
                                                message: translate("Functie is required"),
                                            },
                                        } }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Controller, { name: "name", control: control, render: ({ field }) => {
                                            var _a;
                                            return (_jsx(TextField, Object.assign({ label: translate("Name") }, field, { error: !!errors.name, helperText: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message })));
                                        }, rules: {
                                            required: {
                                                value: true,
                                                message: translate("NameRequired"),
                                            },
                                        } }) }))] })), _jsxs(Grid, Object.assign({ container: true, item: true, xs: 12, lg: 12, gap: 1 }, { children: [_jsx(Typography, { children: translate("Signature") }), _jsx(Box, Object.assign({ sx: {
                                        border: 1,
                                        borderColor: "#CED4DA",
                                        borderRadius: 1,
                                        padding: "6px 12px",
                                        width: { xs: "100%", sm: "100%", md: "800px" },
                                        height: { xs: "150px", sm: "200px" }, // Responsive height
                                    } }, { children: _jsx(SignaturePad, { ref: sigCanvas, canvasProps: {
                                            className: "signatureCanvas",
                                            style: { width: "100%", height: "100%" }, // Responsive canvas
                                        }, onBegin: () => clearErrors("signature") }) })), _jsx(FormHelperText, Object.assign({ error: true }, { children: (_a = errors.signature) === null || _a === void 0 ? void 0 : _a.message }))] })), _jsx(Grid, Object.assign({ container: true, item: true }, { children: _jsx(Button, Object.assign({ sx: { padding: "4px 16px" }, onClick: clear, variant: "outlined" }, { children: translate("Remove") })) }))] })), _jsx("button", Object.assign({ ref: hiddenButtonRef, type: "submit", hidden: true }, { children: "Submit" }))] })) })));
};
export default ContractAcceptModal;
