import { http_api } from "../../leadg-api";
const GetAllContractedObjectByContractId = (id) => {
    return http_api.get("contracted-object/get-by-contract-id/" + id);
};
const GetContractByContractId = (id) => {
    return http_api.get("contract/get-contract-with-contracted-object/" + id);
};
const UpdateContract = (contract) => {
    return http_api.put("contract/update", contract);
};
const RejectContract = (contract) => {
    return http_api.put("contract/reject-contract", contract);
};
const GetContractedObjectDropdown = (contractId) => {
    return http_api.get("contracted-object/get-contracted-object-dropdown-by-contract-id/" +
        contractId);
};
const ContractedObjectApi = {
    GetAllContractedObjectByContractId,
    GetContractByContractId,
    UpdateContract,
    RejectContract,
    GetContractedObjectDropdown
};
export default ContractedObjectApi;
