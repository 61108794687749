import "react-toastify/dist/ReactToastify.css";
import { http_api } from "../../leadg-api";
const getAllRole = () => {
    return http_api.get(`role/get-all-role`);
};
const createRole = (input) => {
    return http_api.post("role/create-role", input);
};
const getRoleById = (id) => {
    return http_api.get(`role/${id}`);
};
const updateRole = (input) => {
    return http_api.put("role/update", input);
};
const deleteRoleById = (id) => {
    return http_api.delete(`role/delete/${id}`);
};
const activedeleteRoleById = (id) => {
    return http_api.delete(`role/delete/${id}`);
};
const RolesApi = {
    createRole,
    getAllRole,
    getRoleById,
    updateRole,
    deleteRoleById,
};
export default RolesApi;
