var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, CircularProgress, Container, FormControl, Grid, InputLabel, Select, TextField, Typography, Switch, FormGroup, FormControlLabel, Autocomplete, } from "@mui/material";
import { DownloadSimple, Plus, UserCheck } from "@phosphor-icons/react";
import { useContext, useState, useEffect } from "react";
import LeadsApi from "../../../infrastructure/api/pages/lead-api";
import { useNavigate } from "react-router-dom";
import { UserRolesPermissionsContext } from "../../../context/UserRolesPermissionsContext";
import { checkCreateEdit, checkManage, } from "../../../application/common/utils/roles-helper";
import { Modules } from "../../../application/common/enums/screen-name";
import { toast } from "react-toastify";
import UsersApi from "../../../infrastructure/api/pages/users-api";
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
export const LeadgenerationTopHeader = ({ setSearch, setStatus, search, status, handleOpenModal, setRefresh, isSelectiveRoles, setIsSelectiveRoles, setPaginationModel, setSelectedPerson, }) => {
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const [searchInput, setSearchInput] = useState(search);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { RolesPermissions } = useContext(UserRolesPermissionsContext);
    const [contactOptions, setContactOptions] = useState([]);
    const handleSearch = (e) => {
        if (e.key === "Enter") {
            setPaginationModel({ page: 0, pageSize: 15 });
            setSearch(searchInput);
        }
    };
    const createNewLead = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        var lead = {
            contactName: "",
            totalObjects: 0,
            status: 0,
            isDeleted: false,
            phoneNumber: "",
            email: "",
            leadNumber: 0,
            informationSubmitted: false,
            responsiblePersonName: "",
            // hasContract:false
        };
        yield LeadsApi.createNewLead(lead)
            .then((res) => {
            if (res.data) {
                const id = res.data.id;
                const companyId = res.data.companyId;
                const orderNumber = res.data.leadNumber;
                const newUrl = `/lead-detail?${new URLSearchParams({
                    id,
                    companyId,
                    orderNumber,
                }).toString()}`;
                navigate(newUrl);
            }
        })
            .finally(() => setLoading(false));
    });
    const [fileUploading, setFileUploading] = useState(false);
    const handleFileChange = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            var type = file.name.split(".")[1];
            if (type === "xlsx" || type === "xls") {
                setFileUploading(true);
                const formData = new FormData();
                formData.append("request", file);
                yield LeadsApi.importLeads(formData)
                    .then((res) => {
                    if (res && res.data) {
                        toast.success("Leads zijn succesvol geüpload");
                        setRefresh((prev) => !prev);
                    }
                    else {
                        toast.error("Upload een geldig formaatbestand of neem contact op met de beheerder");
                    }
                })
                    .finally(() => setFileUploading(false));
            }
            else {
                toast.error("Alleen xls, xlsx toegestaan.");
            }
        }
    });
    const handleChange = () => {
        setPaginationModel({ page: 0, pageSize: 15 });
        setIsSelectiveRoles((prev) => !prev);
    };
    const handleAutocompleteChange = (event, newValue) => {
        setPaginationModel({ page: 0, pageSize: 15 });
        setSelectedPerson(newValue);
    };
    useEffect(() => {
        UsersApi.getAllAdminUsers(false)
            .then((response) => {
            if (response.status === 200 && response.data != null) {
                const op = response.data.map((item) => {
                    return {
                        id: item.id,
                        label: `${item.firstName} ${item.lastName}`,
                        // map other properties if needed
                    };
                });
                // Define the "All" option
                const allOption = {
                    id: "all",
                    label: translate("All"),
                };
                setContactOptions([allOption, ...op]);
            }
        })
            .finally(() => { });
    }, []);
    return (_jsx(Container, Object.assign({ disableGutters: true, sx: { maxWidth: "100% !important" } }, { children: _jsxs(Box, { children: [_jsxs(Box, Object.assign({ sx: {
                        padding: "24px 0px",
                        display: "flex",
                        justifyContent: "space-between",
                    } }, { children: [_jsx(Typography, Object.assign({ sx: { width: "50%" }, variant: "h1", color: theme.palette.text.primary }, { children: "Verkoop" })), _jsxs(Grid, Object.assign({ container: true, item: true, xs: 12, md: 7, gap: 2, sx: {
                                justifyContent: "flex-end",
                            } }, { children: [_jsxs(Button, Object.assign({ variant: "outlined", component: "label", disabled: fileUploading }, { children: [fileUploading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : (_jsx(DownloadSimple, { size: 24 })), _jsx("input", { type: "file", hidden: true, onChange: handleFileChange })] })), checkManage(RolesPermissions, Modules.Leads) && (_jsx(Button, Object.assign({ variant: "contained", size: "medium", startIcon: !loading && _jsx(UserCheck, { size: 24 }), onClick: handleOpenModal, sx: { marginRight: "5px" } }, { children: "Verkoop toewijzen" }))), checkCreateEdit(RolesPermissions, Modules.Leads) && (_jsx(Button, Object.assign({ variant: "contained", size: "medium", startIcon: !loading && _jsx(Plus, {}), onClick: createNewLead, disabled: loading }, { children: loading ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : (translate("CreateLead")) })))] }))] })), _jsx(Box, Object.assign({ sx: {
                        padding: "16px 32px",
                        bgcolor: theme.palette.common.white,
                        borderRadius: "24px",
                    } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2 }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, xs: 12, md: 9, spacing: 2 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 5 }, { children: _jsx(FormControl, Object.assign({ fullWidth: true, variant: "outlined", size: "medium" }, { children: _jsx(TextField, Object.assign({ id: "search", label: "Zoeken", value: searchInput, onChange: (e) => setSearchInput(e.target.value), onKeyDown: (e) => handleSearch(e), variant: "outlined", placeholder: "ID # , Klant , etc...", size: "medium" }, { children: _jsx(SearchIconWrapper, { children: _jsx(SearchIcon, {}) }) })) })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 3 }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true, variant: "outlined", size: "medium" }, { children: [_jsx(InputLabel, { children: "Status" }), _jsxs(Select, Object.assign({ labelId: "simple-select-label", id: "simple-select", label: "Status", value: status !== 0 ? status : undefined, placeholder: translate("SelectStatus"), onChange: (e) => {
                                                        if (e.target.value === 0) {
                                                            setStatus(undefined);
                                                        }
                                                        else {
                                                            setStatus(e.target.value);
                                                        }
                                                    } }, { children: [_jsx(MenuItem, Object.assign({ value: 0 }, { children: translate("All") })), _jsx(MenuItem, Object.assign({ value: 1 }, { children: translate("New") })), _jsx(MenuItem, Object.assign({ value: 2 }, { children: translate("Quotation") })), _jsx(MenuItem, Object.assign({ value: 3 }, { children: translate("Signed") })), _jsx(MenuItem, Object.assign({ value: 4 }, { children: translate("Completed") })), _jsx(MenuItem, Object.assign({ value: 5 }, { children: translate("Remark") })), _jsx(MenuItem, Object.assign({ value: 6 }, { children: translate("Special") })), _jsx(MenuItem, Object.assign({ value: 7 }, { children: translate("InReview") })), _jsx(MenuItem, Object.assign({ value: 8 }, { children: translate("Toewijzen") })), _jsx(MenuItem, Object.assign({ value: 9 }, { children: translate("Verlopen – concurrentie") })), _jsx(MenuItem, Object.assign({ value: 10 }, { children: translate("Verlopen – foutief ingeschreven") })), _jsx(MenuItem, Object.assign({ value: 11 }, { children: translate("Verlopen – niet bekend") })), _jsx(MenuItem, Object.assign({ value: 12 }, { children: translate("Verlopen – prijs") })), _jsx(MenuItem, Object.assign({ value: 13 }, { children: translate("Verlopen – opdracht via andere offerte") }))] }))] })) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: _jsx(Autocomplete, { disablePortal: true, id: "combo-box-demo", options: contactOptions, onChange: handleAutocompleteChange, sx: { width: 300 }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Selecteer verantwoordelijke" }))) }) }))] })), _jsx(Grid, Object.assign({ container: true, item: true, xs: 12, md: 3, gap: 2, sx: {
                                    justifyContent: "flex-end",
                                } }, { children: _jsx(FormGroup, { children: _jsx(FormControlLabel, { value: "bottom", control: _jsx(Switch, { checked: !isSelectiveRoles, onChange: () => handleChange() }), label: "Toon alle rollen", labelPlacement: "bottom" }) }) }))] })) }))] }) })));
};
