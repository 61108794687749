var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import AuthApi from "../../../infrastructure/api/auth/auth-api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Button, } from "@mui/material";
import logo from "../../../images/logo-coloured.svg";
export const ResetPassword = () => {
    var _a, _b, _c, _d;
    const [confirmPassword, setConfirmPassword] = useState({
        password: "",
        confirmPassword: "",
    });
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const initialState = {
        token: (_b = (_a = searchParams.get("token")) === null || _a === void 0 ? void 0 : _a.replaceAll(" ", "+")) !== null && _b !== void 0 ? _b : "",
        email: searchParams.get("email"),
        password: "",
    };
    const { t: translate } = useTranslation();
    const { register, watch, handleSubmit, formState: { errors }, } = useForm();
    const [resetPasswordDtoCredentials, setresetPasswordDtoCredentials] = useState(initialState);
    useEffect(() => {
        setresetPasswordDtoCredentials(Object.assign(Object.assign({}, resetPasswordDtoCredentials), { password: confirmPassword.password }));
    }, [confirmPassword]);
    const onSubmit = (confirmPassword) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            if (confirmPassword.password === confirmPassword.confirmPassword) {
                resetPasswordDtoCredentials.password = confirmPassword.password;
                var result = yield AuthApi.resetPassword(resetPasswordDtoCredentials);
                if (result.data) {
                    toast.info(translate("PasswordReset"));
                    setLoading(false);
                    navigate("/");
                }
            }
        }
        catch (error) {
            toast.error(translate("InvalidToken"));
            setLoading(false);
        }
    });
    const validatePassword = (password) => {
        if (password.length < 8)
            return translate("MustHaveCharacter");
        if (!/[A-Z]+/.test(password))
            return translate("MustHaveUperCase");
        if (!/[a-z]+/.test(password))
            return translate("MustHaveLowerCase");
        if (!/[0-9]+/.test(password))
            return translate("MustHaveOneNumber");
        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password))
            return translate("SpecialCharacter");
    };
    const validateConfirmPassword = (confirmPassword) => {
        if (watch("password") !== confirmPassword)
            return translate("PasswordDoNotMatch");
    };
    return (_jsx("div", Object.assign({ className: "login-container" }, { children: _jsx("div", Object.assign({ className: "login-outer" }, { children: _jsx("div", Object.assign({ className: "fields" }, { children: _jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 5 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx("img", { src: logo, className: "login-logo", height: 60, alt: "logo" }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(TextField, Object.assign({ type: "password" }, register("password", {
                                    required: "Password is required",
                                    validate: validatePassword
                                    // minLength: {
                                    //   value: 8,
                                    //   message: "Password must be at least 8 characters",
                                    // },
                                }), { error: !!errors.password, helperText: (_c = errors.password) === null || _c === void 0 ? void 0 : _c.message, id: "password", label: "Password", placeholder: "*******" })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(TextField, Object.assign({ type: "password" }, register("confirmPassword", {
                                    required: "Confirm Password is required",
                                    validate: (value) => value === watch("password") || "Passwords do not match",
                                }), { error: !!errors.confirmPassword, helperText: (_d = errors.confirmPassword) === null || _d === void 0 ? void 0 : _d.message, id: "confirm-password", label: "Confirm Password", placeholder: "*******" })) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Button, Object.assign({ variant: "contained", disableElevation: true, size: "large", type: "submit", color: "primary", disabled: loading }, { children: loading ? "Loading..." : "Submit" })) }))] })) })) })) })) })));
};
