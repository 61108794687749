import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { Layout } from "../../components/Layout/Layout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WorkPreparationTopHeader } from "./WorkpreparationTopHeader";
import WorkPreparationContracts from "../../../infrastructure/api/pages/work-preparation-contracts-api";
import { CheckCircle, XCircle } from "@phosphor-icons/react";
import { DataGrid, } from "@mui/x-data-grid";
export const Workpreparation = () => {
    const theme = useTheme();
    const { t: translate } = useTranslation();
    const [contracts, setContracts] = useState([]);
    const [search, setSearch] = useState("");
    const [completed, setCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        setLoading(true);
        WorkPreparationContracts.getAllContracts(completed, search)
            .then((response) => {
            if (response.status === 200 && response.data != null) {
                // setContracts(response.data);
                setContracts(Array.from(new Set(response.data.map((x, i) => {
                    return Object.assign(Object.assign({}, x), { index: i + 1 });
                }))));
            }
        })
            .finally(() => {
            setTimeout(() => setLoading(false), 400);
        });
    }, [search, completed]);
    const columnsForDataGrid = [
        {
            field: "index",
            headerName: translate("Id"),
            sortable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            minWidth: 20,
        },
        {
            field: "contractName",
            headerName: translate("ContractName"),
            sortable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            // hideSortIcons: true,
            minWidth: 150,
        },
        {
            field: "companyName",
            headerName: translate("RelationName"),
            sortable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            minWidth: 150,
        },
        {
            field: "totalObjects",
            headerName: translate("TotalObjects"),
            sortable: false,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: true,
            minWidth: 130,
            //   format: (value: number) => value.toFixed(2),
        },
        {
            field: "preferredMonth",
            headerName: translate("InspectionDate"),
            sortable: false,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: true,
            minWidth: 170,
            renderCell: (params) => (_jsx(Typography, Object.assign({ variant: "body2" }, { children: params.row.preferredMonth
                    ? new Date(params.row.preferredMonth).toLocaleDateString("nl")
                    : "" }))),
        },
        {
            field: "isSigned",
            headerName: "Contract getekend",
            sortable: false,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: true,
            minWidth: 100,
            renderCell: (params) => params.row.isSigned ? (_jsx(CheckCircle, { size: 25, color: "green" })) : (_jsx(XCircle, { size: 25, color: "red" })),
        },
        {
            field: "isContinous",
            headerName: "Doorlopend contract",
            sortable: false,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: true,
            minWidth: 100,
            renderCell: (params) => params.row.isContinous ? (_jsx(CheckCircle, { size: 25, color: "green" })) : (_jsx(XCircle, { size: 25, color: "red" })),
        },
    ];
    const handleEvent = (params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
    ) => {
        const contract = params.row;
        if (contract) {
            const id = contract.id;
            const orderNumber = contract.leadNumber;
            const newUrl = `/workpreparation-details?${new URLSearchParams({
                id,
                orderNumber,
            }).toString()}`;
            navigate(newUrl);
        }
    };
    return (_jsxs(Layout, { children: [_jsx(WorkPreparationTopHeader, { search: search, setSearch: setSearch, completed: completed, setCompleted: setCompleted }), _jsx(Box, Object.assign({ sx: {
                    marginTop: "1%",
                    padding: "16px 32px",
                    bgcolor: theme.palette.common.white,
                    borderRadius: "24px",
                } }, { children: _jsx(Paper, Object.assign({ sx: { width: "100%", overflow: "hidden", boxShadow: "none" } }, { children: _jsx(DataGrid, { autoHeight: true, rows: contracts !== null && contracts !== void 0 ? contracts : [], onRowClick: handleEvent, columns: columnsForDataGrid, initialState: {
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }, pageSizeOptions: [10], disableRowSelectionOnClick: true, hideFooterPagination: !(contracts === null || contracts === void 0 ? void 0 : contracts.length), rowHeight: 80, sx: {
                            border: "none",
                            fontSize: "14px",
                            "& .has-contract-row": {
                                backgroundColor: "#ffff",
                            },
                            "& .MuiDataGrid-columnHeaderTitle ": {
                                fontSize: "20px",
                                fontWeight: 400,
                            },
                            "& .MuiDataGrid-cell": {
                                display: "flex",
                                alignItems: "center",
                            },
                        }, sortingOrder: ["asc", "desc"] }, "leads-grid") })) }))] }));
};
