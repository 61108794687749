var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Buildings, MapTrifold, UsersThree } from "@phosphor-icons/react";
import { useState } from "react";
import CompanyInfoTab from "./CompanyInfoTab";
import ContactPersonTab from "./ContactPersonTab";
import ObjectTab from "./ObjectTab";
import { useTranslation } from "react-i18next";
function CustomTabPanel(props) {
    const { children, value, index } = props, other = __rest(props, ["children", "value", "index"]);
    return (_jsx(Box, Object.assign({ role: "tabpanel", hidden: value !== index, id: `simple-tabpanel-${index}`, "aria-labelledby": `simple-tab-${index}` }, other, { children: children })));
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const LeadDetailsTabs = ({ setRefreshModalData, refresh, setActionTabRefresh, }) => {
    const [value, setValue] = useState(0);
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down("sm"));
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Tabs, Object.assign({ className: "tab", value: value, onChange: handleChange, TabIndicatorProps: {
                    sx: {
                        display: "none",
                    },
                } }, { children: [_jsx(Tab, Object.assign({ label: _jsxs(_Fragment, { children: [_jsx(Buildings, { size: 20 }), !isSM && _jsx(Typography, Object.assign({ className: "tab-text" }, { children: translate("Company") }))] }) }, a11yProps(1))), _jsx(Tab, Object.assign({ label: _jsxs(_Fragment, { children: [_jsx(UsersThree, { size: 20 }), !isSM && _jsx(Typography, Object.assign({ className: "tab-text" }, { children: translate("ContactPerson") }))] }) }, a11yProps(2))), _jsx(Tab, Object.assign({ label: _jsxs(_Fragment, { children: [_jsx(MapTrifold, { size: 20 }), !isSM && _jsx(Typography, Object.assign({ className: "tab-text" }, { children: translate("Object") }))] }) }, a11yProps(3)))] })), _jsx(CustomTabPanel, Object.assign({ value: value, index: 0 }, { children: _jsx(CompanyInfoTab, {}) })), _jsx(CustomTabPanel, Object.assign({ value: value, index: 1 }, { children: _jsx(ContactPersonTab, { setRefreshModalData: setRefreshModalData }) })), _jsx(CustomTabPanel, Object.assign({ value: value, index: 2 }, { children: _jsx(ObjectTab, { setRefreshModalData: setRefreshModalData, refreshObjects: refresh, setActionTabRefresh: setActionTabRefresh }) }))] }));
};
export default LeadDetailsTabs;
