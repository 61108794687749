import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { rolesActions } from "../../../application/common/utils/roles-helper";
import { UserRolesPermissionsContext, } from "../../../context/UserRolesPermissionsContext";
import { UnauthorizedPage } from "../UnAuthorizedPage/UnAuthorizedPage";
import LocalStorageService from "../../../infrastructure/local-storage-service";
import { useNavigate } from "react-router";
import { Layout } from "../../components/Layout/Layout";
import "../../components/Loader/Loading.css";
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/material';
const ProtectedRoute = ({ screen, children, action }) => {
    const [userRolesPermissions, setUserRolesPermissions] = useState();
    const navigate = useNavigate();
    const { RolesPermissions, UserRolesPermissionsSet } = useContext(UserRolesPermissionsContext);
    useEffect(() => {
        UserRolesPermissionsSet();
    }, []);
    useEffect(() => {
        setUserRolesPermissions(RolesPermissions);
    }, [RolesPermissions]);
    if (!LocalStorageService.getAccessToken()) {
        navigate("/");
        // if(isAuthenticated){
        //   instance.logoutRedirect();
        // }
        // else{
        //   navigate("/");
        // }
    }
    if (userRolesPermissions && userRolesPermissions.length > 0) {
        const allowedActions = rolesActions(userRolesPermissions, screen);
        if (!(allowedActions === null || allowedActions === void 0 ? void 0 : allowedActions.includes(action)))
            return _jsx(UnauthorizedPage, {});
        return children;
    }
    else if (LocalStorageService.getAccessToken()) {
        return (_jsx(Layout, { children: _jsx(Stack, Object.assign({ alignItems: "center" }, { children: _jsx(CircularProgress, {}) })) }));
    }
    else {
        return _jsx(UnauthorizedPage, {});
    }
};
export default ProtectedRoute;
