import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Skeleton, TableCell, TableRow, styled, tableCellClasses, } from "@mui/material";
const TableRowsLoader = ({ rowsNum, cellNum, }) => {
    return (_jsx(_Fragment, { children: [...Array(rowsNum)].map((_, index) => (_jsx(TableRow, { children: [...Array(cellNum)].map((_, index) => (_jsx(TableCell, Object.assign({ component: "th", scope: "row" }, { children: _jsx(Skeleton, { animation: "wave", variant: "text" }, index) }), index))) }, index))) }));
};
const StyledTableRow = styled(TableRow)(({ infoSubmitted }) => ({
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    // "& td, & th": {
    //   // Apply your custom styles here
    //   backgroundColor: infoSubmitted ||infoSubmitted==undefined ? "inherit":"#F4FCFF !important", // Default background color
    // },
}));
const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    "&:nth-of-type(odd)": {
        backgroundColor: "white",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "white",
    },
}));
export { StyledTableCell, StyledTableRow, TableRowsLoader };
