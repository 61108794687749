import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CustomModal from "../modal/CustomModal";
import { Table, TableHead, TableBody, TableCell, TableRow, Checkbox, } from "@mui/material";
import { ModuleActions, Modules, } from "../../../application/common/enums/screen-name";
import { useContext, useEffect, useState } from "react";
import RolePermissionApi from "../../../infrastructure/api/pages/role-permission-api";
import { toast } from "react-toastify";
import { UserRolesPermissionsContext } from "../../../context/UserRolesPermissionsContext";
import { checkCreateEdit } from "../../../application/common/utils/roles-helper";
const defaultPermission = {
    readId: "",
    read: false,
    createId: "",
    create: false,
    manageId: "",
    manage: false,
    deleteId: "",
    delete: false,
    readAction: ModuleActions.Read,
    createAction: ModuleActions.Create,
    manageAction: ModuleActions.Manage,
    deleteAction: ModuleActions.Delete,
};
const RolePermissionModal = ({ title, roleId, showPopup, onClosePopup, translate, }) => {
    const [rolePermissions, setRolePermissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const newRolePermissions = Object.values(Modules).map((moduleName) => ({
        id: null,
        roleId: roleId,
        screenName: moduleName,
        permissions: defaultPermission,
    }));
    const { RolesPermissions, UserRolesPermissionsSet } = useContext(UserRolesPermissionsContext);
    useEffect(() => {
        setRolePermissions(newRolePermissions);
        if (roleId) {
            RolePermissionApi.getRolePermissionByRoleId(roleId).then((response) => {
                const data = response.data && response.status === 200;
                if (data) {
                    const mappedRolePermissions = Object.values(Modules).map((moduleName) => {
                        var _a;
                        const existingPermission = response.data.filter((perm) => perm.screenName === moduleName);
                        const read = existingPermission.find((x) => x.action === ModuleActions.Read);
                        const create = existingPermission.find((x) => x.action === ModuleActions.Create);
                        const manage = existingPermission.find((x) => x.action === ModuleActions.Manage);
                        const deleterole = existingPermission.find((x) => x.action === ModuleActions.Delete);
                        return {
                            id: ((_a = existingPermission[0]) === null || _a === void 0 ? void 0 : _a.id) || null,
                            roleId: roleId,
                            screenName: moduleName,
                            permissions: {
                                read: (read === null || read === void 0 ? void 0 : read.isDeleted) === false ? true : false,
                                readId: read === null || read === void 0 ? void 0 : read.id,
                                readAction: ModuleActions.Read,
                                create: (create === null || create === void 0 ? void 0 : create.isDeleted) === false ? true : false,
                                createId: create === null || create === void 0 ? void 0 : create.id,
                                createAction: ModuleActions.Create,
                                manage: (manage === null || manage === void 0 ? void 0 : manage.isDeleted) === false ? true : false,
                                manageId: manage === null || manage === void 0 ? void 0 : manage.id,
                                manageAction: ModuleActions.Manage,
                                delete: (deleterole === null || deleterole === void 0 ? void 0 : deleterole.isDeleted) === false ? true : false,
                                deleteId: deleterole === null || deleterole === void 0 ? void 0 : deleterole.id,
                                deleteAction: ModuleActions.Delete,
                            },
                        };
                    });
                    setRolePermissions(mappedRolePermissions);
                }
            });
        }
    }, [roleId]);
    useEffect(() => {
        {
            checkCreateEdit(RolesPermissions, Modules.RoleManagement)
                ? setDisabled(false)
                : setDisabled(true);
        }
    }, []);
    const closePopup = () => {
        setRolePermissions(newRolePermissions);
        onClosePopup();
    };
    const onSubmit = () => {
        setLoading(true);
        if (rolePermissions[0].id) {
            RolePermissionApi.updateRolePermission(rolePermissions)
                .then((response) => {
                if (response.data && response.status === 200) {
                    toast.info(translate("UpdatedSuccessfully"));
                    closePopup();
                    UserRolesPermissionsSet();
                }
            })
                .finally(() => setLoading(false));
        }
        else {
            RolePermissionApi.postRolePermission(rolePermissions)
                .then((response) => {
                const data = response.data && response.status == 200;
                if (data) {
                    toast.info(translate("SavedSuccessfully"));
                    closePopup();
                }
            })
                .finally(() => setLoading(false));
        }
    };
    return (_jsx(CustomModal, Object.assign({ open: showPopup, onClose: closePopup, size: "sm", saving: loading, handleSaveButtonClick: onSubmit, heading: title, saveButtonText: translate("Save"), disabled: disabled }, { children: _jsxs(Table, Object.assign({ stickyHeader: true }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: translate("Module") }), _jsx(TableCell, { children: "Lees" }), _jsx(TableCell, { children: "Aanmaken" }), _jsx(TableCell, { children: "Aanpassen" }), _jsx(TableCell, { children: "Verwijderen" })] }) }), _jsx(TableBody, { children: rolePermissions.map((rolepermissions, index) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: translate(rolepermissions.screenName) }), _jsx(TableCell, { children: _jsx(Checkbox, { checked: rolepermissions.permissions.read, onChange: (e) => {
                                        const checked = e.target.checked;
                                        setRolePermissions((prevPermissions) => {
                                            const updatedPermissions = [...prevPermissions];
                                            updatedPermissions[index].permissions = Object.assign(Object.assign({}, updatedPermissions[index].permissions), { read: checked });
                                            return updatedPermissions;
                                        });
                                    }, disabled: rolePermissions[index].permissions.create === true }) }), _jsx(TableCell, { children: _jsx(Checkbox, { checked: rolepermissions.permissions.create, onChange: (e) => {
                                        const checked = e.target.checked;
                                        setRolePermissions((prevPermissions) => {
                                            const updatedPermissions = [...prevPermissions];
                                            if (rolepermissions.screenName === Modules.Leads) {
                                                // If the permission name is "Leads", set all permissions to checked except delete
                                                updatedPermissions[index].permissions = Object.assign(Object.assign({}, updatedPermissions[index].permissions), { read: checked, create: checked, manage: checked });
                                            }
                                            else {
                                                // If not "Leads", set only create and read permissions
                                                updatedPermissions[index].permissions = Object.assign(Object.assign({}, updatedPermissions[index].permissions), { read: checked, create: checked });
                                            }
                                            return updatedPermissions;
                                        });
                                    }, disabled: rolePermissions[index].permissions.manage === true }) }), _jsx(TableCell, { children: _jsx(Checkbox, { checked: rolepermissions.permissions.manage, onChange: (e) => {
                                        const checked = e.target.checked;
                                        setRolePermissions((prevPermissions) => {
                                            const updatedPermissions = [...prevPermissions];
                                            updatedPermissions[index].permissions = Object.assign(Object.assign({}, updatedPermissions[index].permissions), { read: checked, create: checked, manage: checked });
                                            return updatedPermissions;
                                        });
                                    }, disabled: rolePermissions[index].permissions.delete === true }) }), _jsx(TableCell, { children: _jsx(Checkbox, { checked: rolepermissions.permissions.delete, onChange: (e) => {
                                        const checked = e.target.checked;
                                        setRolePermissions((prevPermissions) => {
                                            const updatedPermissions = [...prevPermissions];
                                            updatedPermissions[index].permissions = Object.assign(Object.assign({}, updatedPermissions[index].permissions), { read: checked, create: checked, manage: checked, delete: checked });
                                            return updatedPermissions;
                                        });
                                    } }) })] }, index))) })] })) })));
};
export default RolePermissionModal;
