import { useEffect } from 'react';
import { EventType } from '@azure/msal-browser';
const useMsalEvents = (msalInstance) => {
    useEffect(() => {
        const callbackId = msalInstance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGOUT_SUCCESS) {
                console.log("Logout complete. Clearing local storage.");
                localStorage.clear();
            }
        });
        // Clean up the event callback on component unmount
        return () => {
            if (callbackId) {
                msalInstance.removeEventCallback(callbackId);
            }
        };
    }, [msalInstance]);
};
export default useMsalEvents;
