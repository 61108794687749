var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { loginRequest } from "./msalConfig";
const MicrosoftLogin = () => {
    const { instance } = useMsal();
    const handleLoginRedirect = () => __awaiter(void 0, void 0, void 0, function* () {
        yield instance.initialize();
        instance.handleRedirectPromise()
            .then(res => {
            console.log(res);
        })
            .catch(err => {
            console.error(err);
        });
        yield instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    });
    useEffect(() => {
        handleLoginRedirect();
    }, []);
    return (_jsx(_Fragment, {}));
};
export default MicrosoftLogin;
