var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Message from "../AuditSection/Message";
import useAsyncEffect from "use-async-effect";
import LeadDetailApi from "../../../../infrastructure/api/pages/lead-detail-api";
import { useState } from "react";
import moment from "moment";
const AuditSection = ({ refresh }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const leadId = urlParams.get("id");
    const theme = useTheme();
    const [log, setLogs] = useState([]);
    useAsyncEffect(() => __awaiter(void 0, void 0, void 0, function* () {
        if (leadId) {
            const logs = yield LeadDetailApi.getLeadLogs(leadId);
            setLogs(logs.data);
        }
    }), [leadId, refresh]);
    return (_jsxs(Grid, Object.assign({ container: true, item: true, className: "chatBox", sx: {
            justifyContent: "space-between",
            bgcolor: theme.palette.common.white,
            borderRadius: "24px",
            padding: "var(--2, 16px) var(--4, 32px)",
            gap: "var(--2, 16px)",
        } }, { children: [_jsx(Box, { children: _jsx(Typography, Object.assign({ className: "heading" }, { children: "Historie" })) }), log.length === 0 ? (_jsx(Grid, Object.assign({ item: true, lg: 12, xs: 12, display: "flex", justifyContent: "space-between" }, { children: _jsx(Typography, Object.assign({ sx: { width: "85%" } }, { children: "Geen historie" })) }))) : (log.map((d, i) => {
                return (_jsx(Message
                // message={
                //   d.description === "Offerte afgewezen" ||
                //   d.description === "Offerte ondertekend" ||
                //   d.description === "Portaal geopend door klant" ||
                //   d.description === "Door de klant ingediende informatie" ||
                //   d.description === "Offerte geaccepteerd"
                //     ? "Uitgevoerd door klant"
                //     : d.userName && d.userName != null
                //     ? "Uitgevoerd door " + d.userName!
                //     : ""
                // }
                , { message: d.description, name: d.action, date: moment
                        .utc(d.createdDateTime)
                        .local()
                        .format("DD-MM-YYYY HH:mm:ss"), maxCharacters: 200, contractId: d.contractId }, i));
            }))] })));
};
export default AuditSection;
