import { http_api } from "../../leadg-api";
const getAllLeads = (page = 0, pageSize = 15, search, status, isSelectiveRoles, isPageChanged) => {
    // if (search && status)
    //   return http_api.get(`lead/get-leads?search=${search}&status=${status}`);
    // else if (search) return http_api.get(`lead/get-leads?search=${search}`);
    // else if (status) return http_api.get(`lead/get-leads?status=${status}`);
    // else return http_api.get(`lead/get-leads`);
    let url = `lead/get-leads?page=${page}&pageSize=${pageSize}&isSelectiveRoles=${isSelectiveRoles}`;
    if (search)
        url += `&search=${search}`;
    if (status)
        url += `&status=${status}`;
    url += `&isPageChanged=${isPageChanged}`;
    return http_api.get(url);
};
const getAllLeadsByUser = (page = 1, pageSize = 15, search, status, currentUserId, isSelectiveRoles, isPageChanged) => {
    // if (search && status)
    //   return http_api.get(`lead/get-leads-by-user?search=${search}&status=${status}&currentUserId=${currentUserId}`);
    // else if (search) return http_api.get(`lead/get-leads-by-user?search=${search}&currentUserId=${currentUserId}`);
    // else if (status) return http_api.get(`lead/get-leads-by-user?status=${status}&currentUserId=${currentUserId}`);
    // else return http_api.get(`lead/get-leads-by-user?currentUserId=${currentUserId}`);
    let url = `lead/get-leads-by-user?page=${page}&pageSize=${pageSize}&currentUserId=${currentUserId}&isSelectiveRoles=${isSelectiveRoles}`;
    if (search)
        url += `&search=${search}`;
    if (status)
        url += `&status=${status}`;
    url += `&isPageChanged=${isPageChanged}`;
    return http_api.get(url);
};
const createNewLead = (lead) => {
    return http_api.post(`lead/add-lead`, lead);
};
const updateLead = (lead) => {
    return http_api.put("lead/update-lead", lead);
};
const getLeadByLeadId = (leadId) => {
    return http_api.get(`lead/get-lead-by-id/${leadId}`);
};
const assignMultipleLead = (assignMultipleLead) => {
    return http_api.post(`lead/assign-responsible-person-mulitple-leads`, assignMultipleLead);
};
const config = {
    headers: {
        "content-type": "multipart/form-data",
    },
};
const importLeads = (input) => {
    return http_api.post(`lead/import-leads`, input, config);
};
const LeadsApi = {
    getAllLeads,
    createNewLead,
    updateLead,
    getLeadByLeadId,
    getAllLeadsByUser,
    assignMultipleLead,
    importLeads
};
export default LeadsApi;
