var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState } from "react";
import RolePermissionApi from "../infrastructure/api/pages/role-permission-api";
import LocalStorageService from "../infrastructure/local-storage-service";
const RolesPermissionContext = {
    RolesPermissions: [],
    UserRolesPermissionsSet: () => { }
};
export const UserRolesPermissionsContext = createContext(RolesPermissionContext);
export const UserRolesPermissionsContextProvider = ({ children }) => {
    const [userRolesPermissions, setUserRolesPermissions] = useState([]);
    const UserRolesPermissionsSet = () => __awaiter(void 0, void 0, void 0, function* () {
        var access = LocalStorageService.getAccessToken();
        if (access) {
            RolePermissionApi.getRolePermissionByUserId().then((response) => {
                const data = (response === null || response === void 0 ? void 0 : response.data) && response.status === 200;
                if (data) {
                    setUserRolesPermissions(response.data);
                }
            });
        }
    });
    const permissions = {
        RolesPermissions: userRolesPermissions,
        UserRolesPermissionsSet
    };
    return (_jsx(UserRolesPermissionsContext.Provider, Object.assign({ value: permissions }, { children: children })));
};
