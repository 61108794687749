var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import "./ChatSection.scss";
import LeftMessage from "./LeftMessage/LeftMessage";
import RightMessage from "./RightMessage/RightMessage";
import { PaperPlaneTilt } from "@phosphor-icons/react";
import useAsyncEffect from "use-async-effect";
import LeadDiscussionApi from "../../../../infrastructure/api/pages/discussion-api";
import { useContext, useState } from "react";
import { AuthenticationContext } from "../../../../context/AuthenticationContext";
import moment from "moment";
import { UserRolesPermissionsContext } from "../../../../context/UserRolesPermissionsContext";
import { checkManage } from "../../../../application/common/utils/roles-helper";
import { Modules } from "../../../../application/common/enums/screen-name";
import { useTranslation } from "react-i18next";
const ChatSection = () => {
    var _a;
    const theme = useTheme();
    const { t: translate } = useTranslation();
    const [message, setMessage] = useState("");
    const [discussion, setDiscussion] = useState([]);
    const { getProfile } = useContext(AuthenticationContext);
    const [userId, setUserId] = useState((_a = getProfile()) === null || _a === void 0 ? void 0 : _a.userId);
    const urlParams = new URLSearchParams(window.location.search);
    const leadId = urlParams.get("id");
    const { RolesPermissions } = useContext(UserRolesPermissionsContext);
    const isMD = useMediaQuery(theme.breakpoints.down("md"));
    useAsyncEffect(() => __awaiter(void 0, void 0, void 0, function* () {
        if (leadId) {
            const discussion = yield LeadDiscussionApi.getDiscussionByLeadId(leadId);
            setDiscussion(discussion.data);
        }
    }), [leadId]);
    const sendChat = () => {
        if (leadId) {
            var lead = {
                leadId: leadId,
                messege: message,
                userId: userId,
                createdOn: moment().toDate(),
                userName: getProfile().name,
            };
            setDiscussion([...discussion, lead]);
            LeadDiscussionApi.sendChat(lead);
            setMessage("");
        }
    };
    return (_jsxs(Grid, Object.assign({ container: true, item: true, className: "chatBox", sx: {
            justifyContent: "space-between",
            bgcolor: theme.palette.common.white,
            borderRadius: "24px",
            padding: "var(--2, 16px) var(--4, 32px)",
            gap: "var(--2, 16px)",
        } }, { children: [_jsx(Box, { children: _jsx(Typography, Object.assign({ className: "heading" }, { children: "Opmerkingen" })) }), discussion.map((d) => {
                const isCurrentUser = d.userId === getProfile().userId;
                return isCurrentUser ? (_jsx(LeftMessage, { message: d.messege, name: d.userName, date: moment.utc(d.createdOn).local().format("DD-MM-YYYY HH:mm:ss"), maxCharacters: 200 })) : (_jsx(RightMessage, { message: d.messege, name: d.userName, date: moment.utc(d.createdOn).local().format("DD-MM-YYYY HH:mm:ss"), maxCharacters: 200 }));
            }), checkManage(RolesPermissions, Modules.Leads) && (_jsx(Grid, Object.assign({ container: true, item: true, className: "inputmessagecontainer" }, { children: _jsxs(Grid, Object.assign({ item: true, lg: 12, xs: 12, display: "flex", gap: 2, justifyContent: "space-between" }, { children: [_jsx(TextField, { id: "outlined-adornment-password", type: "text", placeholder: translate("Add a comment"), value: message, onChange: (ev) => setMessage(ev.target.value), inputProps: { maxLength: 2000 }, sx: { width: "85%" } }), _jsx(Button, Object.assign({ type: "submit", variant: "outlined", sx: {
                                borderRadius: "12px",
                                border: "var(--none, 1px) solid var(--n-primary, #130922)",
                                background: "var(--n-primary-light, #F9F6FF)",
                                display: "flex",
                                padding: "12px !important",
                                alignItems: "flex-start",
                                gap: "10px",
                                minWidth: "auto !important",
                            }, disabled: !message || message.trim() === "", onClick: sendChat }, { children: _jsx(PaperPlaneTilt, { size: isMD ? 20 : 32 }) }))] })) })))] })));
};
export default ChatSection;
