import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { DotsThree, CaretDoubleUp } from "@phosphor-icons/react";
const RightMessage = ({ message, name, date, maxCharacters, }) => {
    const [showFullText, setShowFullText] = useState(false);
    const toggleTextVisibility = () => {
        setShowFullText(!showFullText);
    };
    const truncatedText = showFullText
        ? message
        : message.slice(0, maxCharacters);
    return (_jsxs(Grid, Object.assign({ container: true, item: true, className: "rightmessage" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 2 }, { children: _jsx(Avatar, {}) })), _jsxs(Grid, Object.assign({ container: true, item: true, className: "content" }, { children: [_jsxs(Grid, Object.assign({ item: true, className: "namecontainer" }, { children: [_jsx(Typography, Object.assign({ className: "nametext" }, { children: name })), _jsx(Typography, Object.assign({ className: "datetext" }, { children: date }))] })), _jsx(Grid, Object.assign({ item: true }, { children: truncatedText })), message.length > maxCharacters &&
                        (showFullText ? (_jsx(CaretDoubleUp, Object.assign({ onClick: toggleTextVisibility }, { children: " " }))) : (_jsx(DotsThree, { onClick: toggleTextVisibility })))] }))] })));
};
export default RightMessage;
