import { http_api } from "../../leadg-api";
import "react-toastify/dist/ReactToastify.css";
const login = (credentials) => {
    return http_api.post("account/authenticate", credentials);
};
// Enable two factror
const EnableAuthenticateTwoFactor = (enableTwoFactorauthenticate) => {
    return http_api.post("account/enable-authenticate-Two-Factor-Code", enableTwoFactorauthenticate);
};
const LoadSharedKeyAndQrCodeUri = (email) => {
    return http_api.get(`account/load-secret-key/${email}`);
};
const GenerateQRCode = (email) => {
    return http_api.get(`account/qr-generate/${email}`, {
        responseType: "blob",
    });
};
const verifyToken = (refreshToken) => {
    return http_api.post("token/verify-token", refreshToken);
};
const confirmEmail = (confirmation) => {
    return http_api.post("account/email-confirmation", confirmation);
};
const forgotPassword = (input) => {
    return http_api.post("account/forgot-password", input);
};
const resetPassword = (input) => {
    return http_api.post("account/reset-password", input);
};
const getRefreshToken = (input) => {
    return http_api.post("token/refreshToken", input);
};
const adUserLogin = (input) => {
    return http_api.post("account/ad-user-login", input);
};
const AuthApi = {
    login,
    getRefreshToken,
    confirmEmail,
    forgotPassword,
    resetPassword,
    verifyToken,
    EnableAuthenticateTwoFactor,
    LoadSharedKeyAndQrCodeUri,
    GenerateQRCode,
    adUserLogin
};
export default AuthApi;
