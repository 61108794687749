import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./carousel.scss";
import { Box, Button, Grid, styled, Switch, Typography, useMediaQuery, useTheme, } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
export const CardContainer = ({ onChange, contract }) => {
    return (_jsxs(Box, Object.assign({ sx: {
            background: "#E3E0D9",
            width: "100%",
            height: "100%",
            pl: { xs: 2, sm: 10 },
            pr: { xs: 2, sm: 5 },
            pt: 7,
            pb: 8,
        } }, { children: [_jsx(Typography, Object.assign({ fontWeight: 500, fontSize: "32px", mb: 5, color: "initial" }, { children: "Onze dientsten" })), _jsx(Grid, Object.assign({ container: true, spacing: 4 }, { children: CardArray.map((item, index) => {
                    return (_jsx(Grid, Object.assign({ item: true, md: 6, lg: 3, xl: 3, sm: 6, xs: 12 }, { children: _jsx(Card, { color: item.background, title: item.title, description: item.description, url: item.url, isLast: index === 7 ? false : true, onChange: onChange, contract: contract }) })));
                }) }))] })));
};
export const Card = ({ url, color, title, description, isLast, onChange, contract, }) => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.between(1669, 2000));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between(1200, 1669));
    return (_jsxs(Box, Object.assign({ sx: {
            background: color,
            p: 4.5,
            minHeight: isLargeScreen ? 310 : isMediumScreen ? 370 : 320,
            height: "100%",
            position: "relative",
        } }, { children: [_jsx(Box, { children: _jsx(Typography, Object.assign({ fontSize: "18px", fontWeight: 500, color: "#0D1B1A" }, { children: title })) }), _jsx(Box, Object.assign({ sx: {
                    position: "absolute",
                    left: 39,
                    pr: 2,
                    top: 110,
                } }, { children: _jsx(Typography, Object.assign({ mt: 2, fontSize: "14px", fontWeight: 400, color: "text.primary" }, { children: description })) })), _jsx(Box, Object.assign({ width: 120, sx: { position: "absolute", bottom: 20, left: 39 } }, { children: isLast ? (_jsx(Button, Object.assign({ fullWidth: true, onClick: () => {
                        window.open(url, "_blank");
                    }, size: "small", sx: {
                        color: "#FFFFFF",
                        background: "#4D666D",
                        borderRadius: 1,
                        "&:hover": {
                            backgroundColor: "#3b4d4f",
                        },
                    } }, { children: "Lees meer" }))) : (_jsx(PinkSwitch, { checked: contract ? contract.trainingNewLetter : false, onChange: (event) => onChange(event, "trainingNewLetter") })) }))] })));
};
const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: "#4D666D",
        "&:hover": {},
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        background: "#4D666D",
    },
}));
const CardArray = [
    {
        url: "https://www.eurosafe.eu/nl/safety-circle/safety-circle-risk-risico-inventarisatie-en-evaluatie",
        background: "#B4D2DA",
        title: "Risico inventarisatie & evaluatie",
        description: "Een RI&E brengt risico's in kaart en oplossingen in beeld. Van inzicht en advies naar maatregelen en veilig werken, vol vertrouwen.",
    },
    {
        url: "https://www.eurosafe.eu/nl/safety-circle/safety-circle-levering-en-installatie",
        title: "Installatie",
        description: "Wij zijn specialist in het voorbereiden en monteren van veiligheidsoplossingen. Van ankerpunten tot davitarmen. Van hekwerk tot railsystemen.",
        background: "#FFFFFF",
    },
    {
        url: "https://www.eurosafe.eu/nl/safety-circle/safety-circle-inspection-inspectie-en-onderhoud",
        title: "Test & Inspectie",
        description: "Met ons eigen gecertificeerde team voeren wij inspecties, keuringen en onderhoud uit. En dat voor alle gangbare merken.",
        background: "#D0CCC3",
    },
    {
        url: "https://www.eurosafe.eu/nl/safety-circle/safety-circle-levering-en-installatie",
        background: "#B4D2DA",
        title: "PBM & Levering",
        description: "Wij gaan verder dan 'alleen het leveren van persoonlijke beschermingsmiddelen. Het begint met scherp advies.",
    },
    {
        url: "https://www.eurosafe.eu/nl/safety-circle/safety-circle-training-en-opleiding",
        title: "Training",
        description: "Het volgen van de juiste training vermindert ongelukken op de werkvloer. Wij ondersteunen bij het opleiden van medewerkers.",
        background: "#B4D2DA",
    },
    {
        url: "https://www.eurosafe.eu/nl/expertises/diensten/rope-access-team",
        title: "Rope Access",
        description: "Wij voeren Rope Access werkzaamheden uit voor jouw project. Dit doen wij veilig en effectief.",
        background: "#FFFFFF",
    },
    {
        url: "https://www.eurosafe.eu/nl/expertises/diensten/safety-en-rescue-team",
        title: "Safety & Rescue Support",
        description: "Een Safety & Rescue team staat klaar tijdens risicovolle werkzaamheden. Getraind op het uitvoeren van complexe situaties.",
        background: "#D0CCC3",
    },
    {
        url: "",
        background: "#B4D2DA",
        title: "Schrijf je in voor de nieuwsbrief",
        description: "Blijf op de hoogte van de laatste ontwikkelingen op het gebied van veilig werken op hoogte en in besloten ruimten.Schrijf je in",
    },
];
