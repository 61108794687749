export const RoofAccessMapping = {
    0: "No Record",
    1: "Mobiele Ladder < 6m",
    2: "Mobiele Ladder > 6m",
    3: "Kooi of gevel ladder",
    4: "Dakluik",
    5: "Deur",
    6: "Raam uitstap",
    7: "Anders",
    8: "Hoogwerker",
    9: "Onbekend"
};
