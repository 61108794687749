import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Skeleton, Typography, useTheme, } from "@mui/material";
import { Chart } from "react-google-charts";
import "./LineChartCard.scss";
import { useEffect, useState } from "react";
const LineChartCard = ({ chartData, loading, }) => {
    const theme = useTheme();
    const [data, setData] = useState();
    useEffect(() => {
        const formattedData = [
            ["Month", "Contracts"],
            ...chartData.map((item) => [
                item.yearMonth,
                item.contractCount,
            ]),
        ];
        setData(formattedData);
    }, [chartData, loading]);
    const options = {
        curveType: "function",
        legend: "none",
        series: {
            0: {
                type: "area",
                color: theme.palette.primary.main,
                pointSize: 0,
            }, // Set areaOpacity to create an area below the line
        },
    };
    return (_jsx(Grid, Object.assign({ item: true, lg: 6, md: 6, xs: 12 }, { children: _jsxs(Box, Object.assign({ className: "line-chart-container" }, { children: [_jsx(Box, Object.assign({ className: "line-chart-heading-container" }, { children: _jsx(Typography, Object.assign({ variant: "body1" }, { children: "Offertes verzonden" })) })), _jsx(Box, { children: loading ? (_jsx(Skeleton, { variant: "rectangular", width: "100%", height: 300, sx: { borderRadius: 2 } })) : (_jsx(Chart, { loader: _jsx(Skeleton, { variant: "rectangular", width: "100%", height: 300 }), chartType: "LineChart", width: "100%", height: "300px", data: data, options: options })) })] })) })));
};
export default LineChartCard;
