import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Typography } from "@mui/material";
import CustomModal from "../modal/CustomModal";
const ImportLeadsErrorModal = ({ open, onClose, errors, }) => {
    var _a, _b, _c, _d;
    const onCloseModal = () => {
        onClose();
    };
    return (_jsx(CustomModal, Object.assign({ open: open, onClose: onCloseModal, size: "lg", saving: false, heading: "Importfouten", disabled: false, saveButtonText: "Ok", handleSaveButtonClick: onCloseModal }, { children: _jsxs(Box, Object.assign({ sx: {
                marginTop: "1%",
                padding: "16px 32px",
                borderRadius: "24px",
            } }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: [_jsx(Typography, Object.assign({ variant: "h3" }, { children: "Companies" })), _jsxs(Typography, Object.assign({ variant: "h5" }, { children: ["Total Remaining Errors ", (_a = errors.companyErrors) === null || _a === void 0 ? void 0 : _a.length] })), _jsx(Box, { children: errors.companyErrors &&
                                errors.companyErrors.map((error, index) => (_jsx("p", { children: error }, index))) }), " "] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: [_jsx(Typography, Object.assign({ variant: "h3" }, { children: "Objects" })), _jsxs(Typography, Object.assign({ variant: "h5" }, { children: ["Total Remaining Errors ", (_b = errors.objectErrors) === null || _b === void 0 ? void 0 : _b.length] })), _jsx(Box, { children: errors.objectErrors &&
                                errors.objectErrors.map((error, index) => (_jsx("p", { children: error }, index))) })] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: [_jsx(Typography, Object.assign({ variant: "h3" }, { children: "Contacts" })), _jsxs(Typography, Object.assign({ variant: "h5" }, { children: ["Total Remaining Errors ", (_c = errors.contactErrors) === null || _c === void 0 ? void 0 : _c.length] })), _jsx(Box, { children: errors.contactErrors &&
                                errors.contactErrors.map((error, index) => (_jsx("p", { children: error }, index))) }), " "] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, md: 4 }, { children: [_jsx(Typography, Object.assign({ variant: "h3" }, { children: "Object - Companies" })), _jsxs(Typography, Object.assign({ variant: "h5" }, { children: ["Total Remaining Errors ", (_d = errors.objectCompanyErrors) === null || _d === void 0 ? void 0 : _d.length] })), _jsx(Box, { children: errors.objectCompanyErrors &&
                                errors.objectCompanyErrors.map((error, index) => (_jsx("p", { children: error }, index))) })] }))] })) })));
};
export default ImportLeadsErrorModal;
