import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Button, Grid, InputAdornment, TextField, Typography, } from "@mui/material";
import { Check, Pencil } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import "./OfferModal.scss";
import { convertToNLEuroFormat } from "../../../application/common/utils/helper";
const OfferLeadObjectPrice = ({ leadObject, index, updatePriceOfLeadObject, }) => {
    const [leadObjectEntity, setleadObjectEntity] = useState(leadObject);
    const [displayDiscount, setDisplayDiscount] = useState(false);
    const [discount, setdiscount] = useState(0);
    const savePrice = (data) => {
        updatePriceOfLeadObject(index, data);
    };
    const handleDiscount = (event) => {
        let inputValue = event.target.value;
        // Ensure input is a number
        if (!isNaN(inputValue)) {
            // Clamp input value to maximum of 100
            inputValue = Math.min(inputValue, 100);
            let newPrice = leadObject.price - (inputValue / 100) * leadObject.price;
            const updatedData = Object.assign(Object.assign({}, leadObjectEntity), { updatedPrice: parseFloat((newPrice > 375 ? newPrice : 375).toFixed(1)) });
            let maxDiscount = ((leadObject.price - 375) / leadObject.price) * 100;
            setdiscount(inputValue <= maxDiscount
                ? parseFloat(inputValue.toFixed(1))
                : parseFloat(maxDiscount.toFixed(1)));
            // Update the state with the new object
            setleadObjectEntity(updatedData);
        }
    };
    const handleObjectAmountChange = (event) => {
        let inputValue = event.target.value;
        // Ensure input is a number
        if (!isNaN(inputValue)) {
            // Clamp input value to maximum of oringnal price
            const newPrice = parseFloat(
            // inputValue <= leadObject.price ?
            inputValue
            //  : leadObject.price
            );
            const updatedData = Object.assign(Object.assign({}, leadObjectEntity), { updatedPrice: parseFloat(newPrice.toFixed(1)) });
            // Update the state with the new object
            setleadObjectEntity(updatedData);
        }
    };
    const handleObjectAmountBlur = () => {
        var _a, _b;
        // let newPrice =
        // leadObjectEntity.price <= leadObject.price ? leadObjectEntity.price : leadObject.price;
        let newPrice = (_b = (leadObjectEntity &&
            (((_a = leadObjectEntity.updatedPrice) !== null && _a !== void 0 ? _a : 0 > 375)
                ? leadObjectEntity.updatedPrice
                : 375))) !== null && _b !== void 0 ? _b : 0;
        const updatedData = Object.assign(Object.assign({}, leadObjectEntity), { updatedPrice: parseFloat(newPrice.toFixed(1)) });
        let discount = 100 - (newPrice / leadObject.price) * 100;
        setdiscount(parseFloat(discount.toFixed(1)));
        // Update the state with the new object
        setleadObjectEntity(updatedData);
    };
    useEffect(() => {
        if (leadObject) {
            const updatedData = Object.assign(Object.assign({}, leadObjectEntity), { updatedPrice: parseFloat(leadObject.price.toFixed(1)) });
            setleadObjectEntity(updatedData);
        }
    }, []);
    return (_jsxs(Grid, Object.assign({ container: true, item: true, lg: 12, xs: 12, justifyContent: "space-between" }, { children: [_jsxs(Grid, Object.assign({ item: true }, { children: [_jsxs(Typography, Object.assign({ variant: "body1" }, { children: [" ", leadObjectEntity.street] })), _jsxs(Typography, Object.assign({ variant: "body1" }, { children: [leadObjectEntity.houseNumber, " ", leadObjectEntity.addition, leadObjectEntity.city] }))] })), _jsxs(Grid, Object.assign({ display: displayDiscount ? "flex" : "none", className: `grid-container ${displayDiscount ? "visible" : ""}`, flexDirection: { xs: "column", md: "row" }, item: true, gap: { xs: 1, sm: 2 }, alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ variant: "body1", fontWeight: 500 }, { children: "Discount" })), _jsxs(Box, Object.assign({ display: "flex", gap: 1 }, { children: [_jsx(Box, Object.assign({ width: "120px" }, { children: _jsx(TextField, { variant: "outlined", size: "small", value: discount, type: "number", onChange: (e) => handleDiscount(e), 
                                    // inputProps={{ min: 0 }}
                                    sx: {
                                        minHeight: "0 !important",
                                        padding: "0 !important",
                                        paddingLeft: "15px",
                                    }, style: { height: "0 !important" }, InputProps: {
                                        endAdornment: _jsx(InputAdornment, Object.assign({ position: "end" }, { children: "%" })),
                                    } }) })), _jsx(Box, Object.assign({ width: "120px" }, { children: _jsx(TextField, { variant: "outlined", size: "small", value: leadObjectEntity.updatedPrice, onChange: handleObjectAmountChange, onBlur: handleObjectAmountBlur, sx: {
                                        minHeight: "0 !important",
                                        padding: "0 !important",
                                        paddingLeft: "15px",
                                    }, style: { height: "0 !important" }, InputProps: {
                                        startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: "\u20AC" }))),
                                    } }) }))] })), _jsx(Button, Object.assign({ sx: {
                            borderRadius: "var(--1, 8px)",
                            background: "var(--n-primary, #130922)",
                            padding: "4px",
                            height: "32px",
                            minWidth: "32px !important",
                        }, onClick: () => {
                            const timer = setTimeout(() => {
                                setDisplayDiscount(false);
                                savePrice(leadObjectEntity);
                            }, 400); // Adjust the delay time as needed (in milliseconds)
                            return () => clearTimeout(timer);
                        } }, { children: _jsx(Check, { size: 24, color: "white" }) }))] })), _jsxs(Grid, Object.assign({ item: true, display: !displayDiscount ? "flex" : "none", className: `grid-container ${!displayDiscount ? "visible" : ""}` }, { children: [_jsx(Typography, Object.assign({ variant: "body1", color: "var(--n-jetblack, #312F2F)", fontWeight: 500, paddingRight: "15px !important" }, { children: convertToNLEuroFormat(leadObjectEntity.updatedPrice) })), _jsx(Button, Object.assign({ sx: {
                            borderRadius: "var(--1, 8px)",
                            background: "var(--n-primary, #130922)",
                            padding: "4px",
                            height: "32px",
                            minWidth: "32px !important",
                        }, onClick: () => {
                            const timer = setTimeout(() => {
                                setDisplayDiscount(true);
                            }, 400); // Adjust the delay time as needed (in milliseconds)
                            return () => clearTimeout(timer);
                        } }, { children: _jsx(Pencil, { size: 24, color: "white" }) }))] }))] })));
};
export default OfferLeadObjectPrice;
