import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const UserPageFilter = ({ filter, setState }) => {
    const [statusFilter, setFilterStatus] = useState(filter);
    const { t: translate } = useTranslation();
    useEffect(() => {
        setState(statusFilter);
    }, [statusFilter]);
    return (_jsx(Box, Object.assign({ sx: { pb: .5 } }, { children: _jsx(Grid, Object.assign({ container: true, gap: 5, xs: 12, justifyContent: "flex-end" }, { children: _jsx(Grid, Object.assign({ item: true, xs: 8, sm: 4, md: 2 }, { children: _jsxs(FormControl, Object.assign({ variant: "outlined", fullWidth: true }, { children: [_jsx(InputLabel, { children: translate("SelectStatus") }), _jsxs(Select, Object.assign({ value: statusFilter, onChange: (e) => {
                                setFilterStatus(e.target.value);
                            }, label: translate("SelectStatus"), id: "status" }, { children: [_jsx(MenuItem, Object.assign({ value: "Active" }, { children: translate("Active") })), _jsx(MenuItem, Object.assign({ value: "Inactive" }, { children: translate("InActive") })), _jsx(MenuItem, Object.assign({ value: "All" }, { children: translate("All") }))] }))] })) })) })) })));
};
export default UserPageFilter;
