var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import AuthApi from "../../../../infrastructure/api/auth/auth-api";
const ConfigureAuth = ({ email }) => {
    const [secrectKey, setSecrectKey] = useState("");
    const [qrCodeUrl, setQRCodeUrl] = useState("");
    useEffect(() => {
        if (email) {
            loadSharedKeyAndQrCodeUri();
            getQRCode();
        }
    }, [email]);
    const loadSharedKeyAndQrCodeUri = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield AuthApi.LoadSharedKeyAndQrCodeUri(email);
            if (response.data) {
                setSecrectKey(response.data.secretKey);
            }
        }
        catch (error) {
            console.error("Error while fetching QrCode", error);
        }
    });
    const getQRCode = () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield AuthApi.GenerateQRCode(email);
        const qrImageBlob = new Blob([result.data], {
            type: "image/png",
        });
        const qrImageUrl = URL.createObjectURL(qrImageBlob);
        setQRCodeUrl(qrImageUrl);
    });
    return (_jsx("div", Object.assign({ className: "modal fade", id: "configureAuth", tabIndex: -1, "aria-labelledby": "configureAuthLabel", "aria-hidden": "true" }, { children: _jsx("div", Object.assign({ className: "modal-dialog modal-dialog-scrollable" }, { children: _jsxs("div", Object.assign({ className: "modal-content" }, { children: [_jsxs("div", Object.assign({ className: "modal-header" }, { children: [_jsx("h5", Object.assign({ className: "modal-title", id: "configureAuthLabel" }, { children: "Configure Authenticator App" })), _jsx("button", { type: "button", className: "btn-close", "data-bs-dismiss": "modal", "aria-label": "Close" })] })), _jsxs("div", Object.assign({ className: "modal-body" }, { children: [_jsx("p", { children: "To use an authenticator app go through the following steps:" }), _jsxs("ul", { children: [_jsxs("li", { children: ["Download a two-factor authenticator app like Google Authenticator for", " ", _jsx("a", Object.assign({ href: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" }, { children: "Android" })), "&", " ", _jsx("a", Object.assign({ href: "https://apps.apple.com/us/app/google-authenticator/id388497605" }, { children: "IOS" }))] }), _jsxs("li", { children: ["Scan the QR Code or enter this key", " ", _jsx("code", Object.assign({ className: "d-block" }, { children: secrectKey })), " into your two factor authenticator app.", qrCodeUrl && (_jsx("img", { className: "d-block mx-auto qrImage", src: qrCodeUrl, alt: "QR Code" }))] }), _jsx("li", { children: "Once you have scanned the QR code or input the key above, your two factor authentication app will provide you with a unique code. Enter the code in the confirmation box." })] })] })), _jsx("div", Object.assign({ className: "modal-footer" }, { children: _jsx("button", Object.assign({ type: "button", className: "btn btn-primary", "data-bs-dismiss": "modal" }, { children: "Got it" })) }))] })) })) })));
};
export default ConfigureAuth;
