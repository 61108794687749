var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography, useTheme, } from "@mui/material";
import { useContext, useEffect, useState, } from "react";
import { statusBgColor, statusTextColor, } from "../../../../application/common/utils/column-helper";
import { useTranslation } from "react-i18next";
import LeadsApi from "../../../../infrastructure/api/pages/lead-api";
import useAsyncEffect from "use-async-effect";
import UsersApi from "../../../../infrastructure/api/pages/users-api";
import { AuthenticationContext } from "../../../../context/AuthenticationContext";
import { UserRolesPermissionsContext } from "../../../../context/UserRolesPermissionsContext";
import { checkManage, } from "../../../../application/common/utils/roles-helper";
import { Modules } from "../../../../application/common/enums/screen-name";
import { toast } from "react-toastify";
const ActionSection = ({ leadId, refresh, setIsLeadSpecial, setRefreshModalData }) => {
    const theme = useTheme();
    const [status, setStatus] = useState(1);
    const { getProfile } = useContext(AuthenticationContext);
    const [result, setResult] = useState();
    const [users, setUsers] = useState([]);
    const actions = [
        "New",
        "Quotation",
        "Signed",
        "Completed",
        "Remark",
        "Special",
        "InReview",
        "Toewijzen",
        "Verlopen – concurrentie",
        "Verlopen – foutief ingeschreven",
        "Verlopen – niet bekend",
        "Verlopen – prijs",
        "Verlopen – opdracht via andere offerte"
    ];
    const { t: translate } = useTranslation();
    const [lead, setLead] = useState();
    const { RolesPermissions } = useContext(UserRolesPermissionsContext);
    const getData = (filter) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            var result = yield UsersApi.getAllAdminUsers(filter);
            if (result.data) {
                setUsers(result.data);
            }
        }
        catch (error) {
            console.error("Error while fetching adminusers:", error);
        }
    });
    useEffect(() => {
        getData(false);
    }, []);
    const saveStatus = (param) => __awaiter(void 0, void 0, void 0, function* () {
        let obj = lead;
        if (obj === null || obj === void 0 ? void 0 : obj.status) {
            obj.status = param;
        }
        yield LeadsApi.getLeadByLeadId(leadId).then((lead) => {
            setLead(lead.data);
            obj = lead.data;
            obj.status = param;
        });
        if (obj) {
            yield LeadsApi.updateLead(obj).then(() => {
                setRefreshModalData((prev) => !prev);
                toast.success(translate("UpdatedSuccessfully"));
            });
        }
    });
    useAsyncEffect(() => __awaiter(void 0, void 0, void 0, function* () {
        if (leadId) {
            yield LeadsApi.getLeadByLeadId(leadId).then((lead) => {
                setIsLeadSpecial(lead.data.status == 6);
                setLead(lead.data);
                setStatus(lead.data.status);
                setResult(lead.data.responsiblePersonId);
                if (lead.data.responsiblePersonId === undefined ||
                    lead.data.responsiblePersonId === null) {
                    updateObject(getProfile().userId);
                }
                else {
                    setResult(lead.data.responsiblePersonId);
                }
            });
        }
    }), [leadId, refresh]);
    const handleChange = (e) => __awaiter(void 0, void 0, void 0, function* () {
        setResult(e.target.value);
        updateObject(e.target.value);
    });
    const updateObject = (value) => __awaiter(void 0, void 0, void 0, function* () {
        let obj = lead;
        yield LeadsApi.getLeadByLeadId(leadId).then((lead) => {
            setLead(lead.data);
            obj = lead.data;
            obj.responsiblePersonId = value;
        });
        yield LeadsApi.updateLead(obj);
    });
    return (_jsxs(Grid, Object.assign({ container: true, item: true, sx: {
            justifyContent: "space-between",
            bgcolor: theme.palette.common.white,
            borderRadius: "24px",
            padding: "var(--2, 16px) var(--4, 32px)",
        } }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 6, sx: { gap: "12px" } }, { children: [_jsx(Box, { children: _jsx(InputLabel, Object.assign({ id: "simple-select-label" }, { children: _jsx(Typography, Object.assign({ variant: "h6", sx: { fontSize: "16px" } }, { children: "Status" })) })) }), _jsx(Box, { children: _jsx(FormControl, Object.assign({ fullWidth: true, variant: "standard", size: "medium" }, { children: _jsx(Select, Object.assign({ disableUnderline: true, labelId: "simple-select-status", id: "simple-select-status", value: status, onChange: (e) => {
                                    if (e.target.value === 0) {
                                        setStatus(undefined);
                                    }
                                    else {
                                        setStatus(e.target.value);
                                        saveStatus(e.target.value);
                                    }
                                } }, { children: actions.map((a, i) => {
                                    return (_jsx(MenuItem, Object.assign({ value: i + 1, 
                                        // disabled={getProfile().role !== "Admin"}
                                        disabled: !checkManage(RolesPermissions, Modules.Leads) }, { children: _jsx(Box, Object.assign({ bgcolor: statusBgColor[i + 1], padding: 1, borderRadius: 2 }, { children: _jsx(Typography, Object.assign({ variant: "h4", color: statusTextColor[i + 1] }, { children: translate(a) })) })) }), i));
                                }) })) })) })] })), _jsxs(Grid, Object.assign({ item: true, xs: 6, sx: { gap: "12px" } }, { children: [_jsx(Box, { children: _jsx(InputLabel, Object.assign({ id: "simple-select-label" }, { children: _jsx(Typography, Object.assign({ variant: "h6", sx: { fontSize: "16px" } }, { children: translate("ResponsiblePerson") })) })) }), _jsx(Box, { children: _jsx(FormControl, Object.assign({ fullWidth: true, variant: "standard", size: "medium" }, { children: _jsx(Select, Object.assign({ labelId: "simple-select-label", id: "simple-select", value: result ? result : getProfile().userId, disabled: !checkManage(RolesPermissions, Modules.Leads), onChange: (e) => {
                                    {
                                        checkManage(RolesPermissions, Modules.Leads) &&
                                            handleChange(e);
                                    }
                                } }, { children: users.map((user) => (_jsx(MenuItem, Object.assign({ value: user.id }, { children: user.firstName || user.lastName
                                        ? `${user.firstName} ${user.lastName}`
                                        : "Unknown User" }), user.id))) })) })) })] }))] })));
};
export default ActionSection;
