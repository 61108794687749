import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, CircularProgress, Grow, Link, Typography, } from "@mui/material";
import { ArrowCircleLeft, EnvelopeSimple, Phone, Trash, User, X, } from "@phosphor-icons/react";
import { useState } from "react";
import ContactObjectApi from "../../../infrastructure/api/pages/contact-object-api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Info } from "@phosphor-icons/react/dist/ssr";
const RoofAccessContactCard = ({ object, contact, onclickCard, openModal, refresh, }) => {
    const { t: translate } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [isCardFlipped, setIsCardFlipped] = useState(false);
    const handleInfoButtonClick = () => {
        setIsCardFlipped(true);
    };
    const handleArrowLeftButtonClick = () => {
        setIsCardFlipped(false);
    };
    const onClickCard = (contact) => {
        onclickCard(contact);
        openModal();
    };
    const onDelete = (id) => {
        setLoading(true);
        ContactObjectApi.DeleteContactObject(id)
            .then((res) => {
            if (res.data && res.status) {
                toast.info(translate("DeletedSuccessfully"));
                refresh(true);
            }
            else {
                toast.error(translate("ErrorDelete"));
            }
        })
            .catch(() => {
            console.error("Error while deleting Contact Object");
        })
            .finally(() => setLoading(false));
    };
    const handlePhoneNumberClick = (phoneNumber) => {
        window.open(`tel:${phoneNumber}`);
    };
    return (_jsxs(_Fragment, { children: [!isCardFlipped && (_jsx(Grow, Object.assign({ in: !isCardFlipped }, { children: _jsx(Box, Object.assign({ sx: {
                        width: {
                            xs: "100%",
                            sm: "49%",
                            md: "100%",
                            lg: "49%",
                        },
                        padding: {
                            xs: 0.5,
                            sm: 1,
                            md: 2,
                        },
                    }, className: "contact-card" }, { children: _jsxs(Box, Object.assign({ sx: { display: "flex" } }, { children: [_jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "space-between",
                                    gap: 0.5,
                                    flex: "1 1 auto",
                                } }, { children: [_jsx(Box, Object.assign({ width: 28, height: 28, className: "user-icon" }, { children: _jsx(User, {}) })), _jsx(Box, Object.assign({ onClick: (e) => {
                                            e.stopPropagation();
                                        }, className: "trashcontainer" }, { children: _jsx(Button, Object.assign({ size: "small", sx: {
                                                padding: {
                                                    xs: "0px !important",
                                                    sm: "0px !important",
                                                    md: "0px !important",
                                                },
                                                minWidth: 0,
                                                ":hover": { backgroundColor: "#f9f6ff" },
                                            }, onClick: (e) => {
                                                e.stopPropagation();
                                                onDelete(contact.id);
                                            }, disabled: loading }, { children: loading ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : (_jsx(Trash, { color: "#130922", size: 24 })) })) }))] })), _jsxs(Box, Object.assign({ sx: {
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    gap: 0.5,
                                    flex: "1 1 auto",
                                }, onClick: (event) => {
                                    // Check if the clicked element is a link
                                    if (event.target.tagName.toLowerCase() !== "a") {
                                        onClickCard(contact);
                                    }
                                } }, { children: [_jsx(Typography, Object.assign({ className: "name-heading" }, { children: contact.name })), _jsxs(Box, Object.assign({ sx: { display: "flex", gap: 0.5, alignItems: "center" } }, { children: [_jsx(Phone, { size: 24, color: "#495057" }), _jsx(Link, Object.assign({ className: "card-body-text", style: { cursor: "pointer" }, onClick: () => handlePhoneNumberClick(contact.phoneNumber), target: "_blank", rel: "noreferrer" }, { children: contact.phoneNumber }))] })), _jsxs(Box, Object.assign({ sx: {
                                            display: "flex",
                                            gap: 0.5,
                                            alignItems: "center",
                                            justifyContent: "center",
                                        } }, { children: [_jsx(Box, Object.assign({ display: "flex", alignItems: "center" }, { children: _jsx(EnvelopeSimple, { size: 24, color: "#495057" }) })), _jsx(Link, Object.assign({ className: "card-body-text", href: `mailto:${contact.email}`, target: "_blank", rel: "noreferrer", sx: {
                                                    wordWrap: "break-word",
                                                    whiteSpace: "pre-wrap",
                                                    overflowWrap: "anywhere",
                                                    display: "inline-block",
                                                } }, { children: contact.email }))] })), object.map((name) => (_jsxs(Box, Object.assign({ sx: { display: "flex", alignItems: "center" }, mt: 1, className: "object-tag" }, { children: [_jsx(Typography, Object.assign({ className: "object-tag-text" }, { children: name })), _jsx(X, {})] }))))] })), _jsx(Box, Object.assign({ sx: {
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    gap: 0.5,
                                    flex: "1 1 auto",
                                } }, { children: _jsx(Info, { size: 28, onClick: handleInfoButtonClick }) }))] })) })) }))), isCardFlipped && (_jsx(Grow, Object.assign({ in: isCardFlipped }, { children: _jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between", sx: {
                        width: {
                            xs: "100%",
                            sm: "100%",
                            md: "100%",
                            lg: "49%",
                        },
                        padding: {
                            xs: 0.5,
                            sm: 1,
                            md: 2,
                        },
                        minHeight: "150px"
                    }, className: "contact-card" }, { children: [_jsxs(Box, { children: [_jsx(Typography, Object.assign({ className: "name-heading" }, { children: "Instructions" })), _jsx(Box, Object.assign({ className: "scrollable-content" }, { children: _jsx(Typography, Object.assign({ sx: { padding: "1%" } }, { children: contact.instructions })) }))] }), _jsx(Box, Object.assign({ display: "flex", justifyContent: "flex-end" }, { children: _jsx(ArrowCircleLeft, { size: 32, onClick: handleArrowLeftButtonClick }) }))] })) })))] }));
};
export default RoofAccessContactCard;
