import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from "react";
import { Container, Grid, Typography, useTheme, } from "@mui/material";
import { UserRolesPermissionsContext } from "../../../context/UserRolesPermissionsContext";
const TopHeader = ({ openPopup, translate }) => {
    const theme = useTheme();
    const { RolesPermissions } = useContext(UserRolesPermissionsContext);
    return (_jsx(Container, Object.assign({ disableGutters: true, sx: { maxWidth: "100% !important", padding: "16px 0px 16px 16px" } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 2, alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 6, md: 6 }, { children: _jsx(Typography, Object.assign({ variant: "h1", color: theme.palette.text.primary }, { children: translate("Users") })) })), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 6, sx: { textAlign: "right" } })] })) })));
};
export default TopHeader;
