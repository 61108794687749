import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, IconButton, Paper, Typography, useTheme, } from "@mui/material";
import { DataGrid, } from "@mui/x-data-grid";
import { Layout } from "../../components/Layout/Layout";
import { LeadgenerationTopHeader } from "./LeadgenerationTopHeader";
import { useContext, useEffect, useState } from "react";
import { statusBgColor, statusMapping, statusTextColor, } from "../../../application/common/utils/column-helper";
import LeadsApi from "../../../infrastructure/api/pages/lead-api";
import { Phone } from "@mui/icons-material";
import { EnvelopeSimple } from "@phosphor-icons/react/dist/ssr";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthenticationContext } from "../../../context/AuthenticationContext";
import AssignMultipleLeadsModal from "./AssignMultipleLeadsModal";
export const Leadgeneration = () => {
    const theme = useTheme();
    const { t: translate } = useTranslation();
    const [leads, setLeads] = useState([]);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState();
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [refreshModalData, setRefreshModalData] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 15,
    });
    const [totalLeads, setTotalLeads] = useState(0);
    const navigate = useNavigate();
    const { getProfile } = useContext(AuthenticationContext);
    const [currentUser, setCurrentUser] = useState();
    const [isSelectiveRoles, SetIsSelectiveRoles] = useState(true);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [paginnationModelProp, setPaginnationModelProp] = useState(null);
    useEffect(() => {
        setCurrentUser(getProfile());
    }, []);
    useEffect(() => {
        var _a;
        var userId = undefined;
        if (selectedPerson) {
            if ((selectedPerson === null || selectedPerson === void 0 ? void 0 : selectedPerson.id) === "all") {
                userId = "";
            }
            else {
                userId = selectedPerson === null || selectedPerson === void 0 ? void 0 : selectedPerson.id;
            }
        }
        else {
            userId = (_a = getProfile()) === null || _a === void 0 ? void 0 : _a.userId;
        }
        setLoading(true);
        debugger;
        let pageChanged = (paginnationModelProp === null || paginnationModelProp === void 0 ? void 0 : paginnationModelProp.page) !== paginationModel.page;
        LeadsApi.getAllLeadsByUser(paginationModel.page, paginationModel.pageSize, search, status, userId, isSelectiveRoles, pageChanged)
            .then((response) => {
            if (response.status === 200 && response.data != null) {
                setLeads(response.data);
                let data = response.data;
                if (data.length > 0) {
                    setTotalLeads(data[0].totalRows);
                }
            }
        })
            .finally(() => {
            setTimeout(() => setLoading(false), 400);
        });
        setPaginnationModelProp(paginationModel);
    }, [paginationModel, search, status, refreshModalData, selectedPerson]);
    ////New thing to add
    const columnsForDataGrid = [
        {
            field: "leadNumber",
            headerName: "Nummer",
            sortable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: false,
            minWidth: 50,
        },
        {
            field: "contactName",
            headerName: translate("Company"),
            sortable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: false,
            minWidth: 250,
        },
        {
            field: "email",
            headerName: translate("Email"),
            sortable: true,
            flex: 1,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: false,
            minWidth: 300,
        },
        {
            field: "phoneNumber",
            headerName: translate("PhoneNumber"),
            sortable: true,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            flex: 1,
            minWidth: 200,
        },
        {
            field: "totalObjects",
            headerName: translate("TotalObjects"),
            sortable: true,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: true,
            flex: 1,
            minWidth: 100,
        },
        {
            field: "status",
            headerName: translate("Status"),
            sortable: true,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: true,
            hideSortIcons: true,
            flex: 1,
            minWidth: 300,
            renderCell: (params) => (_jsx(Grid, Object.assign({ container: true, alignContent: "center", justifyContent: "center" }, { children: _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Box, Object.assign({ bgcolor: statusBgColor[params.row.status], padding: 1, mt: 1, borderRadius: 2 }, { children: _jsx(Typography, Object.assign({ variant: "h5", color: statusTextColor[params.row.status] }, { children: translate(`${statusMapping[params.row.status]}`) })) })) })) }))),
        },
        {
            field: "responsiblePersonName",
            headerName: translate("ResponsiblePerson"),
            sortable: true,
            align: "left",
            headerAlign: "left",
            hideable: false,
            filterable: true,
            hideSortIcons: false,
            flex: 1,
            minWidth: 200,
        },
        {
            field: "activites",
            headerName: translate("Activites"),
            sortable: false,
            align: "center",
            headerAlign: "center",
            hideable: false,
            filterable: true,
            hideSortIcons: true,
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (_jsxs(Grid, Object.assign({ container: true, alignContent: "center", justifyContent: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 3 }, { children: _jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                bgcolor: "#F4FCFF",
                                borderRadius: "50%",
                                width: "36px",
                                height: "36px",
                            } }, { children: _jsx(Phone, { onClick: () => handlePhoneNumberClick(params.row.phoneNumber), style: { cursor: "pointer" } }) })) })), _jsx(Grid, Object.assign({ item: true, xs: 3 }, { children: _jsx(Box, Object.assign({ sx: {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                bgcolor: "#F4FCFF",
                                borderRadius: "50%",
                                width: "36px",
                                height: "36px",
                            } }, { children: _jsx(IconButton, Object.assign({ href: `mailto:${params.row.email}`, target: "_blank", rel: "noreferrer" }, { children: _jsx(EnvelopeSimple, { size: 24 }) })) })) }))] }))),
        },
    ];
    const handleCellClick = (params, event) => {
        // Check if the click is from the second column (index 1)
        if (params.field === "activites") {
            // Prevent further propagation
            event.stopPropagation();
        }
        else {
            const lead = params.row;
            if (lead) {
                const id = lead.id;
                const companyId = lead.companyId;
                const orderNumber = lead.leadNumber;
                const newUrl = `/lead-detail?${new URLSearchParams({
                    id,
                    companyId,
                    orderNumber,
                }).toString()}`;
                navigate(newUrl);
            }
        }
    };
    const handlePhoneNumberClick = (phoneNumber) => {
        window.open(`tel:${phoneNumber}`);
    };
    const handleOpenModal = () => {
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };
    return (_jsxs(Layout, { children: [_jsx(LeadgenerationTopHeader, { search: search, status: status, setSearch: setSearch, setStatus: setStatus, handleOpenModal: handleOpenModal, setRefresh: setRefreshModalData, isSelectiveRoles: isSelectiveRoles, setIsSelectiveRoles: SetIsSelectiveRoles, setPaginationModel: setPaginationModel, setSelectedPerson: setSelectedPerson }), _jsx(Paper, Object.assign({ sx: {
                    marginTop: "1%",
                    padding: "16px 32px",
                    borderRadius: "24px",
                } }, { children: _jsx(Paper, Object.assign({ sx: {
                        width: "100%",
                        overflow: "hidden",
                        boxShadow: "none",
                    } }, { children: _jsx(DataGrid, { loading: loading, autoHeight: true, rows: leads !== null && leads !== void 0 ? leads : [], onCellClick: handleCellClick, columns: columnsForDataGrid, 
                        // initialState={{
                        //   pagination: {
                        //     paginationModel: {
                        //       pageSize: 15,
                        //     },
                        //   },
                        // }}
                        // pageSizeOptions={[15]}
                        rowCount: totalLeads, paginationModel: paginationModel, onPaginationModelChange: setPaginationModel, paginationMode: "server", disableRowSelectionOnClick: true, hideFooterPagination: !(leads === null || leads === void 0 ? void 0 : leads.length), rowHeight: 80, sx: {
                            border: "none",
                            fontSize: "14px",
                            "& .has-contract-row": {
                                backgroundColor: "#ffff",
                            },
                            "& .MuiDataGrid-columnHeaderTitle ": {
                                fontSize: "20px",
                                fontWeight: 400,
                            },
                            "& .MuiDataGrid-cell": {
                                display: "flex",
                                alignItems: "center",
                            },
                        }, sortingOrder: ["asc", "desc"] }, "leads-grid") })) })), _jsx(AssignMultipleLeadsModal, { open: modalOpen, onClose: handleCloseModal, refresh: refreshModalData, setRefreshModalData: setRefreshModalData })] }));
};
