var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import AuthApi from "../../../infrastructure/api/auth/auth-api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import SpinnerButton from "../../components/Spinner-Button/SpinnerButton";
import { useTranslation } from "react-i18next";
const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const { t: translate } = useTranslation();
    const { register, handleSubmit, formState: { errors }, reset, } = useForm();
    const onSubmit = (forgotPasswordDtoCredentials) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            var result = yield AuthApi.forgotPassword(forgotPasswordDtoCredentials);
            if (result.data) {
                toast.success(translate("Reset password link sent successfully."));
                reset();
                setLoading(false);
            }
        }
        catch (error) {
            console.error("Error while forgot password.", error);
            setLoading(false);
        }
    });
    return (_jsx("div", Object.assign({ className: "login-container" }, { children: _jsx("div", Object.assign({ className: "container h-100" }, { children: _jsxs("div", Object.assign({ className: "row g-lg-5 g-4 flex-lg-row flex-column align-items-center justify-content-lg-between h-md-100 h-auto" }, { children: [_jsx("div", Object.assign({ className: "col-lg-4" }, { children: _jsx("div", Object.assign({ className: "d-block w-100 text-center" }, { children: _jsx("img", { src: "", className: "logo", width: "100%", alt: "logo" }) })) })), _jsx("div", Object.assign({ className: "col-xl-5 col-lg-6" }, { children: _jsx("div", Object.assign({ className: "box mt-12" }, { children: _jsxs("div", Object.assign({ className: "inner-container" }, { children: [_jsx("h1", Object.assign({ className: "title" }, { children: translate("ForgotPassword") })), _jsx("form", Object.assign({ className: "w-100", onSubmit: handleSubmit(onSubmit) }, { children: _jsxs("div", Object.assign({ className: "row gy-5" }, { children: [_jsxs("div", Object.assign({ className: "col-12" }, { children: [_jsx("label", Object.assign({ className: "form-label" }, { children: translate("Email") })), _jsx("input", Object.assign({ type: "email" }, register("email", {
                                                            required: {
                                                                value: true,
                                                                message: "Email is required",
                                                            },
                                                            pattern: {
                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                message: "Invalid email address",
                                                            },
                                                        }), { className: `form-control ${(errors === null || errors === void 0 ? void 0 : errors.email) ? `is-invalid` : ``}`, id: "email", placeholder: "xyz@leadgeneration.com" }))] })), _jsx("div", Object.assign({ className: "col-12" }, { children: !loading ? (_jsx("button", Object.assign({ className: "btn btn-primary d-block w-100", type: "submit" }, { children: translate("Submit") }))) : (_jsx(SpinnerButton, {})) }))] })) }))] })) })) }))] })) })) })));
};
export default ForgotPassword;
