export const uniqueList = (arr, key) => [
    ...new Map(arr.filter(Boolean).map((item) => [item[key], item])).values(),
];
export const convertUTCDateToLocalDate = (date) => {
    var newDate = new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60 * 1000);
    return newDate;
};
export const convertUTCDateToLocalDateString = (date, locale = "nl-NL") => {
    var newDate = new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60 * 1000);
    return new Date(newDate).toLocaleDateString(locale);
};
export const convertUTCDateToLocalTimeString = (date, locale = "nl-NL") => {
    var newDate = new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60 * 1000);
    return new Date(newDate).toLocaleTimeString(locale);
};
export const convertUTCDateToLocalDateTimeString = (date, locale) => {
    var newDate = new Date(new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60 * 1000);
    return (new Date(newDate).toLocaleDateString(locale) +
        " " +
        new Date(newDate).toLocaleTimeString(locale));
};
export const convertToNLEuroFormat = (amount) => {
    return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
    }).format(amount);
};
