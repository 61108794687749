var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeI18N } from "./i18n";
import i18n from "i18next";
import { createRoot } from "react-dom/client";
import { AuthenticationContextProvider } from "./context/AuthenticationContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./ui/pages/auth/msalConfig";
import { BrowserRouter } from "react-router-dom";
const contaier = document.getElementById("root");
const root = createRoot(contaier);
const customColors = {
    winter: {
        main: "#130922",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#fff",
    },
    inputBg: {
        main: "#F7F7F7",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#000",
    },
    customDark: {
        main: "#130922",
        light: "#423A4E",
        dark: "#A29415",
        contrastText: "#fff",
    },
    white: {
        main: "#fff",
        light: "#fff",
        dark: "#fff",
        contrastText: "#000",
    },
};
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 2000,
            xxxl: 3000,
        },
    },
    palette: {
        text: {
            disabled: "#130922",
            primary: "#312F2F",
            secondary: "#6C757D",
        },
        // mode: 'dark',
        primary: {
            main: "#130922",
            light: "#42a5f5",
            dark: "#1565c0",
            contrastText: "#fff",
        },
        winter: {
            main: customColors.winter.main,
            light: customColors.winter.light,
            dark: customColors.winter.dark,
            contrastText: customColors.winter.contrastText,
        },
        inputBg: {
            main: customColors.inputBg.main,
            light: customColors.inputBg.light,
            dark: customColors.inputBg.dark,
            contrastText: customColors.inputBg.contrastText,
        },
        customDark: {
            main: customColors.customDark.main,
            light: customColors.customDark.light,
            dark: customColors.customDark.dark,
            contrastText: customColors.customDark.contrastText,
        },
        white: {
            main: customColors.white.main,
            light: customColors.white.light,
            dark: customColors.white.dark,
            contrastText: customColors.white.contrastText,
        },
        secondary: {
            main: "#ffba00",
        },
    },
    shape: {
        borderRadius: 8,
    },
    typography: {
        fontFamily: '"Rubik", sans-serif',
        button: {
            fontSize: "1rem",
            fontWeight: "normal",
        },
        h1: {
            fontSize: "34px",
            fontStyle: "normal",
            fontWeight: "500",
        },
        h2: {
            color: "#312F2F",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "133.4%",
            letterSpacing: "1px",
        },
        h3: {
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "430",
            lineHeight: "133.4%",
            letterSpacing: "1px",
        },
        h5: {
            fontSize: "14px",
            fontStyle: "medium",
            fontWeight: "bold",
            wordSpacing: "1px",
        },
        h4: {
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "143%",
            letterSpacing: "1px",
        },
        h6: {
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "160%" /* 32px */,
            letterSpacing: "1px",
        },
        body1: {
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            letterSpacing: "1px",
            lineHeight: "150%",
        },
        body2: {
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            letterSpacing: "1px",
            lineHeight: "143%",
        },
    },
});
// Override button hover color
theme.components = {
    MuiToggleButton: {
        styleOverrides: {
            root: {
                "&.Mui-selected": {
                    color: "#130922", // Change the text color for the active state of the ToggleButton
                },
            },
        },
    },
    MuiDivider: {
        styleOverrides: {
            root: {
                borderColor: "#A9D1D9",
                height: "2px",
                alignSelf: "stretch",
                borderRadius: "100px",
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                borderRadius: "12px",
                [theme.breakpoints.down(1024)]: {
                    fontSize: "14px",
                    borderRadius: "9px",
                },
                [theme.breakpoints.down(900)]: {
                    fontSize: "12px",
                    borderRadius: "9px",
                },
            },
        },
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: {
                fontSize: "20px",
                borderBottom: "0.5px solid #CED4DA",
                fontWeight: 400,
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                borderTop: "0.5px solid #CED4DA",
                padding: "var(--3, 24px)",
            },
        },
    },
    MuiCheckbox: {
        styleOverrides: {
            root: {
                "&.Mui-checked": {
                    color: "#130922", // Change the color for the active state of the checkbox
                },
            },
        },
    },
    MuiSelect: {
        styleOverrides: {
            root: {
                "&.Mui-checked": {
                    color: "#130922", // Change the color for the active state of the checkbox
                },
            },
        },
    },
    MuiStepper: {
        styleOverrides: {
            root: {
                backgroundColor: "transparent", // Set the background color for the entire Stepper
            },
        },
    },
    MuiStepIcon: {
        styleOverrides: {
            root: {
            // color: '#FF5733', // Change the default color for step icons
            },
            active: {
                color: "#130922", // Change the color for the active step icon
            },
            completed: {
                color: "#130922", // Change the color for the completed step icon
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: "none",
                borderRadius: "12px",
                "&:hover": {
                    backgroundColor: "#423A4E",
                },
                [theme.breakpoints.down("md")]: {
                    padding: "10px 10px",
                    fontSize: "14px",
                    borderRadius: "9px",
                },
            },
            contained: {
                "&:hover": {
                    backgroundColor: "#423A4E", // Change the hover color for the contained variant
                },
            },
            outlined: {
                "&:hover": {
                    backgroundColor: "#F9F6FF", // Change the hover text color for the outlined variant
                },
            },
            sizeSmall: {
                padding: "8px 12px",
            },
            sizeMedium: {
                padding: "12px 32px",
            },
            sizeLarge: {
                padding: "14px 48px",
            },
            containedSizeSmall: {
                padding: "8px 12px",
            },
            containedSizeMedium: {
                padding: "12px 32px",
            },
            containedSizeLarge: {
                padding: "14px 48px",
            },
        },
    },
};
const msalInstance = new PublicClientApplication(msalConfig);
(function () {
    return __awaiter(this, void 0, void 0, function* () {
        i18n.on("initialized", () => {
            root.render(_jsx(AuthenticationContextProvider, { children: _jsx(ThemeProvider, Object.assign({ theme: theme }, { children: _jsxs(MsalProvider, Object.assign({ instance: msalInstance }, { children: [_jsx(CssBaseline, {}), _jsx(BrowserRouter, { children: _jsx(App, {}) })] })) })) }));
        });
        initializeI18N();
    });
})();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
