import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./Layout.scss";
import { useEffect } from "react";
import { TopBar } from "../TopBar/TopBar";
import { useState } from "react";
import SideNavBar from "../SideNavBar/SideNavBar";
import { useMediaQuery, useTheme } from "@mui/material";
export const Layout = ({ children }) => {
    const theme = useTheme();
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const [isSideBarHidden, setIsSideBarHidden] = useState(false);
    const isSM = useMediaQuery(theme.breakpoints.down("sm"));
    const isMD = useMediaQuery(theme.breakpoints.down("md"));
    const isMDUp = useMediaQuery(theme.breakpoints.up("md"));
    const isLG = useMediaQuery(theme.breakpoints.up("lg"));
    useEffect(() => {
        if (isSM) {
            setIsSideBarOpen(false);
            setIsSideBarHidden(true);
        }
        else if (isMD) {
            setIsSideBarOpen(true);
            setIsSideBarHidden(false);
        }
        else if (isLG) {
            setIsSideBarOpen(false);
            setIsSideBarHidden(false);
        }
    }, [isSM, isMD, isMDUp, isLG]);
    return (_jsxs(_Fragment, { children: [_jsx(SideNavBar, { isOpen: isSideBarOpen, isHidden: isSideBarHidden }), _jsxs("main", Object.assign({ className: `layout-main ${isSideBarOpen ? "collapsed" : isSideBarHidden ? "hidden" : ""}` }, { children: [_jsx(TopBar, { setIsSideBarOpen: setIsSideBarOpen, isSideBarOpen: isSideBarOpen, isSideBarHidden: isSideBarHidden }), children] }))] }));
};
