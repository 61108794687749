import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Typography } from "@mui/material";
import { ClientLayout } from "../../components/Layout/ClientLayout/ClientLayout";
import "./CheckingAndPaying.scss";
import { CaretLeft } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BillingInformation from "../../components/Check&Pay/BillingInformation";
import { useEffect, useState } from "react";
import PayAndCheckInfo from "../../../infrastructure/api/pages/pay-and-check-api";
const CheckingAndPaying = () => {
    const { t: translate } = useTranslation();
    const urlParams = new URLSearchParams(window.location.search);
    const contractId = urlParams.get("contractId");
    const [payAndCheckInfo, setPayAndCheckInfo] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (contractId) {
            setLoading(true);
            PayAndCheckInfo.GetPayAndCheckByContractId(contractId)
                .then((res) => {
                if (res.data && res.status === 200) {
                    setPayAndCheckInfo(res.data);
                }
            })
                .finally(() => setLoading(false));
        }
    }, [contractId]);
    return (_jsxs(ClientLayout, Object.assign({ page: "checking-and-paying" }, { children: [_jsxs(Box, Object.assign({ padding: 2, className: "checkandpaying-container" }, { children: [_jsxs(Box, Object.assign({ className: "top-heading" }, { children: [_jsx(Typography, Object.assign({ className: "heading" }, { children: translate("CheckandSend") })), _jsx(Typography, Object.assign({ className: "sub-heading" }, { children: translate("CheckandPayDetail") }))] })), _jsx(Box, Object.assign({ display: "flex", gap: 4, alignItems: "center", justifyContent: "center", pt: 1, pb: 1, pr: { xs: 1, sm: 3, md: 5, lg: 20 }, pl: { xs: 1, sm: 3, md: 5, lg: 20 } }, { children: _jsx(BillingInformation, { payAndCheckInfo: payAndCheckInfo, loading: loading }) }))] })), _jsxs(Box, Object.assign({ className: "checking-pay-footer" }, { children: [_jsx(Link, Object.assign({ to: `/billing-address?contractId=${contractId}` }, { children: _jsxs(Button, Object.assign({ variant: "outlined", sx: { borderColor: "white !important" } }, { children: [_jsx(CaretLeft, { size: 24 }), _jsx(Typography, Object.assign({ fontWeight: 700 }, { children: "Terug" }))] })) })), _jsx(Link, Object.assign({ to: `/completion?contractId=${contractId}` }, { children: _jsx(Button, Object.assign({ variant: "contained", color: "primary" }, { children: _jsx(Typography, { children: translate("Complete") }) })) }))] }))] })));
};
export default CheckingAndPaying;
