import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Checks } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import roofAccessGif from "../../../images/Eurosafe_team_trekproef.jpg";
const RoofAccessSideBar = () => {
    const { t: translate } = useTranslation();
    return (_jsxs(Grid, Object.assign({ item: true, spacing: 3, className: "sidepanel-roofaccess" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, overflow: "hidden", alignItems: "center", justifyContent: "center", sx: { padding: 0 } }, { children: _jsx(Box, { component: "img", className: "sidepanel-roofaccess-gif", sx: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "auto",
                        width: "100%",
                        borderRadius: 3,
                        overflow: "hidden", // Hide overflow if necessary
                    }, src: roofAccessGif, alt: "png" }) })), _jsx(Grid, Object.assign({ container: true, item: true }, { children: _jsxs(Grid, Object.assign({ item: true, className: "textcontainer", xs: 12, padding: 4 }, { children: [_jsx(Typography, Object.assign({ className: "sidepanelheading" }, { children: translate("ImportanceOfContactPerson") })), _jsxs(List, Object.assign({ sx: { paddingLeft: 0 } }, { children: [_jsxs(ListItem, Object.assign({ sx: { paddingLeft: 0 } }, { children: [_jsx(ListItemIcon, Object.assign({ sx: { minWidth: "30px" } }, { children: _jsx(Checks, { size: 24, color: "#2AA241" }) })), _jsx(ListItemText, Object.assign({ className: "sidepanellisttext" }, { children: translate("EffectiveCommunication") }))] })), _jsxs(ListItem, Object.assign({ sx: { paddingLeft: 0 } }, { children: [_jsx(ListItemIcon, Object.assign({ sx: { minWidth: "30px" } }, { children: _jsx(Checks, { size: 24, color: "#2AA241" }) })), _jsx(ListItemText, Object.assign({ className: "sidepanellisttext" }, { children: translate("AccurateInformation") }))] })), _jsxs(ListItem, Object.assign({ sx: { paddingLeft: 0 } }, { children: [_jsx(ListItemIcon, Object.assign({ sx: { minWidth: "30px" } }, { children: _jsx(Checks, { size: 24, color: "#2AA241" }) })), _jsx(ListItemText, Object.assign({ className: "sidepanellisttext" }, { children: translate("PersonalApproach") }))] }))] }))] })) }))] })));
};
export default RoofAccessSideBar;
