var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useRef } from "react";
import { TextField, Grid, } from "@mui/material";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomModal from "../modal/CustomModal";
import CompanyRolesApi from "../../../infrastructure/api/pages/company-roles-api";
import { useTranslation } from "react-i18next";
const validationSchema = (translate) => yup.object({
    name: yup.string().required(translate("NameRequired")),
    number: yup
        .number()
        .transform((value, originalValue) => typeof originalValue === 'string' && originalValue.trim() === '' ? null : value)
        .nullable()
        .required(translate("NumberRequired"))
        .moreThan(0, translate("NumberGreaterThanZero")),
});
const CompanyRolesFormSubmit = ({ open, onClose, GetRoles, initialFormData, data, title, }) => {
    const [saving, setSaving] = useState(false);
    const { t: translate } = useTranslation();
    const hiddenButtonRef = useRef(null);
    const { reset, handleSubmit, control, register, formState: { errors }, } = useForm({
        resolver: yupResolver(validationSchema(translate)),
    });
    const onCloseModal = () => {
        reset(initialFormData);
        onClose();
    };
    const onSubmit = (formData) => __awaiter(void 0, void 0, void 0, function* () {
        setSaving(true);
        try {
            const response = formData.id ? yield CompanyRolesApi.updateRole(formData) : yield CompanyRolesApi.createRole(Object.assign(Object.assign({}, formData), { id: "" }));
            if (response.status === 200 && response.data) {
                toast.success(translate(formData.id ? "UpdatedSuccessfully" : "SavedSuccessfully"));
                onCloseModal();
                GetRoles();
                reset(initialFormData);
            }
        }
        catch (error) {
            toast.error(translate("Internal Server Error"));
            onCloseModal();
            reset(initialFormData);
        }
        finally {
            setSaving(false);
        }
    });
    useEffect(() => {
        reset(data);
    }, [data, reset]);
    return (_jsx(CustomModal, Object.assign({ open: open, onClose: onCloseModal, size: "sm", saving: saving, handleSaveButtonClick: () => { var _a; return (_a = hiddenButtonRef.current) === null || _a === void 0 ? void 0 : _a.click(); }, heading: title, saveButtonText: translate("Save"), disabled: false }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 0 }, { children: _jsx(Grid, Object.assign({ item: true, lg: 8, xs: 12, mx: "auto" }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("input", Object.assign({ type: "hidden" }, register("id"))), _jsx(Controller, { name: "number", control: control, defaultValue: data.number, render: ({ field }) => {
                                var _a;
                                return (_jsx(TextField, Object.assign({ label: translate("Number"), variant: "outlined", fullWidth: true, margin: "normal", type: "number", inputProps: { step: "any" } }, field, { error: !!errors.number, helperText: (_a = errors.number) === null || _a === void 0 ? void 0 : _a.message })));
                            } }), _jsx(Controller, { name: "name", control: control, defaultValue: data.name, render: ({ field }) => {
                                var _a;
                                return (_jsx(TextField, Object.assign({ label: translate("Name"), variant: "outlined", fullWidth: true, margin: "normal" }, field, { error: !!errors.name, helperText: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message })));
                            } }), _jsx("button", { ref: hiddenButtonRef, type: "submit", hidden: true })] })) })) })) })));
};
export default CompanyRolesFormSubmit;
