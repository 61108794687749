import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Box, Button, CircularProgress, FormControlLabel, Grid, Typography, Checkbox, } from "@mui/material";
import { ClientLayout } from "../../components/Layout/ClientLayout/ClientLayout";
import "./Completion.scss";
import { CaretLeft } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ContractApi from "../../../infrastructure/api/pages/contract-api";
import { CardContainer } from "../../components/Carousel/Slider";
const defaultValues = {
    leadId: "",
    isDeleted: false,
    objects: [],
    name: "",
    functie: "",
    remark: "",
    payLater: false,
    contactMe: true,
    informationSubmitted: true,
    isContinous: false,
    isCopied: false,
    consultancyNewsLetter: true,
    installationNewLetter: true,
    productsNewLetter: true,
    trainingNewLetter: true,
    inpectionNewLetter: true,
    inspectionPDF: "",
};
const Completion = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const contractId = urlParams.get("contractId");
    const [contract, setContract] = useState(defaultValues);
    const [loading, setLoading] = useState(false);
    const { t: translate } = useTranslation();
    const [refresh, setRefresh] = useState(false);
    const handleNewLetterChange = (event, checkboxType) => {
        const { checked } = event.target;
        setContract((prevState) => {
            if (prevState) {
                return Object.assign(Object.assign({}, prevState), { [checkboxType]: checked });
            }
            return prevState;
        });
    };
    const handleSubmit = () => {
        if (contract) {
            setLoading(true);
            var request = contract;
            request.informationSubmitted = true;
            ContractApi.update(request)
                .then((res) => {
                setRefresh((prev) => !prev);
                toast.success(translate("UpdatedSuccessfully"));
                window.close();
            })
                .finally(() => setLoading(false));
        }
    };
    useEffect(() => {
        if (contractId) {
            setLoading(true);
            ContractApi.getById(contractId).then((response) => {
                setContract(response.data);
                setLoading(false);
            });
        }
    }, [contractId, refresh]);
    return (_jsx(ClientLayout, Object.assign({ page: "completion" }, { children: _jsxs(Box, { children: [_jsx(Box, Object.assign({ className: "completion-container" }, { children: _jsx(CardContainer, { onChange: handleNewLetterChange, contract: contract }) })), _jsx(Box, Object.assign({ className: "footer" }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, md: 6, sm: 12, xs: 12 }, { children: _jsx(Box, Object.assign({ className: "checkbox-container" }, { children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: contract ? contract.contactMe : false, onChange: (event) => handleNewLetterChange(event, "contactMe") }), label: _jsx(Typography, Object.assign({ className: "checkbox-text" }, { children: translate("ContactMeForMore") })) }) })) })), _jsx(Grid, Object.assign({ item: true, md: 6, sm: 12, xs: 12 }, { children: _jsxs(Box, Object.assign({ display: "flex", flexDirection: "row", justifyContent: {
                                        xs: "space-between",
                                        sm: "space-between",
                                        md: "flex-end",
                                    } }, { children: [_jsx(Box, { children: _jsx(Link, Object.assign({ to: `/checking-and-paying?contractId=${contractId}` }, { children: _jsxs(Button, Object.assign({ variant: "outlined", sx: { borderColor: "white !important" } }, { children: [_jsx(CaretLeft, { size: 24 }), _jsx(Typography, Object.assign({ fontWeight: 700 }, { children: "Terug" }))] })) })) }), _jsx(Box, { children: _jsxs(Button, Object.assign({ variant: "contained", color: "primary", disabled: loading, onClick: handleSubmit, autoFocus: true }, { children: [loading ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : (_jsx(_Fragment, {})), _jsx(Typography, { children: "Sluiten" })] })) })] })) }))] })) }))] }) })));
};
export default Completion;
