export var Modules;
(function (Modules) {
    Modules["Dashboard"] = "Dashboard";
    Modules["Leads"] = "Leads";
    Modules["WorkPreparation"] = "WorkPreparation";
    Modules["Users"] = "Users";
    //RolePermissions = "RolePermissions",
    Modules["RoleManagement"] = "RoleManagement";
})(Modules || (Modules = {}));
export var ModuleActions;
(function (ModuleActions) {
    ModuleActions["Create"] = "Create";
    ModuleActions["Read"] = "Read";
    ModuleActions["Manage"] = "Manage";
    ModuleActions["Delete"] = "Delete";
})(ModuleActions || (ModuleActions = {}));
