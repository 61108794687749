import "react-toastify/dist/ReactToastify.css";
import { http_api } from "../../leadg-api";
const getAllRole = () => {
    return http_api.get(`company-role/get`);
};
const createRole = (input) => {
    return http_api.post("company-role/add", input);
};
const updateRole = (input) => {
    return http_api.put("company-role/update", input);
};
const deleteRoleById = (id) => {
    return http_api.delete(`company-role/delete/${id}`);
};
const CompanyRolesApi = {
    createRole,
    getAllRole,
    updateRole,
    deleteRoleById
};
export default CompanyRolesApi;
