import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Skeleton, Typography, } from "@mui/material";
import "./Contract.scss";
import { ClientTopBar } from "../../components/Layout/ClientLayout/ClientTopBar";
import ContractSidePanel from "../../components/contract/ContractSidePanel";
import { useTranslation } from "react-i18next";
import ContractObjectAccordion from "../../components/contract/ContractObjectAccordion";
import ContractObjectPriceAccordion from "../../components/contract/ContractObjectPriceAccordion";
import { useEffect, useState } from "react";
import ContractedObjectApi from "../../../infrastructure/api/pages/contracted-object";
import ContractAcceptModal from "../../components/contract/modal/ContratAcceptModal";
import { PencilSimple } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import ContractRejectModal from "../../components/contract/modal/ContractRejectModal";
import PDFViewer from "./PDFViewer";
import OfferPDFViewer from "./OfferPDFViewer";
const ContractPage = () => {
    const { t: translate } = useTranslation();
    const urlParams = new URLSearchParams(window.location.search);
    const contractId = urlParams.get("contractId");
    const [contractedObjects, setContractedObjects] = useState();
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [acceptModalOpen, setAcceptModalOpen] = useState(false);
    const [rejectModalOpen, setRejectModalOpen] = useState(false);
    const [checked, setIsChecked] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const closeAcceptModal = () => {
        setAcceptModalOpen(!acceptModalOpen);
    };
    const closeRejecttModal = () => {
        setRejectModalOpen(!rejectModalOpen);
    };
    const openAcceptModal = () => {
        if (checked)
            setAcceptModalOpen(true);
        else
            toast.info(translate("Kindly accept the terms and conditions first"));
    };
    const openRejectModal = () => {
        setRejectModalOpen(true);
    };
    useEffect(() => {
        setLoading(true);
        if (contractId) {
            ContractedObjectApi.GetContractByContractId(contractId).then((response) => {
                if (response.status === 200 && response.data) {
                    setContractedObjects(response.data);
                    const totalPrice = response.data.contractedObject.reduce((sum, obj) => sum + obj.price, 0);
                    setAmount(totalPrice);
                    setLoading(false);
                    setIsChecked(response.data.contract.isSigned ||
                        response.data.contract.isDeleted);
                }
            });
        }
    }, [contractId, refresh]);
    const [open, setOpen] = useState(false);
    const [isOfferPDF, setIsOfferPDF] = useState(false);
    const handleOpen = () => {
        setOpen(true);
        setIsOfferPDF(false);
    };
    const handleOfferOpen = () => {
        setOpen(true);
        setIsOfferPDF(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (_jsxs(Box, Object.assign({ className: "contractcontainer", sx: { p: { sm: "12px 32px", md: "24px 64px" } } }, { children: [_jsxs(Grid, Object.assign({ container: true, className: "gridconatiner" }, { children: [_jsx(Grid, Object.assign({ className: "topbar", item: true }, { children: _jsx(ClientTopBar, {}) })), _jsx(Box, Object.assign({ className: "body" }, { children: _jsxs(Grid, Object.assign({ className: "bodycontainer", gap: 1, container: true, item: true, alignItems: "stretch" }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 8, lg: 8, sx: {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        pt: { sm: 1, md: 2 },
                                        pb: { sm: 1, md: 2 },
                                        pl: { sm: 2, md: 4 },
                                        pr: { sm: 2, md: 4 },
                                    }, gap: { sm: 2, md: 5 } }, { children: [_jsxs(Box, Object.assign({ p: { xs: 1, sm: 0 } }, { children: [_jsx(Typography, Object.assign({ className: "topheading" }, { children: translate("Your offer is ready!") })), _jsx(Typography, Object.assign({ color: "#6C757D", variant: "body2" }, { children: translate("We just need your signature...") })), !loading &&
                                                    (contractedObjects === null || contractedObjects === void 0 ? void 0 : contractedObjects.contractedObject.length) != undefined &&
                                                    (contractedObjects === null || contractedObjects === void 0 ? void 0 : contractedObjects.contractedObject.length) == 0 && (_jsx(Typography, Object.assign({ fontSize: 22, marginTop: 5 }, { children: translate("Bedankt voor je reactie. Wij gaan ermee aan de slag en nemen contact op indien nodig") })))] })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(Grid, Object.assign({ container: true, item: true, justifyContent: "center", alignItems: "center", gap: 2, padding: 4 }, { children: loading ? (_jsx(Skeleton, { sx: {
                                                        borderRadius: 3,
                                                        height: { xs: 300, sm: 400 },
                                                        width: { xs: "90%", sm: "100%" },
                                                    }, variant: "rectangular" })) : (contractedObjects === null || contractedObjects === void 0 ? void 0 : contractedObjects.contractedObject.map((contractedObject) => (_jsx(ContractObjectAccordion, { contractedObject: contractedObject })))) })) })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sx: {
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-end",
                                                mt: "auto",
                                            } }, { children: [_jsx(ContractObjectPriceAccordion, { amount: amount }), _jsxs(Grid, Object.assign({ item: true, justifyContent: "space-between", padding: {
                                                        xs: "12px 24px",
                                                        sm: "12px 24px",
                                                        md: "24px 32px",
                                                    }, alignItems: "center", borderRadius: 3, bgcolor: "#FFF", gap: 2, boxShadow: "0px 10px 21px 0px rgba(0, 0, 0, 0.10), 0px 38px 38px 0px rgba(0, 0, 0, 0.09), 0px 86px 52px 0px rgba(0, 0, 0, 0.05), 0px 153px 61px 0px rgba(0, 0, 0, 0.01), 0px 239px 67px 0px rgba(0, 0, 0, 0.00)" }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, lg: 12, gap: 0.5, alignItems: "center" }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, justifyContent: "space-between", display: "flex", gap: 2 }, { children: [_jsx(Button, Object.assign({ variant: "outlined", onClick: handleOpen, sx: { cursor: "pointer" } }, { children: "Algemene voorwaarden bekijken" })), _jsx(Button, Object.assign({ variant: "outlined", disabled: loading, onClick: handleOfferOpen, sx: { cursor: "pointer" } }, { children: "Contract bekijken" }))] })), _jsxs(Dialog, Object.assign({ fullWidth: true, open: open, maxWidth: false, onClose: handleClose, sx: {
                                                                        "& .MuiDialog-paper": {
                                                                            padding: "5px",
                                                                            maxWidth: "100%",
                                                                        },
                                                                    } }, { children: [_jsx(DialogContent, Object.assign({ sx: {
                                                                                padding: "0px 1px !important",
                                                                                paddingBottom: "1px !important",
                                                                            } }, { children: isOfferPDF ? (_jsx(OfferPDFViewer, { base64Pdf: contractedObjects === null || contractedObjects === void 0 ? void 0 : contractedObjects.contract.inspectionPDF })) : (_jsx(PDFViewer, {})) })), _jsx(DialogActions, { children: _jsx(Button, Object.assign({ onClick: handleClose, variant: "outlined" }, { children: "Close" })) })] }))] })), _jsx(Grid, Object.assign({ item: true, sx: {
                                                                display: "flex",
                                                                flexDirection: { xs: "column", sm: "column", md: "row" },
                                                            } }, { children: _jsxs(Grid, Object.assign({ item: true, sx: {
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    flexDirection: "row",
                                                                } }, { children: [_jsx(Checkbox, { checked: checked, disabled: (contractedObjects === null || contractedObjects === void 0 ? void 0 : contractedObjects.contract.isSigned) ||
                                                                            (contractedObjects === null || contractedObjects === void 0 ? void 0 : contractedObjects.contract.isDeleted), onChange: (e) => {
                                                                            setIsChecked(!checked);
                                                                        } }), _jsx(Typography, Object.assign({ className: "footertext" }, { children: translate("I accept the terms and conditions of this offer") }))] })) })), _jsxs(Grid, Object.assign({ xs: 12, item: true, sx: {
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "flex-end",
                                                            }, gap: 2 }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsxs(Button, Object.assign({ disabled: (contractedObjects === null || contractedObjects === void 0 ? void 0 : contractedObjects.contract.isSigned) ||
                                                                            (contractedObjects === null || contractedObjects === void 0 ? void 0 : contractedObjects.contract.isDeleted) ||
                                                                            loading, onClick: openRejectModal, sx: {
                                                                            display: "flex",
                                                                            padding: "8px 16px",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            gap: "8px",
                                                                            borderRadius: "8px",
                                                                            border: "1px solid #130922",
                                                                        }, variant: "outlined" }, { children: [loading ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : (_jsx(_Fragment, {})), _jsx(Typography, Object.assign({ sx: {
                                                                                    color: "#130922",
                                                                                    fontFeatureSettings: "'clig' off, 'liga' off",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: 400,
                                                                                    lineHeight: "150%" /* 24px */,
                                                                                    letterSpacing: "1px",
                                                                                } }, { children: translate("Remark") }))] })) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsxs(Button, Object.assign({ disabled: (contractedObjects === null || contractedObjects === void 0 ? void 0 : contractedObjects.contract.isSigned) ||
                                                                            (contractedObjects === null || contractedObjects === void 0 ? void 0 : contractedObjects.contract.isDeleted) ||
                                                                            loading, sx: {
                                                                            display: "flex",
                                                                            padding: "var(--1, 8px) var(--2, 16px)",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            gap: "var(--1, 8px)",
                                                                            borderRadius: "var(--1, 8px)",
                                                                            border: "var(--n-primary, #130922)",
                                                                        }, type: "button", variant: "contained", color: "primary", onClick: openAcceptModal }, { children: [loading ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : (_jsx(_Fragment, {})), _jsx(PencilSimple, { size: 24 }), _jsx(Typography, Object.assign({ sx: {
                                                                                    color: "var(--Neutral-White, #FFF)",
                                                                                    fontFeatureSettings: "'clig' off, 'liga' off",
                                                                                    fontSize: "16px",
                                                                                    fontStyle: "normal",
                                                                                    fontWeight: "400",
                                                                                    lineHeight: "150%" /* 24px */,
                                                                                    letterSpacing: "1px",
                                                                                } }, { children: translate("Sign") }))] })) }))] }))] }))] }))] })), _jsx(ContractSidePanel, {})] })) }))] })), _jsx(ContractAcceptModal, { setRefresh: setRefresh, open: acceptModalOpen, onClose: closeAcceptModal, contract: contractedObjects === null || contractedObjects === void 0 ? void 0 : contractedObjects.contract }), _jsx(ContractRejectModal, { setRefresh: setRefresh, open: rejectModalOpen, onClose: closeRejecttModal, contract: contractedObjects === null || contractedObjects === void 0 ? void 0 : contractedObjects.contract })] })));
};
export default ContractPage;
