import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useForm } from "react-hook-form";
const Signup = () => {
    const initialSIgnupState = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
    };
    const [signupCredentials, SetsignupCredentials] = useState(initialSIgnupState);
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const validatePassword = (password) => {
        if (password.length < 8)
            return "Must contain atleast 8 characters.";
        if (!/[A-Z]+/.test(password))
            return "Must contain atleast one uppercase letter.";
        if (!/[a-z]+/.test(password))
            return "Must contain atleast one lowercase letter.";
        if (!/[0-9]+/.test(password))
            return "Must contain atleast one number.";
        if (!/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password))
            return "Must contain atleast one special character.";
    };
    return (_jsx("div", Object.assign({ className: "vh-100 d-flex justify-content-center align-items-center", style: { background: "#dee0e2" } }, { children: _jsx("div", Object.assign({ className: "container" }, { children: _jsx("div", Object.assign({ className: "row d-flex justify-content-center" }, { children: _jsx("div", Object.assign({ className: "col-12 col-md-8 col-lg-6" }, { children: _jsx("div", Object.assign({ className: "card bg-white" }, { children: _jsx("div", Object.assign({ className: "card-body p-5" }, { children: _jsxs("form", Object.assign({ className: "mb-3 mt-md-4" }, { children: [_jsx("h3", Object.assign({ className: "fw-bold mb-2 text-uppercase " }, { children: "Lead Generation Sign Up" })), _jsx("p", Object.assign({ className: " mb-5" }, { children: "Please enter your Credentials!" })), _jsxs("div", Object.assign({ className: "mb-3" }, { children: [_jsx("label", Object.assign({ className: "form-label float-start " }, { children: "First Name" })), _jsx("input", Object.assign({ type: "text" }, register("firstName", {
                                                required: {
                                                    value: true,
                                                    message: "This field is required.",
                                                },
                                                minLength: {
                                                    value: 2,
                                                    message: "Should have minimum 2 characters.",
                                                },
                                                maxLength: {
                                                    value: 15,
                                                    message: "Maximum 15 characters are allowed.",
                                                },
                                                pattern: {
                                                    value: RegExp("^[a-z A-Z]+$"),
                                                    message: "Invalid first name",
                                                },
                                            }), { className: "form-control", id: "firstName", placeholder: "First Name", onChange: (e) => SetsignupCredentials(Object.assign(Object.assign({}, signupCredentials), { firstName: e.target.value })), value: signupCredentials.firstName }))] })), _jsxs("div", Object.assign({ className: "mb-3" }, { children: [_jsx("label", Object.assign({ className: "form-label float-start " }, { children: "Last Name" })), _jsx("input", Object.assign({ type: "text" }, register("lastName", {
                                                required: {
                                                    value: true,
                                                    message: "This field is required.",
                                                },
                                                minLength: {
                                                    value: 2,
                                                    message: "Should have minimum 2 characters.",
                                                },
                                                maxLength: {
                                                    value: 15,
                                                    message: "Maximum 15 characters are allowed.",
                                                },
                                                pattern: {
                                                    value: RegExp("^[a-z A-Z]+$"),
                                                    message: "Invalid last name",
                                                },
                                            }), { className: "form-control", id: "lastName", placeholder: "Last Name", onChange: (e) => SetsignupCredentials(Object.assign(Object.assign({}, signupCredentials), { lastName: e.target.value })), value: signupCredentials.lastName }))] })), _jsxs("div", Object.assign({ className: "mb-3" }, { children: [_jsx("label", Object.assign({ className: "form-label float-start " }, { children: "Email address" })), _jsx("input", Object.assign({ type: "text" }, register("email", {
                                                required: {
                                                    value: true,
                                                    message: "This field is required.",
                                                },
                                                maxLength: {
                                                    value: 100,
                                                    message: "Maximum 100 characters are allowed..",
                                                },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Email address is not valid.",
                                                },
                                            }), { className: "form-control", id: "email", placeholder: "name@example.com", onChange: (e) => SetsignupCredentials(Object.assign(Object.assign({}, signupCredentials), { email: e.target.value })), value: signupCredentials.email }))] })), _jsxs("div", Object.assign({ className: "mb-3" }, { children: [_jsx("label", Object.assign({ className: "form-label float-start" }, { children: "Password" })), _jsx("input", Object.assign({ type: "password" }, register("password", {
                                                required: {
                                                    value: true,
                                                    message: "This field is required.",
                                                },
                                                validate: (value) => validatePassword(value),
                                            }), { className: "form-control", id: "password", placeholder: "*******", onChange: (e) => SetsignupCredentials(Object.assign(Object.assign({}, signupCredentials), { password: e.target.value })), value: signupCredentials.password }))] })), _jsx("div", Object.assign({ className: "d-grid" }, { children: _jsx("button", Object.assign({ className: "btn btn-outline-dark", type: "submit" }, { children: "Sign Up" })) }))] })) })) })) })) })) })) })));
};
export default Signup;
