import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { TextField, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LeadDetailApi from "../../../infrastructure/api/pages/lead-detail-api";
import { useTranslation } from "react-i18next";
import CustomModal from "../modal/CustomModal";
const CompanyInfoModal = ({ open, onClose, GetLeadContacts, initialFormData, data, }) => {
    const [saving, setSaving] = useState(false);
    const { t: translate } = useTranslation();
    const hiddenButtonRef = useRef(null);
    const handleSaveButtonClick = () => {
        // Trigger click event on the hidden button
        if (hiddenButtonRef.current) {
            hiddenButtonRef.current.click();
        }
    };
    const validationSchema = yup.object({
        name: yup.string().required(translate("NameRequired")),
        email: yup.string().email(translate("InvalidEmail")),
    });
    // State for form fields
    const { reset, handleSubmit, control, register, formState: { errors }, } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const onCloseModal = () => {
        reset(initialFormData);
        onClose();
    };
    const onSubmit = (data) => {
        const urlParams = new URLSearchParams(window.location.search);
        const leadId = urlParams.get("id");
        const companyId = urlParams.get("companyId");
        if (companyId && leadId) {
            data.leadId = leadId;
            data.companyId = companyId;
            if (data.id && data.id != "") {
                setSaving(true);
                LeadDetailApi.updateContact(data)
                    .then((response) => {
                    if (response.status === 200 && response.data != null) {
                        toast.success(translate("UpdatedSuccessfully"));
                        onCloseModal();
                        GetLeadContacts();
                        reset(initialFormData);
                    }
                })
                    .finally(() => {
                    setSaving(false);
                });
            }
            else {
                setSaving(true);
                data.id = undefined;
                LeadDetailApi.addContact(data)
                    .then((response) => {
                    if (response.status === 200 && response.data != null) {
                        toast.success(translate("SavedSuccessfully"));
                        onCloseModal();
                        GetLeadContacts();
                        reset(initialFormData);
                    }
                })
                    .finally(() => {
                    setSaving(false);
                });
            }
        }
    };
    useEffect(() => {
        reset(data);
    }, [data]);
    return (_jsx(CustomModal, Object.assign({ open: open, onClose: onCloseModal, size: "sm", saving: saving, handleSaveButtonClick: handleSaveButtonClick, heading: translate("AddContactPerson"), saveButtonText: translate("Save"), disabled: false }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 0 }, { children: _jsx(Grid, Object.assign({ item: true, lg: 8, xs: 12, mx: "auto" }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("input", Object.assign({ type: "hidden" }, register("id"))), _jsx(Controller, { name: "name", control: control, defaultValue: data.name, render: ({ field }) => {
                                var _a;
                                return (_jsx(TextField, Object.assign({ label: translate("Name"), variant: "outlined", fullWidth: true, margin: "normal" }, field, { error: !!errors.name, helperText: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message })));
                            } }), _jsx(Controller, { name: "function", control: control, defaultValue: data.function, render: ({ field }) => (_jsx(TextField, Object.assign({ label: translate("Function"), variant: "outlined", fullWidth: true, margin: "normal" }, field))) }), _jsx(Controller, { name: "phoneNumber", control: control, defaultValue: data.phoneNumber, render: ({ field }) => (_jsx(TextField, Object.assign({ label: translate("Telephone"), variant: "outlined", fullWidth: true, margin: "normal" }, field))) }), _jsx(Controller, { name: "email", control: control, defaultValue: data.email, render: ({ field }) => {
                                var _a;
                                return (_jsx(TextField, Object.assign({ label: translate("MailAddress"), variant: "outlined", fullWidth: true, margin: "normal" }, field, { error: !!errors.email, helperText: (_a = errors.email) === null || _a === void 0 ? void 0 : _a.message })));
                            } }), _jsx("button", Object.assign({ ref: hiddenButtonRef, type: "submit", hidden: true }, { children: "Submit" }))] })) })) })) })));
};
export default CompanyInfoModal;
