import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import { convertToNLEuroFormat } from "../../../application/common/utils/helper";
import { statusBgColor, statusTextColor, } from "../../../application/common/utils/column-helper";
import { useTranslation } from "react-i18next";
const OfferLeadObject = ({ leadobject, setSelectedObjects, selectedObjects, }) => {
    const { t: translate } = useTranslation();
    const handleCheckboxChange = (leadObjectPrice) => {
        const existingIndex = selectedObjects.findIndex((record) => record.objectId === leadObjectPrice.objectId);
        if (existingIndex !== -1) {
            // If the record already exists, remove it
            const updatedRecords = [...selectedObjects];
            updatedRecords.splice(existingIndex, 1);
            setSelectedObjects(updatedRecords);
        }
        else {
            // If the record doesn't exist, add it
            setSelectedObjects((prevRecords) => [...prevRecords, leadObjectPrice]);
        }
    };
    return (_jsxs(Grid, Object.assign({ item: true, container: true, lg: 12, xs: 12, mx: "auto", gap: 3, sx: {
            borderRadius: "12px",
            background: "var(--n-primary-light, #F9F6FF)",
            padding: 2,
            boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.05), 0px 12px 12px 0px rgba(0, 0, 0, 0.04), 0px 26px 16px 0px rgba(0, 0, 0, 0.03), 0px 46px 19px 0px rgba(0, 0, 0, 0.01), 0px 73px 20px 0px rgba(0, 0, 0, 0.00)",
        } }, { children: [_jsxs(Grid, Object.assign({ item: true, lg: 3, md: 3, xs: 12, display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "center" }, { children: [leadobject.isContracted ? (_jsx(Checkbox, { disabled: true, checked: true })) : (_jsx(Checkbox, { onChange: () => handleCheckboxChange({
                            objectId: leadobject.id,
                            price: leadobject.price,
                            updatedPrice: leadobject.updatedPrice == undefined ||
                                leadobject.updatedPrice == null ||
                                leadobject.updatedPrice == 0
                                ? leadobject.price
                                : leadobject.updatedPrice,
                        }) })), _jsx(Box, { children: _jsx(Box, { component: "img", sx: {
                                maxWidth: { xs: "100%", md: "100%" },
                                maxHeight: "244px",
                                height: "auto",
                                objectFit: "contain",
                                objectPosition: "center center",
                            }, src: leadobject.image }) })] })), _jsxs(Grid, Object.assign({ item: true, lg: 4, md: 4, xs: 12, sx: {
                    borderRight: {
                        xs: "0px",
                        md: "1px solid var(--n-teal-dark, #4D666E)",
                    },
                    borderBottom: {
                        xs: "1px solid var(--n-teal-dark, #4D666E)",
                        md: "0px",
                        lg: "0px",
                    },
                }, display: "flex", flexDirection: "column", alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ color: "color: var(--n-jetblack, #312F2F);", variant: "h6", sx: {
                            paddingBottom: "8px",
                        } }, { children: leadobject.street })), _jsxs(Typography, Object.assign({ color: "color: var(--n-jetblack, #312F2F);", variant: "h6", sx: {
                            paddingBottom: "8px",
                        } }, { children: [leadobject.houseNumber, leadobject.addition, " ", leadobject.city] })), _jsx(Typography, Object.assign({ sx: {
                            color: "var(--Status-Success, #2AA241)",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "150%" /* 27px */,
                            letterSpacing: "1px",
                            paddingBottom: "16px",
                        } }, { children: convertToNLEuroFormat(leadobject.price) })), _jsxs(Typography, Object.assign({ variant: "body1", color: "var(--Neutral-600, #6C757D)", sx: {
                            paddingBottom: "8px",
                        } }, { children: ["Dakvlakken: ", leadobject.totalRoofSurfaces] })), _jsxs(Typography, Object.assign({ variant: "body1", color: "var(--Neutral-600, #6C757D)", sx: {
                            paddingBottom: "8px",
                        } }, { children: ["Oppervlakte: ", leadobject.area] })), leadobject.isSpecial && (_jsx(Box, Object.assign({ bgcolor: statusBgColor[6], padding: 1, borderRadius: 2 }, { children: _jsx(Typography, Object.assign({ variant: "h4", color: statusTextColor[6] }, { children: translate("Special") })) })))] })), _jsxs(Grid, Object.assign({ item: true, lg: 4, md: 4, xs: 12, display: "flex", flexDirection: "column", alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ color: "var(--Neutral-700, #495057)", variant: "body2" }, { children: "Aanmeldprocedure:" })), _jsx(Typography, Object.assign({ color: " var(--Neutral-600, #6C757D)", variant: "body2" }, { children: leadobject.registrationProcedure }))] }))] })));
};
export default OfferLeadObject;
