import { ModuleActions } from "../enums/screen-name";
export const checkRead = (rolepermissions, moduleName) => {
    return rolepermissions.filter(x => x.controller === moduleName && x.action === ModuleActions.Read && x.isDeleted === false).length > 0;
};
export const checkManage = (rolepermissions, moduleName) => {
    return rolepermissions.filter(x => x.controller === moduleName && x.action === ModuleActions.Manage && x.isDeleted === false).length > 0;
};
export const checkDelete = (rolepermissions, moduleName) => {
    return rolepermissions.filter(x => x.controller === moduleName && x.action === ModuleActions.Delete && x.isDeleted === false).length > 0;
};
export const checkCreateEdit = (rolepermissions, moduleName) => {
    return rolepermissions.filter(x => x.controller === moduleName && x.action === ModuleActions.Create && x.isDeleted === false).length > 0;
};
export const rolesActions = (userRolesPermissions, screenName) => {
    const allowedActionsRolesPermissions = userRolesPermissions === null || userRolesPermissions === void 0 ? void 0 : userRolesPermissions.filter((rolesPermissions) => {
        return (rolesPermissions.screenName == screenName &&
            rolesPermissions.isDeleted == false);
    });
    const allowedActions = allowedActionsRolesPermissions === null || allowedActionsRolesPermissions === void 0 ? void 0 : allowedActionsRolesPermissions.map((rolesPermissions) => rolesPermissions.action);
    return allowedActions;
};
