import { http_api } from "../../leadg-api";
const getDiscussionByLeadId = (leadId) => {
    return http_api.get(`lead-discussion/get-by-lead-id/${leadId}`);
};
const sendChat = (discussion) => {
    http_api.post("lead-discussion/add", discussion);
};
const LeadDiscussionApi = {
    getDiscussionByLeadId,
    sendChat,
};
export default LeadDiscussionApi;
