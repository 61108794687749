var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { Box, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography, CircularProgress, useTheme, } from "@mui/material";
import { StyledTableCell, StyledTableRow, TableRowsLoader, } from "../../components/mui-table/mui-table-helper";
import { Pencil, Trash } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import CompanyRolesFormSubmit from "./CompanyRolesFormSubmit";
import CompanyRolesApi from "../../../infrastructure/api/pages/company-roles-api";
import { Layout } from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import CompanyRoleTopHeader from "./CompanyRoleTopHeader";
import { UserRolesPermissionsContext } from "../../../context/UserRolesPermissionsContext";
import { checkCreateEdit, checkDelete, checkManage, } from "../../../application/common/utils/roles-helper";
import { Modules } from "../../../application/common/enums/screen-name";
const initialFormData = {
    id: "",
    number: "",
    name: "",
};
export const CompanyRoles = () => {
    const theme = useTheme();
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState(initialFormData);
    const [refereshGrid, setRefereshGrid] = useState(false);
    const [roleModalOpen, setRoleModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [idToBeDeleted, setIdToBeDeleted] = useState();
    const { t: translate } = useTranslation();
    const { RolesPermissions } = useContext(UserRolesPermissionsContext);
    const [modalTitle, setModalTitle] = useState("");
    useEffect(() => {
        const fetchRoles = () => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            try {
                const response = yield CompanyRolesApi.getAllRole();
                if (response.status === 200 && response.data) {
                    setRoles(response.data);
                }
            }
            finally {
                setLoading(false);
            }
        });
        fetchRoles();
    }, [refereshGrid]);
    const handleDeleteRole = (id) => __awaiter(void 0, void 0, void 0, function* () {
        if (!id)
            return;
        setDeleting(true);
        setIdToBeDeleted(id);
        try {
            const result = yield CompanyRolesApi.deleteRoleById(id);
            if (result.data) {
                toast.info(translate("RoleDeletedSuccessfully"));
                setRefereshGrid(!refereshGrid);
            }
        }
        catch (_a) {
            toast.error(translate("ErrorDeleteCompanyRole"));
        }
        finally {
            setDeleting(false);
            setIdToBeDeleted(undefined);
        }
    });
    const handleNewRole = () => {
        setRole(initialFormData);
        setModalTitle("NewCompanyRole");
        setRoleModalOpen(true);
    };
    const handleEditRole = (roleToEdit) => {
        setRole(roleToEdit);
        setModalTitle("UpdateCompanyRole");
        setRoleModalOpen(true);
    };
    const onCloseCompanyRoleModal = () => {
        setRoleModalOpen(false);
        setRole(initialFormData);
    };
    const columns = [
        // { id: "id", label: "Id", minWidth: 100, align: "left" },
        { id: "number", label: translate("Number"), minWidth: 100, align: "left" },
        { id: "name", label: translate("Name"), minWidth: 100, align: "left" },
    ];
    if (checkCreateEdit(RolesPermissions, Modules.RoleManagement) ||
        checkManage(RolesPermissions, Modules.RoleManagement)) {
        columns.push({
            id: "action",
            label: translate("Action"),
            minWidth: 100,
            align: "left",
        });
    }
    return (_jsxs(Layout, { children: [_jsx(CompanyRoleTopHeader, { openPopup: handleNewRole, translate: translate }), _jsx(Box, Object.assign({ sx: {
                    marginTop: "1%",
                    padding: { xs: "16px 16px", sm: "16px 32px" },
                    bgcolor: theme.palette.common.white,
                    borderRadius: "24px",
                } }, { children: _jsx(Paper, Object.assign({ sx: { width: "100%", overflow: "hidden", boxShadow: "none" } }, { children: _jsx(TableContainer, { children: _jsxs(Table, Object.assign({ stickyHeader: true, "aria-label": "sticky table" }, { children: [_jsx(TableHead, { children: _jsx(TableRow, Object.assign({ sx: { backgroundColor: theme.palette.common.white } }, { children: columns.map((column) => (_jsx(StyledTableCell, Object.assign({ align: column.align, style: { minWidth: column.minWidth } }, { children: _jsx(Typography, Object.assign({ variant: "h6", color: theme.palette.text.primary, sx: {
                                                    fontSize: {
                                                        xs: "0.875rem",
                                                        sm: "1rem",
                                                        md: "1.25rem",
                                                    },
                                                } }, { children: translate(`${column.label}`) })) }), column.id))) })) }), _jsx(TableBody, { children: loading ? (_jsx(TableRowsLoader, { rowsNum: 10, cellNum: 4 })) : (roles.map((role, index) => (_jsxs(StyledTableRow, { children: [_jsx(StyledTableCell, Object.assign({ align: "left" }, { children: _jsx(Typography, Object.assign({ variant: "body2" }, { children: role.number })) })), _jsx(StyledTableCell, Object.assign({ align: "left" }, { children: _jsx(Typography, Object.assign({ variant: "body2" }, { children: role.name })) })), _jsx(StyledTableCell, Object.assign({ align: "left" }, { children: _jsxs(Grid, Object.assign({ container: true, alignContent: "left", justifyContent: "left", gap: { xs: 3, sm: 1 } }, { children: [checkCreateEdit(RolesPermissions, Modules.RoleManagement) && (_jsx(Grid, Object.assign({ item: true, xs: 3 }, { children: _jsx(Box, Object.assign({ sx: {
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    bgcolor: "#F4FCFF",
                                                                    borderRadius: "50%",
                                                                    width: "36px",
                                                                    height: "36px",
                                                                } }, { children: _jsx(Pencil, { onClick: () => handleEditRole(role) }) })) }))), checkDelete(RolesPermissions, Modules.RoleManagement) && (_jsx(Grid, Object.assign({ item: true, xs: 3 }, { children: _jsx(Box, Object.assign({ sx: {
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    bgcolor: "#F4FCFF",
                                                                    borderRadius: "50%",
                                                                    width: "36px",
                                                                    height: "36px",
                                                                } }, { children: deleting && idToBeDeleted === role.id ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : (_jsx(Trash, { onClick: () => handleDeleteRole(role.id) })) })) })))] })) }))] }, role.id)))) })] })) }) })) })), _jsx(CompanyRolesFormSubmit, { open: roleModalOpen, onClose: onCloseCompanyRoleModal, GetRoles: () => setRefereshGrid(!refereshGrid), initialFormData: initialFormData, data: role, title: translate(modalTitle) })] }));
};
export default CompanyRoles;
