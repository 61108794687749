var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, FormControl, Grid, TextField, Typography, } from "@mui/material";
import { ClientLayout } from "../../components/Layout/ClientLayout/ClientLayout";
import "./BillingAddress.scss";
import { AddressBook, Buildings, CaretDown, CaretLeft, CaretRight, Phone, X, } from "@phosphor-icons/react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ClientPortalApi from "../../../infrastructure/api/pages/client-portal-api";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
const initialFormData = {
    id: "",
    name: "",
    chamberOfCommerce: "",
    street: "",
    houseNumber: "",
    addition: "",
    postalCode: "",
    city: "",
    country: "",
    phoneNumber: "",
    email: "",
    contractId: "",
    reference: "",
    department: "",
    ledgerAccount: "",
    isDeleted: "",
};
const initialPurchaseOrderFormData = {
    contractId: undefined,
    reference: "",
    department: "",
    ledgerAccount: "",
    files: [],
};
const BillingAddress = () => {
    var _a;
    const [expand, setExpand] = useState(false);
    const navigate = useNavigate();
    const { t: translate } = useTranslation();
    const urlParams = new URLSearchParams(window.location.search);
    const [data, setData] = useState(initialFormData);
    const [purchaseOrderData, setPurchaseOrderData] = useState(initialPurchaseOrderFormData);
    const hiddenButtonRef = useRef(null);
    const contractId = urlParams.get("contractId");
    const [files, setFiles] = useState([]);
    const [saving, setSaving] = useState(false);
    const [refreshDocument, setRefreshDocument] = useState(false);
    const [deletingDocumentId, setDeletingDocumentId] = useState(undefined);
    const [disablePurchaseOrder, setDisablePurchaseOrder] = useState(false);
    useEffect(() => {
        if (contractId) {
            ClientPortalApi.getCompanyInvoiceAddressInfo(contractId)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    setData(response.data);
                }
            })
                .finally(() => { });
        }
    }, [contractId]);
    useEffect(() => {
        if (contractId) {
            ClientPortalApi.getPurchaseOrderData(contractId)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    setPurchaseOrderData(response.data);
                    setDisablePurchaseOrder(!response.data.disablePurchaseOrder);
                }
            })
                .finally(() => { });
        }
    }, [contractId, refreshDocument]);
    useEffect(() => {
        reset(data);
    }, [data]);
    const handleFileChange = (event) => {
        if (event.target.files) {
            setFiles(Array.from(event.target.files));
        }
    };
    const handleSaveButtonClick = () => {
        // Trigger click event on the hidden button
        if (hiddenButtonRef.current) {
            hiddenButtonRef.current.click();
        }
    };
    const handleDeleteDocment = (id) => {
        if (id) {
            setDeletingDocumentId(id);
            ClientPortalApi.deleteDocument(id)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    toast.success(translate("DeletedSuccessfully"));
                }
            })
                .finally(() => {
                setDeletingDocumentId(undefined);
                setRefreshDocument((prev) => !prev);
            });
        }
    };
    const handleDeleteNewDocment = (indexToRemove) => {
        if (indexToRemove != undefined) {
            setFiles((prevFiles) => {
                // Filter out the entry at the specified index
                const newArray = prevFiles.filter((_, index) => index !== indexToRemove);
                return newArray;
            });
        }
    };
    const handleOnSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        //setSaving(true);
        if (contractId) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i]);
            }
            formData.append("contractId", contractId);
            formData.append("disablePurchaseOrder", !disablePurchaseOrder);
            yield ClientPortalApi.uploadDocumentData(formData)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    toast.success(translate("SavedSuccessfully"));
                    //navigate("/checking-and-paying?contractId=" + contractId);
                }
            })
                .finally(() => {
                //setSaving(false);
            });
        }
    });
    const onSubmitForm = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setSaving(true);
        yield handleOnSubmit()
            .then(() => {
            ClientPortalApi.updateCompanyInvoiceInfo(data).then((response) => {
                if (response.status === 200 && response.data != null) {
                    //toast.success(translate("SavedSuccessfully"));
                    setTimeout(() => {
                        navigate("/checking-and-paying?contractId=" + contractId);
                        setSaving(false);
                    }, 2000); // 4000 milliseconds = 4 seconds
                }
            });
        })
            .finally(() => {
            //setSaving(false);
        });
    });
    const { control, handleSubmit, reset } = useForm();
    return (_jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmitForm) }, { children: _jsx(FormControl, Object.assign({ fullWidth: true, variant: "outlined", margin: "normal" }, { children: _jsxs(ClientLayout, Object.assign({ page: "billing-address" }, { children: [_jsxs(Box, Object.assign({ display: "flex", flexDirection: "column", gap: 1, padding: "2.5%" }, { children: [_jsx(Typography, Object.assign({ variant: "h1" }, { children: translate("Billing address") })), _jsx(Typography, Object.assign({ variant: "body1" }, { children: translate("Fill in your billing information here") }))] })), _jsx(Grid, Object.assign({ container: true, lg: 12, xs: 12, display: "flex", justifyContent: "center", pt: 1, pb: 1, pr: { xs: 1, sm: 3, md: 5, lg: 0 }, pl: { xs: 1, sm: 3, md: 5, lg: 0 }, className: "main-container" }, { children: _jsxs(Grid, Object.assign({ container: true, lg: 8, xs: 12, display: "flex", flexDirection: "column", gap: 2, item: true }, { children: [_jsxs(Accordion, Object.assign({ defaultExpanded: true, sx: {
                                        width: "100%",
                                        borderRadius: "16px",
                                        background: "var(--n-primary-light, #F9F6FF)",
                                        boxShadow: "0px 29px 63px 0px rgba(19, 9, 34, 0.10), 0px 115px 115px 0px rgba(19, 9, 34, 0.09), 0px 258px 155px 0px rgba(19, 9, 34, 0.05), 0px 459px 184px 0px rgba(19, 9, 34, 0.01), 0px 717px 201px 0px rgba(19, 9, 34, 0.00)",
                                    } }, { children: [_jsx(AccordionSummary, Object.assign({ sx: { padding: "16px" }, "aria-controls": "panel1d-content", id: "panel1d-header", expandIcon: _jsx(CaretDown, { size: 24, color: "#410073" }) }, { children: _jsxs(Box, Object.assign({ gap: 2, width: "100%", display: "flex" }, { children: [_jsx(Buildings, { size: 24, color: "#410073" }), _jsx(Typography, Object.assign({ fontSize: "16px", color: "#410073", fontWeight: 600 }, { children: translate("Company") }))] })) })), _jsxs(AccordionDetails, { children: [_jsx(Controller, { name: "name", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({}, field, { InputLabelProps: {
                                                            shrink: true,
                                                        }, label: translate("CompanyName"), variant: "outlined", margin: "normal", sx: { width: "100%" } }))) }), _jsx(Controller, { name: "chamberOfCommerce", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({}, field, { InputLabelProps: {
                                                            shrink: true,
                                                        }, label: translate("ChamberOfCommerceNumber"), variant: "outlined", margin: "normal", sx: { width: "100%" } }))) })] })] })), _jsxs(Accordion, Object.assign({ defaultExpanded: true, sx: {
                                        width: "100%",
                                        borderRadius: "16px",
                                        background: "var(--n-primary-light, #F9F6FF)",
                                        boxShadow: "0px 29px 63px 0px rgba(19, 9, 34, 0.10), 0px 115px 115px 0px rgba(19, 9, 34, 0.09), 0px 258px 155px 0px rgba(19, 9, 34, 0.05), 0px 459px 184px 0px rgba(19, 9, 34, 0.01), 0px 717px 201px 0px rgba(19, 9, 34, 0.00)",
                                    } }, { children: [_jsx(AccordionSummary, Object.assign({ sx: { padding: "16px" }, "aria-controls": "panel1d-content", id: "panel1d-header", expandIcon: _jsx(CaretDown, { size: 24, color: "#410073" }) }, { children: _jsxs(Box, Object.assign({ gap: 2, width: "100%", display: "flex" }, { children: [_jsx(AddressBook, { size: 24, color: "#410073" }), _jsx(Typography, Object.assign({ fontSize: "16px", color: "#410073", fontWeight: 600 }, { children: translate("Address") }))] })) })), _jsx(AccordionDetails, { children: _jsxs(Grid, Object.assign({ lg: 12, container: true }, { children: [_jsxs(Grid, Object.assign({ container: true, lg: 12, flexDirection: "row", justifyContent: "space-between", item: true }, { children: [_jsx(Controller, { name: "street", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({}, field, { InputLabelProps: {
                                                                        shrink: true,
                                                                    }, label: translate("Street"), variant: "outlined", margin: "normal", sx: { width: { xs: "100%", sm: "49%", md: "49%" } } }))) }), _jsx(Controller, { name: "houseNumber", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({}, field, { InputLabelProps: {
                                                                        shrink: true,
                                                                    }, label: translate("HouseNumber"), variant: "outlined", margin: "normal", sx: { width: { xs: "49%", sm: "24%", md: "24%" } } }))) }), _jsx(Controller, { name: "addition", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({}, field, { InputLabelProps: {
                                                                        shrink: true,
                                                                    }, label: translate("Addition"), variant: "outlined", margin: "normal", sx: { width: { xs: "49%", sm: "24%", md: "24%" } } }))) })] })), _jsxs(Grid, Object.assign({ container: true, lg: 12, flexDirection: "row", justifyContent: "space-between", item: true }, { children: [_jsx(Controller, { name: "postalCode", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({}, field, { InputLabelProps: {
                                                                        shrink: true,
                                                                    }, label: translate("PostalCode"), variant: "outlined", margin: "normal", sx: { width: { xs: "100%", sm: "49%", md: "49%" } } }))) }), _jsx(Controller, { name: "city", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({}, field, { InputLabelProps: {
                                                                        shrink: true,
                                                                    }, label: translate("Place"), variant: "outlined", margin: "normal", sx: { width: { xs: "100%", sm: "49%", md: "49%" } } }))) })] })), _jsx(Controller, { name: "country", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({ InputLabelProps: {
                                                                shrink: true,
                                                            } }, field, { label: translate("Country"), variant: "outlined", margin: "normal", sx: { width: "100%" } }))) })] })) })] })), _jsxs(Accordion, Object.assign({ defaultExpanded: true, sx: {
                                        width: "100%",
                                        borderRadius: "16px",
                                        background: "var(--n-primary-light, #F9F6FF)",
                                        boxShadow: "0px 29px 63px 0px rgba(19, 9, 34, 0.10), 0px 115px 115px 0px rgba(19, 9, 34, 0.09), 0px 258px 155px 0px rgba(19, 9, 34, 0.05), 0px 459px 184px 0px rgba(19, 9, 34, 0.01), 0px 717px 201px 0px rgba(19, 9, 34, 0.00)",
                                    } }, { children: [_jsx(AccordionSummary, Object.assign({ sx: { padding: "16px" }, "aria-controls": "panel1d-content", id: "panel1d-header", expandIcon: _jsx(CaretDown, { size: 24, color: "#410073" }) }, { children: _jsxs(Box, Object.assign({ gap: 2, width: "100%", display: "flex" }, { children: [_jsx(Phone, { size: 24, color: "#410073" }), _jsx(Typography, Object.assign({ fontSize: "16px", color: "#410073", fontWeight: 600 }, { children: "Contactgegevens voor facturatie" }))] })) })), _jsx(AccordionDetails, { children: _jsx(Grid, Object.assign({ lg: 12, container: true }, { children: _jsxs(Grid, Object.assign({ container: true, lg: 12, flexDirection: "row", justifyContent: "space-between", item: true }, { children: [_jsx(Controller, { name: "email", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({}, field, { InputLabelProps: {
                                                                    shrink: true,
                                                                }, label: translate("Email"), variant: "outlined", margin: "normal", sx: { width: { xs: "100%", sm: "49%", md: "49%" } } }))) }), _jsx(Controller, { name: "phoneNumber", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({}, field, { InputLabelProps: {
                                                                    shrink: true,
                                                                }, label: translate("PhoneNumber"), variant: "outlined", margin: "normal", sx: { width: { xs: "100%", sm: "49%", md: "49%" } } }))) })] })) })) })] })), _jsxs(Accordion, Object.assign({ defaultExpanded: true, sx: {
                                        width: "100%",
                                        borderRadius: "16px",
                                        background: "var(--n-primary-light, #F9F6FF)",
                                        boxShadow: "0px 29px 63px 0px rgba(19, 9, 34, 0.10), 0px 115px 115px 0px rgba(19, 9, 34, 0.09), 0px 258px 155px 0px rgba(19, 9, 34, 0.05), 0px 459px 184px 0px rgba(19, 9, 34, 0.01), 0px 717px 201px 0px rgba(19, 9, 34, 0.00)",
                                    } }, { children: [_jsx(AccordionSummary, Object.assign({ id: "panel1d-header", expandIcon: _jsx(CaretDown, { size: 24, color: "#410073" }), "aria-controls": "panel1d-content", sx: { padding: "16px" } }, { children: _jsxs(Box, Object.assign({ gap: 2, width: "100%", display: "flex" }, { children: [_jsx(Phone, { size: 24, color: "#410073" }), _jsx(Typography, Object.assign({ fontSize: "16px", color: "#410073", fontWeight: 600 }, { children: translate("PurchaseOrder") +
                                                            "(" +
                                                            translate("optional") +
                                                            ")" }))] })) })), _jsx(AccordionDetails, { children: _jsx(Grid, Object.assign({ lg: 12, container: true }, { children: _jsxs(Grid, Object.assign({ container: true, lg: 12, flexDirection: "row", justifyContent: "space-between", item: true }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, lg: 12, gap: 1 }, { children: [_jsx(Controller, { name: "reference", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({}, field, { InputLabelProps: {
                                                                            shrink: true,
                                                                        }, label: translate("Reference"), variant: "outlined", margin: "normal", sx: {
                                                                            width: { xs: "100%", sm: "49%", md: "49%" },
                                                                        } }))) }), _jsx(Controller, { name: "department", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({}, field, { InputLabelProps: {
                                                                            shrink: true,
                                                                        }, label: translate("Department"), variant: "outlined", margin: "normal", sx: {
                                                                            width: { xs: "100%", sm: "49%", md: "49%" },
                                                                        } }))) }), _jsx(Controller, { name: "ledgerAccount", control: control, render: ({ field }) => (_jsx(TextField, Object.assign({}, field, { InputLabelProps: {
                                                                            shrink: true,
                                                                        }, label: translate("LedgerAccount"), variant: "outlined", margin: "normal", sx: { width: "49%" } }))) }), _jsx(Box, Object.assign({ alignContent: "center", sx: { width: { xs: "100%", sm: "49%", md: "49%" } } }, { children: _jsx(Box, Object.assign({ justifyContent: "center" }, { children: _jsxs(Button, Object.assign({ variant: "contained", component: "label", disabled: saving }, { children: [translate("UploadFiles"), _jsx("input", { type: "file", multiple: true, hidden: true, onChange: handleFileChange })] })) })) }))] })), _jsx(Grid, Object.assign({ container: true, item: true, display: "flex", lg: 8, gap: 1, marginTop: 1 }, { children: files &&
                                                                (files === null || files === void 0 ? void 0 : files.map((file, index) => (_jsxs(Grid, Object.assign({ item: true, className: "selected-file" }, { children: [_jsx(Typography, Object.assign({ variant: "body2" }, { children: file.name })), _jsx(X, { size: 16, onClick: () => {
                                                                                handleDeleteNewDocment(index);
                                                                            }, cursor: "pointer" })] }))))) })), _jsx(Grid, Object.assign({ container: true, item: true, display: "flex", lg: 12, gap: 1, marginTop: 1 }, { children: (purchaseOrderData === null || purchaseOrderData === void 0 ? void 0 : purchaseOrderData.files) &&
                                                                ((_a = purchaseOrderData === null || purchaseOrderData === void 0 ? void 0 : purchaseOrderData.files) === null || _a === void 0 ? void 0 : _a.map((file) => (_jsxs(Grid, Object.assign({ item: true, className: deletingDocumentId &&
                                                                        deletingDocumentId == file.id
                                                                        ? "disabled-file"
                                                                        : "selected-file" }, { children: [deletingDocumentId &&
                                                                            deletingDocumentId == file.id ? (_jsx(CircularProgress, { size: 24 })) : (_jsx(_Fragment, {})), _jsx(Typography, Object.assign({ variant: "body2" }, { children: file.fileName })), _jsx(X, { size: 16, onClick: () => {
                                                                                if (!deletingDocumentId) {
                                                                                    handleDeleteDocment(file.id);
                                                                                }
                                                                            }, cursor: "pointer" })] }))))) })), _jsx("button", Object.assign({ ref: hiddenButtonRef, type: "submit", hidden: true }, { children: "Submit" }))] })) })) })] }))] })) })), _jsxs(Box, Object.assign({ className: "billing-address-footer" }, { children: [_jsx(Link, Object.assign({ to: "/roof-access?contractId=" + contractId }, { children: _jsxs(Button, Object.assign({ variant: "outlined", sx: { borderColor: "white !important" } }, { children: [_jsx(CaretLeft, { size: 24 }), _jsx(Typography, Object.assign({ fontWeight: 700 }, { children: translate("Go back") }))] })) })), _jsxs(Button, Object.assign({ variant: "contained", color: "primary", onClick: handleSaveButtonClick, disabled: saving }, { children: [saving ? _jsx(CircularProgress, { size: 24, color: "inherit" }) : _jsx(_Fragment, {}), _jsx(Typography, { children: translate("Save & Continue") }), _jsx(CaretRight, { size: 24 })] }))] }))] })) })) })));
};
export default BillingAddress;
