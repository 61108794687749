import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState, } from "react";
import { Box, Typography, Grid, Accordion, AccordionSummary, AccordionDetails, Autocomplete, TextField, FormControlLabel, Checkbox, useMediaQuery, useTheme, } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomModal from "../modal/CustomModal";
import { CaretDown } from "@phosphor-icons/react";
import LeadsObjectApi from "../../../infrastructure/api/pages/lead-object-api";
import OfferLeadObject from "./OfferLeadObject";
import OfferLeadObjectPrice from "./OfferLeadObjectPrice";
import LeadDetailApi from "../../../infrastructure/api/pages/lead-detail-api";
import ContractApi from "../../../infrastructure/api/pages/contract-api";
import { toast } from "react-toastify";
import { convertToNLEuroFormat } from "../../../application/common/utils/helper";
const OfferModal = ({ open, onClose, leadId, refresh, setRefreshModalData, setObjectModal, setActionTabRefresh, }) => {
    const [selectedObjects, setSelectedObjects] = useState([]);
    const [isContractContinous, setIsContractContinous] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [contactOptions, setContactOptions] = useState([]);
    const [error, setError] = useState(undefined);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isSeprateContract, setIsSeprateContract] = useState(false);
    const contactSelectRef = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const GetLeadContacts = () => {
        if (leadId) {
            LeadDetailApi.getLeadContacts(leadId)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    setContacts(response.data);
                }
            })
                .finally(() => { });
        }
    };
    const GetLeadObjectById = (leadId) => {
        if (leadId) {
            LeadsObjectApi.getLeadObjectsByid(leadId)
                .then((response) => {
                if (response.data && response.status === 200) {
                    setleadObjects(response.data);
                }
            })
                .finally(() => { });
        }
    };
    useEffect(() => {
        if (contacts && contacts.length > 0) {
            if (contacts.length > 0) {
                const op = contacts.map((item) => {
                    return {
                        id: item.id,
                        label: item.name,
                        // map other properties if needed
                    };
                });
                setContactOptions(op);
            }
        }
    }, [contacts]);
    const [saving, setSaving] = useState(false);
    const { t: translate } = useTranslation();
    const [selectedContact, setSelectedContact] = useState(null);
    const handleAutocompleteChange = (event, newValue) => {
        setSelectedContact(newValue);
        if (!newValue) {
            setError("Required");
        }
        else {
            setError(undefined);
        }
    };
    const handleSaveButtonClick = () => {
        var _a;
        // Trigger click event on the hidden button
        if (selectedContact === null || selectedContact === void 0 ? void 0 : selectedContact.id) {
            if (leadId) {
                // Iterate over each object in the source array
                leadObjects.forEach((sourceObj) => {
                    // Find the corresponding object in the target array using its ID
                    const targetIndex = selectedObjects.findIndex((targetObj) => targetObj.objectId === sourceObj.id);
                    // If the object is found, update it with data from the source object
                    if (targetIndex !== -1) {
                        selectedObjects[targetIndex] = Object.assign(Object.assign({}, selectedObjects[targetIndex]), sourceObj);
                    }
                });
                const mappedObjects = selectedObjects.map((obj) => ({
                    objectId: obj.objectId,
                    price: obj.price,
                    updatedPrice: obj.updatedPrice,
                }));
                var contract = {
                    leadId: leadId,
                    objects: mappedObjects,
                    isDeleted: false,
                    contactId: selectedContact === null || selectedContact === void 0 ? void 0 : selectedContact.id,
                    isSigned: false,
                    name: "",
                    functie: "",
                    remark: "",
                    payLater: true,
                    contactMe: true,
                    informationSubmitted: false,
                    isContinous: isContractContinous,
                    isCopied: false,
                    consultancyNewsLetter: true,
                    installationNewLetter: true,
                    productsNewLetter: true,
                    trainingNewLetter: true,
                    inpectionNewLetter: true,
                    inspectionPDF: "",
                    isSeprateContract: isSeprateContract,
                };
                if (contract) {
                    setSaving(true);
                    ContractApi.add(contract)
                        .then((response) => {
                        if (response.status === 200 && response.data != null) {
                            toast.success("Offerte succesvol verzonden.");
                            onCloseModal();
                        }
                    })
                        .finally(() => {
                        setSaving(false);
                        setRefreshModalData((prev) => !prev);
                        setObjectModal((prev) => !prev);
                        setActionTabRefresh((prev) => !prev);
                        setIsContractContinous(false);
                    });
                }
            }
        }
        else {
            setError("Required");
            (_a = contactSelectRef === null || contactSelectRef === void 0 ? void 0 : contactSelectRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    };
    const onCloseModal = () => {
        setSelectedObjects([]);
        setSelectedContact(null);
        setError(undefined);
        GetLeadObjectById(leadId);
        onClose();
    };
    const [leadObjects, setleadObjects] = useState([]);
    useEffect(() => {
        if (leadId) {
            GetLeadContacts();
            GetLeadObjectById(leadId);
        }
    }, [leadId, refresh]);
    //Update lead object prices
    const updatePriceOfLeadObject = (index, updatedItem) => {
        setleadObjects((prevItems) => {
            const updatedItems = [...prevItems]; // Create a copy of the original array
            updatedItems[index] = updatedItem; // Replace the item at the specified index
            return updatedItems; // Update the state with the modified array
        });
    };
    useEffect(() => {
        if (leadObjects && leadObjects.length > 0 && selectedObjects.length > 0) {
            setTotalAmount(leadObjects
                .filter((item) => selectedObjects.map((obj) => obj.objectId).includes(item.id))
                .reduce((total, current) => { var _a; return total + ((_a = current.updatedPrice) !== null && _a !== void 0 ? _a : current.price); }, 0));
        }
        else {
            setTotalAmount(0);
        }
    }, [leadObjects, selectedObjects]);
    const handleCheckboxChange = (event) => {
        const { checked } = event.target;
        setIsContractContinous(checked);
    };
    const handleSeprateContractCheckboxChange = (event) => {
        const { checked } = event.target;
        setIsSeprateContract(checked);
    };
    return (_jsxs(CustomModal, Object.assign({ open: open, onClose: onCloseModal, size: "lg", saving: saving, handleSaveButtonClick: handleSaveButtonClick, heading: translate("SendQuote"), saveButtonText: translate("SendQuote"), disabled: !(selectedObjects.length > 0) || saving, warningMessage: (leadObjects === null || leadObjects === void 0 ? void 0 : leadObjects.some((obj) => obj.isContracted === true))
            ? translate("Quote has been sent")
            : undefined }, { children: [_jsxs(Grid, Object.assign({ container: true, gap: 2, spacing: 0, sx: {
                    paddingTop: "16px",
                    paddingX: isMobile ? "8px" : isTablet ? "16px" : "24px",
                } }, { children: [_jsx(Autocomplete, { disablePortal: true, id: "combo-box-demo", options: contactOptions, onChange: handleAutocompleteChange, sx: {
                            width: isMobile ? "100%" : "300px",
                            display: "block !important",
                        }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Select Contact", error: !!error, helperText: error, inputRef: contactSelectRef }))) }), _jsxs(Grid, Object.assign({ container: true }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, sm: 5, md: 3, className: "checkbox-container" }, { children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: isContractContinous, onChange: handleCheckboxChange }), label: _jsx(Typography, Object.assign({ className: "checkbox-text" }, { children: "Doorlopend contract" })) }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, sm: 5, md: 3, className: "checkbox-container" }, { children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: isSeprateContract, onChange: handleSeprateContractCheckboxChange }), label: _jsx(Typography, Object.assign({ className: "checkbox-text" }, { children: translate("SeparateContract") })) }) }))] })), leadObjects === null || leadObjects === void 0 ? void 0 : leadObjects.map((leadobject, index) => (_jsx(OfferLeadObject, { leadobject: leadobject, setSelectedObjects: setSelectedObjects, selectedObjects: selectedObjects }, leadobject.id)))] })), _jsxs(Accordion, Object.assign({ sx: {
                    position: "sticky",
                    bottom: { xs: "-1px", sm: "-1px" },
                    borderBottomLeftRadius: "0 !important",
                    borderBottomRightRadius: "0 !important",
                    padding: { xs: "4px 3px", md: "16px 12px" },
                    width: "100%",
                } }, { children: [_jsx(AccordionSummary, Object.assign({ expandIcon: _jsx(CaretDown, { size: 24 }), "aria-controls": "panel2-content", id: "panel2-header" }, { children: _jsxs(Grid, Object.assign({ container: true, justifyContent: "space-between" }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ variant: "h6", sx: { fontWeight: "500 !important" } }, { children: translate("TotalAmount") })) })), _jsx(Grid, Object.assign({ item: true, paddingRight: "15px" }, { children: _jsx(Box, Object.assign({ sx: {
                                            display: "flex",
                                            borderRadius: "var(--1, 8px)",
                                            width: "fit-content",
                                            background: "rgba(42, 162, 65, 0.10)",
                                            padding: "var(--borderRadius, 4px) var(--2, 16px)",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        } }, { children: _jsx(Typography, Object.assign({ sx: {
                                                color: "var(--Status-Success, #2AA241)",
                                                fontSize: "16px",
                                                fontStyle: "normal",
                                                fontWeight: 500,
                                                lineHeight: "150%",
                                                letterSpacing: "1px",
                                            } }, { children: convertToNLEuroFormat(parseFloat(totalAmount.toFixed(1))) })) })) }))] })) })), _jsx(AccordionDetails, { children: _jsx(Grid, Object.assign({ container: true }, { children: _jsxs(Grid, Object.assign({ item: true, xs: 12, sx: {
                                    display: "flex",
                                    padding: "var(--none, 0px)",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    gap: { sx: 1.5, md: 3 },
                                    alignSelf: "stretch",
                                } }, { children: [leadObjects.map((leadObject, index) => {
                                        if (selectedObjects
                                            .map((obj) => obj.objectId)
                                            .includes(leadObject.id)) {
                                            return (_jsx(OfferLeadObjectPrice, { leadObject: leadObject, index: index, updatePriceOfLeadObject: updatePriceOfLeadObject }, leadObject.id));
                                        }
                                        return null;
                                    }), _jsxs(Grid, Object.assign({ container: true, item: true, xs: 12, justifyContent: "space-between", borderTop: "0.5px solid var(--n-teal-dark, #4D666E)", paddingTop: "10px" }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ variant: "h6", sx: { fontWeight: "500 !important" } }, { children: translate("TotalAmount") })) })), _jsx(Grid, Object.assign({ item: true, paddingRight: "32px" }, { children: _jsx(Typography, Object.assign({ variant: "body1", color: "var(--n-jetblack, #312F2F)", fontWeight: 500, paddingRight: "15px !important" }, { children: convertToNLEuroFormat(parseFloat(totalAmount.toFixed(1))) })) }))] }))] })) })) })] }))] })));
};
export default OfferModal;
