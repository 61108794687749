import { http_api } from "../../leadg-api";
const get = (filters) => {
    return http_api.get(`general-task/get?startDate=${filters.startDate}&endDate=${filters.endDate}`);
};
const getById = (Id) => {
    return http_api.get(`general-task/get-by-Id?Id=${Id}`);
};
const add = (taskObject) => {
    return http_api.post(`general-task/add`, taskObject);
};
const update = (taskObject) => {
    return http_api.put(`general-task/update`, taskObject);
};
const isCompleted = (Id) => {
    return http_api.patch(`general-task/updated-status`, Id);
};
const GeneralTaskApi = {
    get,
    getById,
    add,
    update,
    isCompleted
};
export default GeneralTaskApi;
