var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import UsersApi from "../../../infrastructure/api/pages/users-api";
import { TextField, Grid, FormControl, InputLabel, FormHelperText, Select, MenuItem } from "@mui/material";
import i18n from "../../../i18n";
import CustomModal from "../modal/CustomModal";
import { Controller, useForm } from "react-hook-form";
import LeadDetailApi from "../../../infrastructure/api/pages/lead-detail-api";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Calendar } from "@phosphor-icons/react";
const defaultleadTaskData = {
    id: undefined,
    leadId: "",
    description: "",
    responsiblePersonId: "",
    responsiblePersonName: "",
    responsiblePersonPhoto: "",
    deadline: new Date(),
    createdOn: new Date()
};
const LeadTaskModal = ({ data, title, closeOnClickOutside = false, leadTaskId = "", buttonText, showPopup = false, onClosePopup, onSave, translate, }) => {
    const [modalTitle, setModalTitle] = useState("");
    const [leadTaskData, setleadTaskData] = React.useState(defaultleadTaskData);
    const [buttonTextChange, setButtonTextChange] = useState("");
    const [saving, setSaving] = useState(false);
    const [users, setUsers] = useState([]);
    const { reset, handleSubmit, control, register, setValue, formState: { errors }, } = useForm();
    useEffect(() => {
        setleadTaskData(data);
    }, [data]);
    const hiddenButtonRef = useRef(null);
    const handleSaveButtonClick = () => {
        // Trigger click event on the hidden button
        if (hiddenButtonRef.current) {
            hiddenButtonRef.current.click();
        }
    };
    useEffect(() => {
        setModalTitle(title);
        setButtonTextChange(buttonText);
    }, [leadTaskId, i18n.language]);
    const getData = (filter) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            var result = yield UsersApi.getAllAdminUsers(filter);
            if (result.data) {
                setUsers(result.data);
            }
        }
        catch (error) {
            console.error("Error while fetching adminusers:", error);
        }
    });
    useEffect(() => {
        getData(false);
    }, []);
    const resetFormData = () => {
        setleadTaskData(defaultleadTaskData);
    };
    useEffect(() => {
        reset(leadTaskData);
    }, [leadTaskData]);
    const onSubmit = (leadTaskData) => {
        setSaving(true);
        if (leadTaskData.id) {
            UpdateLeadTaskAsync(leadTaskData);
        }
        else {
            createLeadTaskAsync(leadTaskData);
        }
    };
    const UpdateLeadTaskAsync = (leadTaskData) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const result = yield LeadDetailApi.updateLeadTask(leadTaskData);
            if (result.data) {
                toast.success(translate("LeadTaskUpdatedMessage"));
                closePopup();
                onSave();
                setSaving(false);
            }
        }
        catch (error) {
            console.log("Error while updating Lead Task.", error);
            setSaving(false);
        }
    });
    const createLeadTaskAsync = (leadTaskData) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            leadTaskData.id = undefined;
            const urlParams = new URLSearchParams(window.location.search);
            const leadId = urlParams.get("id");
            leadTaskData.leadId = leadId;
            var user = users.find(user => user.id === leadTaskData.responsiblePersonId);
            leadTaskData.responsiblePersonName = (user === null || user === void 0 ? void 0 : user.firstName) || (user === null || user === void 0 ? void 0 : user.lastName) ? (user === null || user === void 0 ? void 0 : user.firstName) + " " + (user === null || user === void 0 ? void 0 : user.lastName) : "Unknown User";
            const result = yield LeadDetailApi.addLeadTask(leadTaskData);
            if (result.data) {
                toast.success(translate("LeadTaskAddMessage"));
                closePopup();
                onSave();
                setSaving(false);
            }
        }
        catch (error) {
            toast.error(translate("InvalidLeadTask"));
            setSaving(false);
        }
    });
    const closePopup = () => {
        resetFormData();
        onClosePopup();
        reset();
    };
    return (_jsx(CustomModal, Object.assign({ open: showPopup, onClose: closePopup, size: "sm", saving: saving, handleSaveButtonClick: handleSaveButtonClick, heading: translate(modalTitle), saveButtonText: translate("Save"), disabled: false }, { children: _jsx(Grid, Object.assign({ container: true, spacing: 0 }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, mx: "auto", lg: 8 }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("input", Object.assign({ type: "hidden" }, register("id"))), _jsx(Controller, { name: "description", control: control, defaultValue: leadTaskData.description, rules: {
                                required: {
                                    value: true,
                                    message: translate("Description is required"),
                                }
                            }, render: ({ field }) => {
                                var _a;
                                return (_jsx(TextField, Object.assign({ label: translate("Description"), variant: "outlined", fullWidth: true, margin: "normal" }, field, { error: !!errors.description, helperText: (_a = errors.description) === null || _a === void 0 ? void 0 : _a.message })));
                            } }), _jsx(Controller, { name: "responsiblePersonId", control: control, defaultValue: leadTaskData.responsiblePersonId, rules: {
                                required: {
                                    value: true,
                                    message: translate("Responsible Person is required"),
                                },
                            }, render: ({ field }) => {
                                var _a;
                                return (_jsxs(FormControl, Object.assign({ fullWidth: true, error: !!errors.responsiblePersonId, sx: {
                                        marginTop: "16px",
                                        marginBottom: "8px"
                                    } }, { children: [_jsx(InputLabel, Object.assign({ htmlFor: "simple-select-role-option", style: { color: errors.responsiblePersonId ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)' } }, { children: translate("ResponsiblePerson") })), _jsx(Select, Object.assign({ labelId: "simple-select-responsibleperson-option", id: "simple-select-responsibleperson-option", label: translate("ResponsiblePerson") }, field, { children: users.map((user) => (_jsx(MenuItem, Object.assign({ value: user.id }, { children: user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : "Unknown User" }), user.id))) })), _jsx(FormHelperText, Object.assign({ error: true }, { children: (_a = errors.responsiblePersonId) === null || _a === void 0 ? void 0 : _a.message }))] })));
                            } }), _jsx(Controller, { name: "deadline", control: control, rules: {
                                required: {
                                    value: true,
                                    message: translate("Deadline is required"),
                                },
                                validate: {
                                    lowerLimit: (value) => {
                                        const selectedDate = new Date(value);
                                        const today = new Date();
                                        selectedDate.setHours(0, 0, 0, 0);
                                        today.setHours(0, 0, 0, 0);
                                        const isValid = selectedDate >= today;
                                        return isValid || translate("Date must be greater than or equal to today");
                                    },
                                    upperLimit: (value) => {
                                        return (new Date(value) <= new Date("2099-12-31") ||
                                            translate("Date must be less than or equal to 2099"));
                                    },
                                },
                            }, render: ({ field }) => {
                                var _a;
                                return (_jsxs(FormControl, Object.assign({ fullWidth: true, variant: "outlined", sx: {
                                        marginTop: "16px",
                                        marginBottom: "8px"
                                    } }, { children: [_jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterDayjs }, { children: _jsx(DatePicker, { value: leadTaskData.deadline && dayjs(leadTaskData.deadline), views: ["day", "month", "year"], label: translate("Deadline"), slots: {
                                                    openPickerIcon: Calendar,
                                                }, onChange: (date) => {
                                                    const formattedDate = date && dayjs(date).format("YYYY-MM-DD");
                                                    field.onChange(formattedDate);
                                                }, sx: { width: "100%" }, format: "DD,MMM, YYYY" }) })), _jsx(FormHelperText, Object.assign({ error: true }, { children: (_a = errors.deadline) === null || _a === void 0 ? void 0 : _a.message }))] })));
                            } }), _jsx("button", Object.assign({ ref: hiddenButtonRef, type: "submit", hidden: true }, { children: "Submit" }))] })) })) })) })));
};
export default LeadTaskModal;
