import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import LeadObject from "../../components/LeadDetail/LeadObject/LeadObject";
import { useEffect, useState } from "react";
import LeadsObjectApi from "../../../infrastructure/api/pages/lead-object-api";
import { Plus } from "@phosphor-icons/react";
import ObjectModal from "./ObjectModal";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { UserRolesPermissionsContext } from "../../../context/UserRolesPermissionsContext";
import { checkCreateEdit, } from "../../../application/common/utils/roles-helper";
import { Modules } from "../../../application/common/enums/screen-name";
const initialFormData = {
    id: null,
    leadId: "",
    companyRoleId: "",
    street: "",
    name: "",
    houseNumber: "",
    addition: "",
    postalCode: "",
    city: "",
    totalRoofSurfaces: 0,
    price: 0,
    area: 0,
    roofAccess: null,
    registrationProcedure: null,
    registrationTime: 0,
    preferredMonth: undefined,
    image: undefined,
    isDeleted: false,
    openingEndHoursString: "17:00",
    openingStartHoursString: "09:00",
    locationId: "",
    location: null,
    longitude: 0,
    latitude: 0,
    pandId: "",
    installationDate: undefined,
    isSpecial: false,
    projectNumber: "",
    externalComments: "",
    internalComments: "",
};
const ObjectTab = ({ setRefreshModalData, refreshObjects, setActionTabRefresh, }) => {
    const [leadObject, setLeadobject] = useState(initialFormData);
    const [refresh, setRefresh] = useState(true);
    const [leadObjects, setleadObjects] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t: translate } = useTranslation();
    const urlParams = new URLSearchParams(window.location.search);
    const leadId = urlParams.get("id");
    const { RolesPermissions } = useContext(UserRolesPermissionsContext);
    const [assetOptions, setAssetOptions] = useState([]);
    const handleOpenModal = () => {
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        setModalOpen(false);
        setLeadobject(initialFormData);
    };
    const deleteObject = (id) => {
        LeadsObjectApi.deleteLeadObject(id).then((response) => {
            if (response.status === 200) {
                toast.success(translate("DeletedSuccessfully"));
                setRefresh((prev) => !prev);
                setRefreshModalData((prev) => !prev);
                setActionTabRefresh((prev) => !prev);
            }
        });
    };
    useEffect(() => {
        LeadsObjectApi.getSelectionListSystems()
            .then((response) => {
            if (response.status === 200 && response.data != null) {
                setAssetOptions(response.data);
            }
        })
            .finally(() => { });
    }, []);
    useEffect(() => {
        if (leadId && assetOptions.length > 0) {
            setLoading(true);
            LeadsObjectApi.getLeadObjectsByid(leadId)
                .then((response) => {
                if (response.data && response.status === 200) {
                    const LeadObjectList = response.data;
                    LeadObjectList.forEach((leadobject) => {
                        leadobject.selectionListSystems =
                            leadobject.selectionListSystemsId &&
                                leadobject.selectionListSystemsId.map((id) => {
                                    var _a;
                                    return ({
                                        value: id,
                                        text: (_a = assetOptions.find((option) => option.value === id)) === null || _a === void 0 ? void 0 : _a.text,
                                    });
                                });
                    });
                    setleadObjects(response.data);
                    setRefreshModalData((prev) => !prev);
                }
            })
                .finally(() => setLoading(false));
        }
    }, [refresh, refreshObjects, assetOptions]);
    const selectedObject = () => {
        setLeadobject(leadObject);
        handleOpenModal();
    };
    useEffect(() => {
        if (leadObject.id)
            selectedObject();
    }, [leadObject]);
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ padding: { xs: "4px 8px", sm: "8px 16px", md: "16px 32px" } }, { children: _jsxs(Grid, Object.assign({ container: true, flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 3 }, { children: [_jsx(Typography, Object.assign({ variant: "h2" }, { children: translate("Object") })), checkCreateEdit(RolesPermissions, Modules.Leads) && (_jsx(Button, Object.assign({ type: "submit", variant: "outlined", sx: {
                                borderRadius: "12px",
                                border: "var(--none, 1px) solid var(--n-primary, #130922)",
                                background: "var(--n-primary-light, #F9F6FF)",
                                display: "flex",
                                padding: "12px !important",
                                alignItems: "flex-start",
                                gap: "10px",
                                minWidth: "auto !important",
                            }, onClick: handleOpenModal }, { children: _jsx(Plus, { size: 24 }) }))), loading ? (_jsx(Skeleton, { sx: { borderRadius: 3 }, variant: "rectangular", width: "100%", height: 430 })) : (leadObjects === null || leadObjects === void 0 ? void 0 : leadObjects.map((leadobject, index) => (_jsx(LeadObject, { serialNumber: index + 1, setSelectedObject: setLeadobject, leadobject: leadobject, assetsData: assetOptions, onDelete: deleteObject }, `LeadObject${index}`))))] })) })), _jsx(ObjectModal, { open: modalOpen, onClose: handleCloseModal, refresh: () => setRefresh((prev) => !prev), initialFormData: initialFormData, data: leadObject, assetsData: assetOptions, setData: setLeadobject, setActionTabRefresh: setActionTabRefresh })] }));
};
export default ObjectTab;
