var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { Box, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography, CircularProgress, useTheme, } from "@mui/material";
import { StyledTableCell, StyledTableRow, TableRowsLoader, } from "../../components/mui-table/mui-table-helper";
import { Pencil, Trash, Keyhole } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import RoleFormSubmit from "./RoleFormSubmit";
import RolesApi from "../../../infrastructure/api/pages/roles-api";
import { Layout } from "../../components/Layout/Layout";
import RolePermissionModal from "./RolePermissionModal";
import { useTranslation } from "react-i18next";
import TopHeader from "./TopHeader";
import { UserRolesPermissionsContext } from "../../../context/UserRolesPermissionsContext";
import { checkCreateEdit, checkDelete, checkManage, checkRead, } from "../../../application/common/utils/roles-helper";
import { Modules } from "../../../application/common/enums/screen-name";
const initialFormData = {
    id: "",
    name: "",
};
export const AllRole = () => {
    const theme = useTheme();
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState(initialFormData);
    const [refereshGrid, setRefereshGrid] = useState(false);
    const [showingPopup, setShowingPopup] = useState(false);
    const [idToBeShowingPopup, setIdToBeShowingPopup] = useState();
    const [showingRolePopup, setShowingRolePopup] = useState(false);
    const [idToBeShowingRolePopup, setIdToBeShowingRolePopup] = useState();
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [idToBeDeleted, setIdToBeDeleted] = useState();
    const [rolePermissionModalOpen, setRolePermissionModalOpen] = useState(false);
    const [roleModalOpen, setRoleModalOpen] = useState(false);
    const { t: translate } = useTranslation();
    const { RolesPermissions } = useContext(UserRolesPermissionsContext);
    const [modalTitle, setModalTitle] = useState("");
    useEffect(() => {
        setLoading(true);
        RolesApi.getAllRole()
            .then((response) => {
            if (response.status === 200 && response.data != null) {
                setRoles(response.data);
            }
        })
            .finally(() => {
            setTimeout(() => setLoading(false), 400);
        });
    }, [refereshGrid]);
    const handleDeleteRole = (id) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIdToBeDeleted(id);
            setDeleting(true);
            var result = yield RolesApi.deleteRoleById(id);
            if (result.data) {
                toast.info(translate("RoleDeletedSuccessfully"));
                setRefereshGrid(!refereshGrid);
                setDeleting(false);
                setIdToBeDeleted(undefined);
            }
        }
        catch (error) {
            toast.error(translate("IsRoleAlreadyAssign"));
            setDeleting(false);
            setIdToBeDeleted(undefined);
        }
    });
    const handleEditRolePermission = (id) => {
        if (id) {
            setIdToBeShowingRolePopup(id);
            setShowingRolePopup(true);
            RolesApi.getRoleById(id)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    setRole(response.data);
                    setShowingRolePopup(false);
                    setIdToBeShowingRolePopup(undefined);
                    handleOpenRolePermissionModal();
                }
            })
                .finally(() => {
                setShowingRolePopup(false);
                setIdToBeShowingRolePopup(undefined);
            });
        }
    };
    const handleNewRole = () => {
        setRole(initialFormData);
        setModalTitle("NewRole");
        handleOpenRoleModal();
    };
    const handleEditRole = (id) => {
        if (id) {
            setRole(initialFormData);
            setIdToBeShowingPopup(id);
            setShowingPopup(true);
            RolesApi.getRoleById(id)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    setRole(response.data);
                    setModalTitle("UpdateRole");
                    setShowingPopup(false);
                    setIdToBeShowingPopup(undefined);
                    handleOpenRoleModal();
                }
            })
                .finally(() => {
                setShowingRolePopup(false);
                setIdToBeShowingPopup(undefined);
            });
        }
    };
    const handleOpenRolePermissionModal = () => {
        setRolePermissionModalOpen(true);
    };
    const handleOpenRoleModal = () => {
        setRoleModalOpen(true);
    };
    const handleCloseRoleModal = () => {
        setRoleModalOpen(false);
    };
    const columns = [
        { id: "id", label: "Id", minWidth: 100, align: "left" },
        { id: "name", label: translate("Name"), minWidth: 100, align: "left" },
    ];
    (checkCreateEdit(RolesPermissions, Modules.RoleManagement) ||
        checkManage(RolesPermissions, Modules.RoleManagement)) &&
        columns.push({
            id: "action",
            label: translate("Action"),
            minWidth: 100,
            align: "left",
        });
    checkRead(RolesPermissions, Modules.RoleManagement) &&
        columns.push({
            id: "rolePermissions",
            label: translate("RolePermissions"),
            minWidth: 100,
            align: "left",
        });
    return (_jsx(_Fragment, { children: _jsxs(Layout, { children: [_jsx(TopHeader, { openPopup: handleNewRole, translate: translate }), _jsx(Box, Object.assign({ sx: {
                        marginTop: "1%",
                        padding: { xs: "16px 16px", sm: "16px 32px" },
                        bgcolor: theme.palette.common.white,
                        borderRadius: "24px",
                    } }, { children: _jsx(Paper, Object.assign({ sx: { width: "100%", overflow: "hidden", boxShadow: "none" } }, { children: _jsx(TableContainer, { children: _jsxs(Table, Object.assign({ stickyHeader: true, "aria-label": "sticky table" }, { children: [_jsx(TableHead, { children: _jsx(TableRow, Object.assign({ sx: { backgroundColor: theme.palette.common.white } }, { children: columns.map((column) => (_jsx(StyledTableCell, Object.assign({ align: column.align, style: { minWidth: column.minWidth } }, { children: _jsx(Typography, Object.assign({ variant: "h6", color: theme.palette.text.primary, sx: {
                                                        fontSize: {
                                                            xs: "0.875rem",
                                                            sm: "1rem",
                                                            md: "1.25rem",
                                                        },
                                                    } }, { children: translate(`${column.label}`) })) }), column.id))) })) }), _jsx(TableBody, { children: loading ? (_jsx(TableRowsLoader, { rowsNum: 10, cellNum: 4 })) : (roles.map((role, index) => (_jsxs(StyledTableRow, { children: [_jsx(StyledTableCell, Object.assign({ align: "left" }, { children: _jsx(Typography, Object.assign({ variant: "body2" }, { children: index + 1 })) })), _jsx(StyledTableCell, Object.assign({ align: "left" }, { children: _jsx(Typography, Object.assign({ variant: "body2" }, { children: role.name })) })), checkCreateEdit(RolesPermissions, Modules.RoleManagement) && (_jsx(StyledTableCell, Object.assign({ align: "left" }, { children: _jsxs(Grid, Object.assign({ container: true, alignContent: "left", justifyContent: "left", gap: { xs: 3, sm: 1 } }, { children: [checkCreateEdit(RolesPermissions, Modules.RoleManagement) && (_jsx(Grid, Object.assign({ item: true, xs: 3 }, { children: _jsx(Box, Object.assign({ sx: {
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        bgcolor: "#F4FCFF",
                                                                        borderRadius: "50%",
                                                                        width: "36px",
                                                                        height: "36px",
                                                                    } }, { children: showingPopup &&
                                                                        idToBeShowingPopup == role.id ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : (_jsx(Pencil, { onClick: () => {
                                                                            handleEditRole(role.id);
                                                                        } })) })) }))), checkDelete(RolesPermissions, Modules.RoleManagement) && (_jsx(Grid, Object.assign({ item: true, xs: 3 }, { children: _jsx(Box, Object.assign({ sx: {
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        bgcolor: "#F4FCFF",
                                                                        borderRadius: "50%",
                                                                        width: "36px",
                                                                        height: "36px",
                                                                    } }, { children: deleting && idToBeDeleted == role.id ? (_jsx(CircularProgress, { size: 24, color: "inherit" })) : (_jsx(Trash, { onClick: () => handleDeleteRole(role.id) })) })) })))] })) }))), _jsx(StyledTableCell, Object.assign({ align: "left" }, { children: _jsx(Grid, Object.assign({ container: true, alignContent: "left", justifyContent: "left", spacing: 1 }, { children: checkRead(RolesPermissions, Modules.RoleManagement) && (_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Box, Object.assign({ sx: {
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    bgcolor: "#F4FCFF",
                                                                    borderRadius: "50%",
                                                                    width: "36px",
                                                                    height: "36px",
                                                                } }, { children: showingRolePopup &&
                                                                    idToBeShowingRolePopup == role.id ? (_jsx(CircularProgress, { size: 18, color: "inherit" })) : (_jsx(Keyhole, { size: 24, onClick: () => handleEditRolePermission(role.id) })) })) }))) })) }))] }, role.id)))) })] })) }) })) })), _jsx(RolePermissionModal, { title: `${translate("RolePermissions")}`, roleId: role.id, showPopup: rolePermissionModalOpen, onClosePopup: () => {
                        setRolePermissionModalOpen(false);
                        setRole(initialFormData);
                    }, translate: translate }), _jsx(RoleFormSubmit, { open: roleModalOpen, onClose: handleCloseRoleModal, GetRoles: () => setRefereshGrid(!refereshGrid), initialFormData: initialFormData, data: role, title: translate(modalTitle) })] }) }));
};
export default AllRole;
