import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Box, Button, CircularProgress, Container, Typography, useTheme, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Download, NotePencil } from "@phosphor-icons/react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import WorkPreparationContracts from "../../../infrastructure/api/pages/work-preparation-contracts-api";
export const WorkPreparationBreadCrumb = ({ orderNumber, setCompleted, loader, copied, }) => {
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const contractId = urlParams.get("id");
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const completeContract = () => {
        setCompleted(true);
    };
    const downloadDocument = () => {
        debugger;
        if (contractId) {
            WorkPreparationContracts.downloadExcelDocument(contractId)
                .then((response) => {
                if (response.status === 200 && response.data != null) {
                    debugger;
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Kopie van Inschrijfformulier nieuwe klant v2024 EUROSAFE.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            })
                .catch((error) => {
                console.error("Er is een fout opgetreden bij het downloaden van het document!", error);
            });
        }
    };
    return (_jsx(Container, Object.assign({ disableGutters: true, sx: { maxWidth: "100% !important" } }, { children: _jsxs(Box, Object.assign({ sx: {
                display: "flex",
                spacing: 1,
                justifyContent: "space-between",
                flexDirection: { xs: "column", sm: "row", md: "row" },
            } }, { children: [_jsxs(Box, { children: [_jsx(Typography, Object.assign({ variant: "h1", color: theme.palette.text.primary }, { children: translate("WorkPreparation") })), _jsx(Box, Object.assign({ display: "flex" }, { children: _jsxs(Breadcrumbs, Object.assign({ "aria-label": "breadcrumb" }, { children: [_jsxs(Link, Object.assign({ underline: "hover", color: "inherit", href: "/workpreparation" }, { children: [_jsx(NotePencil, {}), translate("ContractList")] })), _jsxs(Link, Object.assign({ underline: "hover", color: "inherit" }, { children: [translate("Order"), " #", orderNumber] }))] })) }))] }), _jsxs(Box, Object.assign({ sx: { display: "flex", justifyContent: "flex-end" } }, { children: [_jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { marginRight: "5px" }, startIcon: _jsx(Download, { size: 16 }), onClick: downloadDocument }, { children: translate("Download document") })), _jsx(Button, Object.assign({ variant: "contained", size: "medium", sx: { marginRight: "5px" }, onClick: handleOpen, disabled: copied }, { children: translate("Complete") }))] })), _jsxs(Dialog, Object.assign({ open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsx(DialogContent, { children: _jsx(DialogContentText, Object.assign({ id: "alert-dialog-description" }, { children: translate("Are you sure you have copied the details?") })) }), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ onClick: handleClose, variant: "outlined", color: "error", size: "small" }, { children: "Nee" })), _jsxs(Button, Object.assign({ onClick: completeContract, variant: "contained", color: "success", size: "small", autoFocus: true }, { children: [loader ? _jsx(CircularProgress, { size: 20, color: "inherit" }) : _jsx(_Fragment, {}), "Ja"] }))] })] }))] })) })));
};
