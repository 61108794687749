import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
const LoadingScreen = () => {
    useEffect(() => {
        setTimeout(() => {
            console.log("Hello, World!");
        }, 6000);
    }, []);
    return (_jsx(_Fragment, {}));
};
export default LoadingScreen;
