import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, } from "@mui/material";
import { CaretDown, MapPin } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";
import { convertToNLEuroFormat } from "../../../application/common/utils/helper";
const ContractObjectAccordion = ({ contractedObject, }) => {
    const { t: translate } = useTranslation();
    return (_jsxs(Accordion, Object.assign({ sx: {
            height: "fit-content",
            background: "#F9F6FF",
            borderRadius: "12px",
            boxShadow: "0px 7px 15px 0px rgba(0, 0, 0, 0.05), 0px 28px 28px 0px rgba(0, 0, 0, 0.04), 0px 63px 38px 0px rgba(0, 0, 0, 0.03), 0px 112px 45px 0px rgba(0, 0, 0, 0.01), 0px 175px 49px 0px rgba(0, 0, 0, 0.00)",
        } }, { children: [_jsx(AccordionSummary, Object.assign({ sx: {
                    borderRadius: "12px",
                    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.12)",
                }, "aria-controls": "panel1d-content", id: "panel1d-header", expandIcon: _jsx(CaretDown, { size: 24 }) }, { children: _jsxs(Grid, Object.assign({ container: true, gap: { xs: 1, md: 3, lg: 4 }, alignItems: "center", padding: { xs: "0", sm: "8px 24px" }, justifyContent: "space-between" }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Box, { component: "img", src: contractedObject.image, sx: { width: "100px", height: "auto" } }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(MapPin, { size: 24 }) })), _jsx(Grid, Object.assign({ container: true, item: true, padding: 2, alignItems: "center", flex: "1 0 0", p: 0 }, { children: _jsxs(Grid, Object.assign({ container: true, item: true, justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ className: "addresstext" }, { children: `${contractedObject.street} ${contractedObject.houseNumber == null
                                            ? ""
                                            : contractedObject.houseNumber} ${contractedObject.addition == null
                                            ? ""
                                            : contractedObject.addition} ${contractedObject.postalCode} ${contractedObject.city}` })), _jsx(Box, Object.assign({ className: "pricecontainer", sx: { display: "flex", justifyContent: "flex-end" } }, { children: _jsx(Typography, Object.assign({ className: "pricetext" }, { children: convertToNLEuroFormat(contractedObject.price) })) }))] })) }))] })) })), _jsx(AccordionDetails, { children: _jsxs(Grid, Object.assign({ container: true, gap: 1 }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, padding: { xs: "0", sm: "0 64px" }, justifyContent: "space-between", alignItems: "flex-start" }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsxs(Typography, Object.assign({ color: "#495057" }, { children: [translate("Number of roof surfaces"), ":", " ", contractedObject.totalRoofSurfaces] })) })), _jsx(Grid, { item: true })] })), _jsxs(Grid, Object.assign({ container: true, item: true, padding: { xs: "0", sm: "0 64px" }, justifyContent: "space-between", alignItems: "flex-start" }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsxs(Typography, Object.assign({ color: "#495057" }, { children: [translate("Surface"), ": ", contractedObject.area] })) })), _jsx(Grid, { item: true })] })), _jsxs(Grid, Object.assign({ container: true, item: true, className: "expectedmonthcontainer" }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsxs(Typography, Object.assign({ color: "#495057" }, { children: [translate("Expected Inspection Month"), ":"] })) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ color: "#495057" }, { children: contractedObject.preferredMonth != null
                                            ? new Date(contractedObject.preferredMonth).toLocaleString("en-US", { month: "long", year: "numeric" })
                                            : "vrij te plannen" })) }))] })), _jsxs(Grid, Object.assign({ container: true, item: true, className: "expectedmonthcontainer" }, { children: [_jsxs(Typography, Object.assign({ color: "#495057" }, { children: ["Opmerking voor dit object", ":"] })), _jsx(Typography, Object.assign({ color: "#495057" }, { children: contractedObject.externalComments }))] }))] })) })] })));
};
export default ContractObjectAccordion;
