import { http_api } from "../../leadg-api";
const AddObjectContact = (addcontactobject) => {
    return http_api.post('contact-object/add', addcontactobject);
};
const GetObjectContactByContractId = (contractId) => {
    return http_api.get('contact-object/get-by-contact-id/' + contractId);
};
const UpdateObjectContact = (updatecontactObject) => {
    return http_api.put('contact-object/update', updatecontactObject);
};
const DeleteContactObject = (id) => {
    return http_api.delete('contact-object/delete/' + id);
};
const LogUserPortalView = (contractId) => {
    return http_api.get('contact-object/log-user-portal-view/' + contractId);
};
const ContactObjectApi = {
    AddObjectContact,
    GetObjectContactByContractId,
    UpdateObjectContact,
    DeleteContactObject,
    LogUserPortalView
};
export default ContactObjectApi;
