import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Container, FormControl, Switch, TextField, Typography, } from "@mui/material";
import { useState } from "react";
const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));
export const WorkPreparationTopHeader = ({ setSearch, search, setCompleted, completed }) => {
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const [searchInput, setSearchInput] = useState(search);
    const [completedInput, setCompletedInput] = useState(completed);
    const handleSearch = (e) => {
        if (e.key === "Enter") {
            setSearch(searchInput);
        }
    };
    const handleToggle = () => {
        setCompletedInput(!completedInput);
        setCompleted(!completedInput);
        console.log('Toggle state:', !completedInput);
    };
    return (_jsx(Container, Object.assign({ disableGutters: true, sx: { maxWidth: "100% !important" } }, { children: _jsxs(Box, { children: [_jsx(Box, Object.assign({ sx: { padding: "24px 0px" } }, { children: _jsx(Typography, Object.assign({ variant: "h1", color: theme.palette.text.primary }, { children: translate("WorkPreparation") })) })), _jsxs(Box, Object.assign({ sx: {
                        display: 'flex',
                        gap: 2,
                        padding: "16px 32px",
                        bgcolor: theme.palette.common.white,
                        borderRadius: "24px",
                        alignItems: "center"
                    } }, { children: [_jsx(FormControl, Object.assign({ sx: { width: "50%" }, variant: "outlined", size: "medium" }, { children: _jsx(TextField, Object.assign({ id: "email", label: "Zoeken", value: searchInput, onChange: (e) => setSearchInput(e.target.value), onKeyDown: (e) => handleSearch(e), variant: "outlined", placeholder: "CompanyName , ContractName , etc...", size: "medium" }, { children: _jsx(SearchIconWrapper, { children: _jsx(SearchIcon, {}) }) })) })), _jsx(Switch, { checked: completedInput, onChange: handleToggle, inputProps: { "aria-label": "controlled" } }), _jsx(Typography, Object.assign({ variant: "h6", color: theme.palette.text.primary }, { children: completedInput ? translate("Completed") : translate("Not Completed") }))] }))] }) })));
};
