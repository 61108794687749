var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ChatCenteredText, Notepad, HourglassLow, } from "@phosphor-icons/react";
import { useState } from "react";
import ChatSection from "../../components/LeadDetail/ChatSection/ChatSection";
import AuditSection from "../../components/LeadDetail/AuditSection/AuditSection";
import { useTranslation } from "react-i18next";
import TaskList from "../../components/LeadDetail/TaskListSection/TaskList";
function CustomTabPanel(props) {
    const { children, value, index, maxHeight } = props, other = __rest(props, ["children", "value", "index", "maxHeight"]);
    return (_jsx(Box, Object.assign({ role: "tabpanel", hidden: value !== index, id: `simple-tabpanel-${index}`, "aria-labelledby": `simple-tab-${index}` }, other, { style: {
            display: value === index ? "block" : "none",
            maxHeight: maxHeight,
            overflowY: "auto", // Add overflow-y style for scroll
        } }, { children: children })));
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const AuditSectionTabs = ({ refresh }) => {
    const [value, setValue] = useState(0);
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const isHidden = useMediaQuery(theme.breakpoints.between(1200, 1500));
    const isHiidenForSmallScreen = useMediaQuery(theme.breakpoints.between(0, 500));
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Tabs, Object.assign({ className: "tab", value: value, onChange: handleChange, TabIndicatorProps: {
                    sx: {
                        display: "none",
                    },
                } }, { children: [_jsx(Tab, Object.assign({ label: _jsxs(_Fragment, { children: [_jsx(ChatCenteredText, { size: 20 }), (!isHidden && !isHiidenForSmallScreen) && _jsx(Typography, Object.assign({ className: "tab-text" }, { children: "Opmerkingen" }))] }) }, a11yProps(1))), _jsx(Tab, Object.assign({ label: _jsxs(_Fragment, { children: [_jsx(HourglassLow, { size: 20 }), (!isHidden && !isHiidenForSmallScreen) && _jsx(Typography, Object.assign({ className: "tab-text" }, { children: "Historie" }))] }) }, a11yProps(2))), _jsx(Tab, Object.assign({ label: _jsxs(_Fragment, { children: [_jsx(Notepad, { size: 20 }), (!isHidden && !isHiidenForSmallScreen) && _jsx(Typography, Object.assign({ className: "tab-text" }, { children: translate("TaskList") }))] }) }, a11yProps(2)))] })), _jsx(CustomTabPanel, Object.assign({ value: value, index: 0, maxHeight: "400px" }, { children: _jsx(ChatSection, {}) })), _jsx(CustomTabPanel, Object.assign({ value: value, index: 1, maxHeight: "400px" }, { children: _jsx(AuditSection, { refresh: refresh }) })), _jsx(CustomTabPanel, Object.assign({ value: value, index: 2, maxHeight: "400px" }, { children: _jsx(TaskList, { refresh: refresh }) }))] }));
};
export default AuditSectionTabs;
