import { http_api } from "../../leadg-api";
const getDashboardData = (filters, userId) => {
    return http_api.get(`dashboard/get-dashboard-data?startDate=${filters.startDate}&endDate=${filters.endDate}&UserId=${userId}`);
};
const getTaskData = (filters) => {
    return http_api.get(`dashboard/get-task-data?startDate=${filters.startDate}&endDate=${filters.endDate}`);
};
const DashboardApi = {
    getDashboardData,
    getTaskData
};
export default DashboardApi;
