import { http_api } from "../../leadg-api";
const getAllContracts = (isCompleted, search) => {
    if (search)
        return http_api.get(`contract/get-contracts-work-preparation?isCompleted=${isCompleted}&search=${search}`);
    else
        return http_api.get(`contract/get-contracts-work-preparation?isCompleted=${isCompleted}`);
};
const getWorkPreparationDetails = (contractId) => {
    return http_api.get(`contract/get-work-preparation-details?contractId=${contractId}`);
};
const downloadDocument = (Id) => {
    return http_api.get(`contract/download-document?Id=${Id}`, {
        responseType: 'blob',
    });
};
const downloadExcelDocument = (Id) => {
    return http_api.get(`contract/download-excel-document-work-preparation?Id=${Id}`, {
        responseType: 'blob',
    });
};
const WorkPreparationContracts = {
    getAllContracts,
    getWorkPreparationDetails,
    downloadDocument,
    downloadExcelDocument
};
export default WorkPreparationContracts;
