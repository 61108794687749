export const msalConfig = {
    auth: {
        clientId: process.env.MSAL_CLIENT_ID,
        authority: "https://login.microsoftonline.com/" + process.env.MSAL_TENANT_ID,
        redirectUri: "/",
        postLogoutRedirectUri: "/",
    },
    system: {
        allowNativeBroker: false,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
};
export const loginRequest = {
    scopes: ["User.Read"],
};
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
