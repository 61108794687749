var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthApi from "../../../infrastructure/api/auth/auth-api";
import useAsyncEffect from "use-async-effect";
const ConfirmEmail = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [apistatus, setApiStatus] = useState();
    const confirmationToken = searchParams.get("token");
    const confirmationEmail = searchParams.get("email");
    useAsyncEffect(() => __awaiter(void 0, void 0, void 0, function* () {
        if (confirmationToken && confirmationEmail) {
            const confirmation = {
                email: confirmationEmail,
                token: confirmationToken.replaceAll(" ", "+"),
            };
            const response = yield AuthApi.confirmEmail(confirmation);
            setApiStatus(response.status);
        }
    }), [confirmationEmail, confirmationToken]);
    useEffect(() => {
        if (apistatus === 200) {
            navigate("/");
        }
    }, [apistatus, navigate]);
    return _jsx(_Fragment, {});
};
export default ConfirmEmail;
