import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { ClientLayout } from "../../components/Layout/ClientLayout/ClientLayout";
import "./RoofAccess.scss";
import { CaretRight, Plus } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RoofAccessContactCard from "../../components/RoofAccess/RoofAccessContactCard";
import RoofAccessSideBar from "../../components/RoofAccess/RoofAccessSideBar";
import ContactObjectModal from "../../components/RoofAccess/ContactObjectModal/ContactObjectModal";
import { useEffect, useState } from "react";
import ContractedObjectApi from "../../../infrastructure/api/pages/contracted-object";
import ContactObjectApi from "../../../infrastructure/api/pages/contact-object-api";
const emptyData = {
    id: null,
    name: null,
    contractObjectIds: null,
    email: null,
    phoneNumber: null,
    sameAsBillingAddress: false,
    instructions: null,
    isDeleted: false,
    contractObjectIdArray: [],
};
const RoofAccess = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const theme = useTheme();
    const contractId = urlParams.get("contractId");
    const { t: translate } = useTranslation();
    const [objects, setObjects] = useState([]);
    const [filteredObjects, setFilteredObjects] = useState([]);
    const [existingContact, setexsistingContact] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [selectedContact, setSelectedContact] = useState(emptyData);
    useEffect(() => {
        if (contractId) {
            ContactObjectApi.LogUserPortalView(contractId);
        }
    }, []);
    useEffect(() => {
        if (contractId && refresh) {
            setLoading(true);
            ContactObjectApi.GetObjectContactByContractId(contractId).then((response) => {
                if (response.status === 200 && response.data) {
                    setexsistingContact(response.data);
                }
            });
            ContractedObjectApi.GetContractedObjectDropdown(contractId).then((response) => {
                if (response.status === 200 && response.data) {
                    setObjects(response.data);
                    setLoading(false);
                    setRefresh(false);
                }
            });
            setFilteredObjects(objects.filter((object) => existingContact.some((contact) => contact.contractObjectIds === object.id)));
        }
    }, [contractId, refresh]);
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedContact(emptyData);
    };
    return (_jsxs(ClientLayout, Object.assign({ page: "roof-access" }, { children: [_jsxs(Grid, Object.assign({ container: true }, { children: [_jsxs(Grid, Object.assign({ item: true, className: "roofaccess-body", spacing: 2, display: "flex", gap: 2, flexDirection: { xs: "column", sm: "column", md: "row" } }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 8, lg: 8, p: 0 }, { children: [_jsxs(Box, { children: [_jsxs(Box, Object.assign({ sx: { display: "flex", flexDirection: "column", gap: "8px" } }, { children: [_jsx(Typography, Object.assign({ className: "topheading" }, { children: translate("Application") })), _jsx(Typography, Object.assign({ color: "#6C757D", variant: "body2" }, { children: translate("EnterInformationRoofAccess") }))] })), _jsx(Box, Object.assign({ sx: {
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    width: "100%",
                                                    paddingBottom: 2,
                                                    paddingTop: 2,
                                                } }, { children: _jsx(Button, Object.assign({ variant: "outlined", size: "medium", onClick: handleOpenModal, startIcon: _jsx(Plus, {}) }, { children: translate("AddContact") })) }))] }), _jsx(Box, { children: _jsx(Box, Object.assign({ className: "roofaccess-body-card-container" }, { children: _jsx(Box, Object.assign({ gap: { xs: 1.5, md: 1, lg: 1 }, sx: {
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flexWrap: "wrap",
                                                } }, { children: existingContact.map((contactobject) => (_jsx(RoofAccessContactCard, { object: objects
                                                        .filter((x) => contactobject.contractObjectIdArray.includes(x.id))
                                                        .map((obj) => obj.name), contact: contactobject, onclickCard: setSelectedContact, openModal: handleOpenModal, refresh: setRefresh }))) })) })) })] })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 4, lg: 4 }, { children: _jsx(RoofAccessSideBar, {}) }))] })), _jsxs(Box, Object.assign({ className: "roof-access-footer" }, { children: [existingContact.length < 1 && (_jsx(Box, Object.assign({ display: "flex", alignItems: "flex-start" }, { children: _jsx(Typography, Object.assign({ color: theme.palette.text.secondary }, { children: translate("ContactValidation") })) }))), _jsx(Box, Object.assign({ className: "button-container" }, { children: _jsxs(Button, Object.assign({ variant: "contained", color: "primary", disabled: loading || existingContact.length < 1, onClick: () => navigate(`/billing-address?contractId=${contractId}`), autoFocus: true }, { children: [_jsx(Typography, Object.assign({ fontWeight: 700 }, { children: "Volgende" })), _jsx(CaretRight, { size: 24 })] })) }))] }))] })), _jsx(ContactObjectModal, { contractId: contractId, open: openModal, onClose: handleCloseModal, initialFormData: emptyData, data: selectedContact, option: objects, refresh: setRefresh })] })));
};
export default RoofAccess;
