import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout } from "../../components/Layout/Layout";
import { useContext, useEffect, useState } from "react";
import DashboardTopHeader from "./DashboardTopHeader";
import { Grid } from "@mui/material";
import { DashboardBoardCard } from "../../components/dashboard/card/DashboardCard";
import { ArrowDown, ArrowUp, Handshake, Money, Newspaper, UsersThree, } from "@phosphor-icons/react";
import LineChartCard from "../../components/dashboard/linechart/LineChartCard";
import { LeadsTakenTable } from "../../components/dashboard/leadTakenTable/LeadsTakenTable";
import { RecentOfferTable } from "../../components/dashboard/recentOfferTable/RecentOfferTable";
import { defaultFilter, dummyDashboard, dummyDashboardTasks, } from "../../../application/pages/models/dashboard-dto";
import DashboardApi from "../../../infrastructure/api/pages/dashboard-api";
import { AuthenticationContext } from "../../../context/AuthenticationContext";
import { useTranslation } from "react-i18next";
import { convertToNLEuroFormat } from "../../../application/common/utils/helper";
const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState(dummyDashboard);
    const [loading, setLoading] = useState(false);
    const [dashboardFilter, setDashboardFilters] = useState(defaultFilter);
    const { getProfile } = useContext(AuthenticationContext);
    const [tasks, setTasks] = useState(dummyDashboardTasks);
    const [isTaskRefresh, setTaskRefresh] = useState();
    const { t: translate } = useTranslation();
    useEffect(() => {
        setLoading((prv) => !prv);
        DashboardApi.getDashboardData(dashboardFilter, getProfile().userId)
            .then((res) => {
            setDashboardData(res.data);
        })
            .finally(() => setLoading((prv) => !prv));
    }, [dashboardFilter]);
    useEffect(() => {
        setLoading((prv) => !prv);
        DashboardApi.getTaskData(dashboardFilter)
            .then((res) => {
            setTasks(res.data);
        })
            .finally(() => setLoading((prv) => !prv));
    }, [isTaskRefresh, dashboardFilter]);
    return (_jsxs(Layout, { children: [_jsx(DashboardTopHeader, { filters: dashboardFilter, setFilters: setDashboardFilters }), _jsxs(Grid, Object.assign({ container: true, spacing: { lg: 3, md: 2, xs: 1.5 } }, { children: [_jsx(DashboardBoardCard, { iconName: _jsx(UsersThree, { color: "#6C757D", size: 20 }), heading: translate("New Leads"), value: dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.topHeader.newLeads, footerIcon: (dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.topHeader.newLeadsPercentage) > 0 ? (_jsx(ArrowUp, { color: "#2AA241", size: 20 })) : (_jsx(ArrowDown, { color: "#A22A2A", size: 20 })), footerText: `${dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.topHeader.newLeadsPercentage.toString()} %`, footerTextColor: (dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.topHeader.newLeadsPercentage) > 0
                            ? "#2AA241"
                            : "#A22A2A", loading: loading }), _jsx(DashboardBoardCard, { iconName: _jsx(Newspaper, { color: "#6C757D", size: 20 }), heading: translate("Total Offerte Sent"), value: dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.topHeader.totalOfferSent, footerIcon: _jsx(_Fragment, {}), footerText: `${dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.topHeader.totalOfferSentByUser} ${translate("by me")}`, footerTextColor: "#005892", loading: loading }), _jsx(DashboardBoardCard, { iconName: _jsx(Handshake, { color: "#6C757D", size: 20 }), heading: translate("Leads Closed"), value: dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.topHeader.leadsClosed, footerIcon: _jsx(_Fragment, {}), footerText: `${dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.topHeader.leadsClosedByUser} ${translate("by me")}`, footerTextColor: "#005892", loading: loading }), _jsx(DashboardBoardCard, { iconName: _jsx(Money, { color: "#6C757D", size: 20 }), heading: translate("Revenue Generated"), value: convertToNLEuroFormat(dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.topHeader.revenueGenerated), footerIcon: _jsx(_Fragment, {}), footerText: `${convertToNLEuroFormat(dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.topHeader.revenueGeneratedByUser)} ${translate("by me")}`, footerTextColor: "#005892", loading: loading }), _jsxs(Grid, Object.assign({ container: true, item: true, spacing: { lg: 3, md: 2, xs: 1 } }, { children: [_jsx(LineChartCard, { chartData: dashboardData.contractChartData, loading: loading }), _jsx(LeadsTakenTable, { takenTask: tasks.takenLeadTask, generalTask: tasks.generalTask, loading: loading, setRefreshModalData: setTaskRefresh })] })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(RecentOfferTable, { loading: loading, recentOffers: dashboardData.recentOffers }) }))] }))] }));
};
export default Dashboard;
