var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
//import i18n from '../i18n';
import "react-toastify/dist/ReactToastify.css";
import LocalStorageService from "./local-storage-service";
import { toast } from "react-toastify";
import AuthApi from "./api/auth/auth-api";
const baseURL = process.env.REACT_APP_API_BASE_URL;
let refreshing_token = null;
export const http_api = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-Type": "application/json",
    },
});
http_api.interceptors.request.use((config) => __awaiter(void 0, void 0, void 0, function* () {
    if (LocalStorageService.getAccessToken() != null) {
        if (config.url !== "token/refreshToken" &&
            config.url !== "account/authenticate") {
            if (config.headers)
                config.headers.set("Authorization", `Bearer ${LocalStorageService.getAccessToken()}`);
        }
    }
    return config;
}));
http_api.interceptors.request.use((config) => __awaiter(void 0, void 0, void 0, function* () {
    if (LocalStorageService.getAccessToken() != null) {
        if (config.url !== "token/refresh" &&
            config.url !== "account/authenticate") {
            if (config.headers)
                config.headers.set("Authorization", `Bearer ${LocalStorageService.getAccessToken()}`);
        }
    }
    return config;
}));
http_api.interceptors.response.use((response) => {
    return response;
}, (error) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const config = error.config;
    if (error.response && (error.response.status === 401) && !config._retry) {
        if (error.response.data) {
        }
        if (((_a = error.config) === null || _a === void 0 ? void 0 : _a.url) === "account/authenticate") {
            return Promise.reject(error);
        }
        else if (error.config.retry || ((_b = error.config) === null || _b === void 0 ? void 0 : _b.url) === "token/refreshToken") {
        }
        else {
            config._retry = true;
            try {
                refreshing_token = refreshing_token
                    ? refreshing_token
                    : refresh_token();
                yield refreshing_token;
                refreshing_token = null;
                return http_api(config);
            }
            catch (err) {
                LocalStorageService.removeAccessToken();
                LocalStorageService.removeRefreshToken();
                window.location.href = "/";
            }
        }
    }
    else if (error.response && error.response.status === 403) {
        toast.error(error.response.data);
        return error;
    }
}));
function refresh_token() {
    return __awaiter(this, void 0, void 0, function* () {
        const input = {
            accessToken: LocalStorageService.getAccessToken(),
            refreshToken: LocalStorageService.getRefreshToken(),
        };
        return yield AuthApi.getRefreshToken(input).then((response) => {
            LocalStorageService.setAccessToken(response.data.accessToken);
            LocalStorageService.setRefreshToken(response.data.refreshToken);
        });
    });
}
