import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import CustomModal from "../../../pages/modal/CustomModal";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { FormControl, FormHelperText, Grid, TextField, Typography, } from "@mui/material";
import "./ContractModal.scss";
import ContractedObjectApi from "../../../../infrastructure/api/pages/contracted-object";
const ContractRejectModal = ({ setRefresh, open, onClose, contract, }) => {
    const { t: translate } = useTranslation();
    const [saving, setSaving] = useState(false);
    const hiddenButtonRef = useRef(null);
    const [charCount, setCharCount] = useState(0);
    const { formState: { errors }, control, handleSubmit, reset, } = useForm();
    const closeModal = () => {
        setSaving(false);
        setCharCount(0);
        reset();
        onClose();
    };
    const save = () => {
        // Trigger click event on the hidden button
        if (hiddenButtonRef.current) {
            hiddenButtonRef.current.click();
        }
    };
    const onSubmit = (data) => {
        setSaving(true);
        data.isDeleted = true;
        ContractedObjectApi.RejectContract(data)
            .then(() => translate("You have rejected the offer"))
            .finally(() => {
            setSaving(false);
            setRefresh((prev) => !prev);
        });
        onClose();
    };
    useEffect(() => {
        reset(contract);
    }, [contract]);
    return (_jsx(CustomModal, Object.assign({ open: open, onClose: closeModal, size: "sm", saving: saving, handleSaveButtonClick: save, heading: translate("Note about quotation"), saveButtonText: translate("Save"), disabled: false }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs(Grid, Object.assign({ container: true, gap: 1, padding: 1, className: "rejectedModal" }, { children: [_jsx(Typography, Object.assign({ className: "subheading" }, { children: translate("RejectNote") })), _jsx(Typography, Object.assign({ className: "textareatittle" }, { children: translate("Remark") })), _jsx(Controller, { name: "remark", control: control, rules: {
                                required: translate("Remark is required"),
                                maxLength: {
                                    value: 1000,
                                    message: translate("Remark must not exceed 1000 characters"),
                                },
                            }, render: ({ field }) => {
                                var _a;
                                return (_jsxs(FormControl, Object.assign({ fullWidth: true, variant: "standard", size: "medium" }, { children: [_jsx(TextField, Object.assign({ variant: "outlined", margin: "none" }, field, { multiline: true, fullWidth: true, rows: 10, onChange: (e) => {
                                                field.onChange(e);
                                                const text = e.target.value;
                                                const charCount = text.length;
                                                setCharCount(charCount);
                                            } })), _jsx(FormHelperText, Object.assign({ error: true }, { children: (_a = errors.remark) === null || _a === void 0 ? void 0 : _a.message })), _jsxs(Typography, Object.assign({ variant: "body2", color: charCount > 1000 ? "error" : "textSecondary" }, { children: ["Character Count: ", charCount, " / 1000"] }))] })));
                            } })] })), _jsx("button", Object.assign({ ref: hiddenButtonRef, type: "submit", hidden: true }, { children: "Submit" }))] })) })));
};
export default ContractRejectModal;
