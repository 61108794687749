import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { CaretDoubleUp, DotsThree, Notepad } from "@phosphor-icons/react";
import { useState } from "react";
function formatMessage(message) {
    return message.replace(/\r\n|\n/g, '<br />');
}
const Message = ({ message, name, date, maxCharacters, contractId, }) => {
    const [showFullText, setShowFullText] = useState(false);
    const appUrl = process.env.REACT_APP_APPLICATION_BASE_URL;
    const toggleTextVisibility = () => {
        setShowFullText(!showFullText);
    };
    const truncatedText = showFullText
        ? message
        : message.slice(0, maxCharacters);
    return (_jsxs(Grid, Object.assign({ container: true, item: true, className: "rightmessage", alignItems: "center" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 1 }, { children: _jsx(Notepad, { size: 32 }) })), _jsxs(Grid, Object.assign({ container: true, item: true, className: "content" }, { children: [_jsxs(Grid, Object.assign({ item: true, className: "namecontainer" }, { children: [_jsx(Typography, Object.assign({ className: "nametext" }, { children: name === "Offertepagina bekeken" ||
                                    name === "Offerte afgewezen" ||
                                    name === "Offerte verzonden" ? (_jsx("a", Object.assign({ href: contractId && contractId != null
                                        ? appUrl + "contract?contractId=" + contractId
                                        : "#", target: "_blank" }, { children: name }))) : (_jsx(_Fragment, { children: name })) })), _jsx(Typography, Object.assign({ className: "datetext" }, { children: date }))] })), _jsxs(Grid, Object.assign({ item: true }, { children: [_jsx(Typography, { className: "message", dangerouslySetInnerHTML: { __html: formatMessage(truncatedText) } }), message.length > maxCharacters &&
                                (showFullText ? (_jsx(CaretDoubleUp, Object.assign({ onClick: toggleTextVisibility }, { children: " " }))) : (_jsx(DotsThree, { onClick: toggleTextVisibility })))] }))] }))] })));
};
export default Message;
