var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./App.scss";
import AppRoutes from "./ui/layout/AppRoutes";
import { AuthenticationContextProvider } from "./context/AuthenticationContext";
import { UserRolesPermissionsContextProvider } from "./context/UserRolesPermissionsContext";
import useAsyncEffect from "use-async-effect";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./ui/pages/auth/msalConfig";
import AuthApi from "./infrastructure/api/auth/auth-api";
import LocalStorageService from "./infrastructure/local-storage-service";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import useMsalEvents from "./ui/pages/auth/mSalEvents";
function App() {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    useMsalEvents(instance);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    useAsyncEffect(() => __awaiter(this, void 0, void 0, function* () {
        if (isAuthenticated && localStorage.getItem("AUTH_TOKEN") == null) {
            setLoading(true);
            try {
                const accessToken = yield instance.acquireTokenSilent(Object.assign(Object.assign({}, loginRequest), { account: accounts[0] })).then((response) => response.accessToken);
                const email = accounts[0].username;
                const inputData = { email, accessToken };
                // Check group membership
                const groupCheckResponse = yield fetch(`https://graph.microsoft.com/v1.0/groups/${process.env.GROUP_ID}/members?$filter=id eq '${accounts[0].localAccountId}'`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                });
                if (groupCheckResponse.status != 200) {
                    toast.error(" U heeft geen toegang tot deze applicatie.");
                    instance.logoutRedirect();
                    return;
                }
                console.log("Sending data to backend:", inputData);
                const response = yield AuthApi.adUserLogin(inputData);
                if (response.data != null && response.data.accessToken === "" && response.data.refreshToken === "") {
                    toast.error(response.data.responseMessage);
                    instance.logoutRedirect();
                }
                else if (response.data != null && response.data.accessToken != "" && response.data.refreshToken != "") {
                    LocalStorageService.setAccessToken(response.data.accessToken);
                    LocalStorageService.setRefreshToken(response.data.refreshToken);
                    navigate("/");
                }
                else {
                    sessionStorage.clear();
                    localStorage.clear();
                }
                console.log(response);
            }
            catch (error) {
                console.error("Authentication error:", error);
                sessionStorage.clear();
                localStorage.clear();
            }
            finally {
                setLoading(false);
            }
        }
    }), [accounts, isAuthenticated]);
    if (loading) {
        return (_jsx("div", Object.assign({ style: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
            } }, { children: _jsx(CircularProgress, { size: 32, color: "inherit" }) })));
    }
    return (_jsx(AuthenticationContextProvider, { children: _jsxs(UserRolesPermissionsContextProvider, { children: [_jsx(ToastContainer, { position: "top-right", newestOnTop: true, pauseOnHover: true, theme: "colored" }), _jsx(AppRoutes, {})] }) }));
}
export default App;
